import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ErrorMsg from "../InlineErrorMsg";
import { Trans } from "react-i18next";

@inject("store")
@observer
class QuestionThree extends Component {
  componentDidMount() {
    $(".date_inp_inner>input").datetimepicker({
      format: "MM/DD/YYYY",
      icons: {
        next: "fas fa-angle-right",
        previous: "fas fa-angle-left"
      },
      minDate: new Date()
    });
  }

  render() {
    const { store } = this.props;
    const errorMessage = () => {
      if (!store.proposeEffDate) {
        return "Date Required";
      } else {
        let selectDate = new Date(store.proposeEffDate);
        let now = new Date(new Date().toDateString());
        if (selectDate < now) return "Policy Effect Date cannot be before today's date";
      }
    };

    return (
      <div className="other_new_elem d-flex mb-4 justify-content-sm-start justify-content-lg-start justify-content-md-start justify-content-center">
        <span className="d-flex col-lg-1 dspan mr-n5 pl-0 pr-0 font-weight-bold col-md-1 col-sm-1 col-2">
          3
        </span>
        <div className="cont_new_elem ml-lg-0 col-lg-11 col-md-10 ml-md-4 col-sm-10 col-11 ml-sm-4 ml-3">
          <p className="mb-2">
            <Trans>When would you like your policy to take effect?</Trans>
          </p>
          <div className="date_input">
            <p className="mb-3">
              <Trans>Please Indicate your Policy Effective date.</Trans>
            </p>
            <div className="pl-0 pr-0 date_inp_inner col-12 dis__button">
              <input
                type="text"
                placeholder="MM/DD/YYYY"
                name="proposeEffDate"
                className="pl-lg-5 pl-md-5 pl-sm-5 pl-5 pb-2 border-bottom-1 w-sm-100"
                onBlur={event => {
                  store.setEffDate(event.target.value);
                  store.toggleDateUpdate();
                }}
              />
              <ErrorMsg msg={errorMessage()} classNo="H" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionThree;
