import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import * as RegexSetting from "../../../config/regex";
import i18next from "i18next";

@inject("store")
@observer
class ContactInfoModal extends Component {
  constructor(props) {
    super(props);
    this.brokerEmailFormatError = false;
    this.brokerEmailEmptyError = false;
    this.error = false;
    this.state = {
      brokerEmailFormatError:false,
      brokerEmailEmptyError:false,
      error:false
    };
  }

  render() {
    const { store, moveNextStep, moveBackStep } = this.props;

    const content = () => {
      return (
        <div className="contact_elem first_contact d-flex flex-column col-12 p-0  d-flex">
          <h2 className="mt-0 mb-3">
            <Trans>Intermediary Information</Trans>
          </h2>
          <div className="wrap_form d-flex flex-column">
            <div className="el_form mb-3 col-6 p-0">
              <p className="mb-0">
                <Trans>Brokerage Name</Trans>
              </p>
              <input
                className="rounded pl-2 pr-2"
                name="header_broker"
                type="text"
                placeholder={i18next.t("Name of Company")}
                value={store["header_broker"]}
                onChange={event => {
                  store.setHeaderField("header_broker", event.target.value);
                }}
              />
            </div>
            <div className="el_form mb-3 col-6 p-0">
              <p className="mb-0">
                <Trans>Handler Name</Trans>
              </p>
              <input
                className="rounded pl-2 pr-2"
                name="header_handler"
                type="text"
                placeholder={i18next.t("Full Name")}
                value={store["header_handler"]}
                onChange={event => {
                  store.setHeaderField("header_handler", event.target.value);
                }}
              />
            </div>
            <div className="two_el_form col-11 d-flex flex-row p-0 mb-sm-5 mb-4">
              <div className="el_form col-5 p-0">
                <p className="mb-0">
                  <Trans>Contact Number</Trans>
                </p>
                <input
                  className="rounded pl-2 pr-2 cont_phone"
                  name="header_broker_no"
                  type="text"
                  placeholder="+852"
                  value={store["header_broker_contact"]}
                  onChange={event => {
                    store.setHeaderField("header_broker_contact", event.target.value);
                  }}
                />
              </div>
              <div className="el_form offset-1 col-5 p-0 position_relative">
                <p className="mb-0">
                  <Trans>Email Address</Trans>
                </p>
                <input
                  className="mail_inp rounded pl-2 pr-2"
                  type="text"
                  name="header_broker_email"
                  placeholder={i18next.t("Email")}
                  value={store["header_broker_email"]}
                  onChange={event => {
                    store.setHeaderField("header_broker_email", event.target.value);
                  }}
                  style={this.state.error ? { border: "1px solid red" } : { width: "100%" }}
                />
                {this.state.brokerEmailEmptyError && (
                  <div>
                    <Trans>Fill this field</Trans>.
                  </div>
                )}
                {this.state.brokerEmailFormatError && (
                  <div>
                    <Trans>Invalid Email Address</Trans>.
                  </div>
                )}
              </div>
            </div>
            <div className="subm_el_form d-flex justify-content-end">
              <a
                href="#"
                name="contact_skip"
                className="skip_button rounded font-weight-bold d-flex align-items-center justify-content-center mr-3"
                onClick={event => {
                  event.preventDefault();
                  store.setHeaderField("header_broker_email", "");
                  store.setHeaderField("header_broker_contact", "");
                  store.setHeaderField("header_handler", "");
                  store.setHeaderField("header_broker", "");
                  moveNextStep();

                }}
              >
                <Trans>Skip</Trans>
              </a>
              <a
                href="#"
                name="contact_next"
                className="next_button rounded font-weight-bold d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();


                  this.setState({
                    error:false,
                    brokerEmailFormatError:false,
                    brokerEmailEmptyError:false
                  });

                  if (!store.header_broker_email) {

                    this.setState({
                      error:true,
                      brokerEmailEmptyError:true
                    });
                  } else {
                    if(!(RegexSetting.email_regex.test(store.header_broker_email)))
                    {
                      this.setState({
                        error:true,
                        brokerEmailFormatError:true
                      });

                    }
                    else
                    {
                      moveNextStep();
                    }
                  }
                }}
              >
                <Trans>Next</Trans>
              </a>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="container contact_block d-flex flex-column col-12 p-0">
        <div className="contact_head d-flex align-items-start justify-content-start">
          <h2 className="mt-0 mb-3 ">
            <img className="mr-2" src={"img/" + "ccl_mail.png"} alt="ccl_mail" />
            <Trans>Contact Information</Trans>
            <span>
              (<Trans>Optional</Trans>)
            </span>
          </h2>
        </div>

        <div className="contact_block">{content()}</div>
      </div>
    );
  }
}

export default ContactInfoModal;
