import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { Modal } from "reactstrap";
import ImportantNotes from "./ImportantNotes";
import ContactInfo1 from "./ContactInfo1";
import ContactInfo2 from "./ContactInfo2";
import ExportPdf from "./ExportPdf";

@inject("store")
@observer
class ExportQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "notes" // notes>> contact>> export
    };
    this.moveNextStep = this.moveNextStep.bind(this);
    this.moveBackStep = this.moveBackStep.bind(this);
  }
  moveNextStep() {
    if (this.state.step === "notes") {
      this.setState({ step: "contact1" });
    } else if (this.state.step === "contact1") {
      this.setState({ step: "contact2" });
    } else if ((this.state.step = "contact2")) {
      this.setState({ step: "export" });
    }
  }
  moveBackStep() {
    if (this.state.step === "export") {
      this.setState({ step: "contact2" });
    } else if ((this.state.step = "contact2")) {
      this.setState({ step: "contact1" });
    } else if ((this.state.step = "contact1")) {
      this.setState({ step: "notes" });
    }
  }
  render() {
    const { store } = this.props;

    const content = () => {
      if (this.state.step === "notes") {
        return <ImportantNotes moveNextStep={this.moveNextStep} />;
      } else if (this.state.step === "contact1") {
        return <ContactInfo1 moveNextStep={this.moveNextStep} moveBackStep={this.moveBackStep} />;
      } else if (this.state.step === "contact2") {
        return <ContactInfo2 moveNextStep={this.moveNextStep} moveBackStep={this.moveBackStep} />;
      } else if (this.state.step === "export") {
        return <ExportPdf moveBackStep={this.moveBackStep} />;
      }
    };
    let wrapperClass = "notesModal";
    if (this.state.step === "contact1" || this.state.step === "contact2") {
      wrapperClass = "contactInfoModal";
    } else if (this.state.step === "export") {
      wrapperClass = "exportModal";
    }

    return (
      <div id="contactInfoModal">
        <Modal
          isOpen={store.exportQuoteModalOpen}
          toggle={store.toggleExportQuoteModal}
          className={"modal-dialog ml-auto mr-auto"}
          backdropClassName={"modal-backdrop"}
          modalClassName={"fsonsmall"}
          contentClassName={"position-relative"}
          wrapClassName={wrapperClass}
          fade={true}
        >
          <button
            type="button"
            className="close close_di"
            data-dismiss="modal"
            aria-label="Close"
            onClick={event => {
              store.toggleExportQuoteModal();
            }}
          >
            <img src={"img/" + "close.png"} alt="close" />
          </button>

          <div className="modal-body pl-lg-4 pr-lg-4 pl-md-4 pr-md-4 pl-sm-4 pr-sm-4   pl-3 pr-3">
            {content()}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ExportQuoteModal;
