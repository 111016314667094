const initMobileModal = () => {
  $(".head_dhosp").on("click", function() {
    $(".content_dhosp").slideUp();
    var status = 0;

    if (
      $(this)
        .closest(".elem_small_hospit")
        .hasClass("active_hospit")
    ) {
      $(".elem_small_hospit").removeClass("active_hospit");
      $(this)
        .next()
        .slideUp();
      status = 1;
    }
    $(".elem_small_hospit").removeClass("active_hospit");

    if (status == 0) {
      if ($(this).next().length) {
        $(this)
          .next()
          .slideDown();
        $(this)
          .closest(".elem_small_hospit")
          .addClass("active_hospit");
      } else {
        $(this)
          .closest(".elem_small_hospit")
          .addClass("active_hospit");
      }
    }
    $(this)
      .closest(".modal-body")
      .find(".selected_text>span")
      .text(
        $(this)
          .closest(".modal-body")
          .find(".active_hospit>.head_dhosp>p")
          .text()
      );
  });
};

const initTooltip = () => {
  $('[data-toggle="tooltip"]').tooltip({
    html: true
  });
};

const planToOption = value => {
  if (value) {
    return value.replace(/plan/gi, "Option ");
  }
  return value;
};

module.exports = { initMobileModal, initTooltip, planToOption };
