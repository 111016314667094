import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from "i18next";

const { initMobileModal, planToOption } = require("../../util");

@inject("store")
@observer
class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: ""
    };
  }

  componentDidMount() {
    initMobileModal();
  }

  setHospitalPlanOption(opt) {
    const { store } = this.props;
    store.setHospitalOption(store.openModalClassNo, opt);
  }
  render() {
    const { store } = this.props;
    const currentSelection = store["class_hospitalPlan_" + store.openModalClassNo];
    const isActive = option => {
      if (option == currentSelection) return " active_hospit ";
      return "";
    };
    return (
      <Fragment>
        <div className="small_hospit_wrap d-flex flex-column align-items-center justify-content-center m-0 p-2 col-12">
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN1")}
            onClick={event => {
              this.setState({ expand: "PLAN1" });
              store.setHospitalOption(store.openModalClassNo, "PLAN1");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 1</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN1") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Room & Board Charges (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">2,700</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intensive Care Unit (Maximum 10 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">4,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Special Services</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Surgical Fees</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Complex Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">90,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Major Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intermediate Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">22,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">11,250</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Anaesthetist's Fee</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">27,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">13,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">6,750</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,375</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Operating Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">27,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">13,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">6,750</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,375</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Doctor's Consultation (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">2,700</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Specialist's Consultation (Referred & recommended by attending physician in writing)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">5,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Cashless Gastroscopy / Colonoscopy at designated Day Surgery Centre (Pre-admission assessment is required)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2"><Trans>Full Cover</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Day Surgery for Gastroscopy / Colonoscopy</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2">28,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Pre & Post-Hospitalization Treatment (Including 1 pre-admission visit within 30 consecutive days before admission to hospital and all related post-hospitalization treatment within 90 consecutive days after discharge from hospital)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">4,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Special Nursing (Maximum 30 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">800</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash Benefit (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">1,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash for coordinate Benefits (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">1,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Final Tribute Costs</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per case limit</Trans></p>
                  <span className="mb-0 ml-2">90,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Extended Major Medical Benefit (80% Reimbursement) Please Refer to the policy terms for coverage details.</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">90,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Deductible</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>NIL</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Max. limit per disability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">150,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Overseas Emergency Medical Evacuation  (Applicable to member age below 70)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">$2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Greater China Card (Deposit-free Admission)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>100% Top Up Overseas Benefits for Hospitalization Benefits due to Accident (Exclude China, Hong Kong and Macau)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ></p>
                  <span className="mb-0 ml-2"><Trans>Hospital Benefits increase to 200%</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Psychiatric Treatment</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ><Trans>per policy year</Trans></p>
                  <span className="mb-0 ml-2">50,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN2")}
            onClick={event => {
              store.setHospitalOption(store.openModalClassNo, "PLAN2");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 2</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN2") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Room & Board Charges (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">1,760</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intensive Care Unit (Maximum 10 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">2,700</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Special Services</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2">23,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Surgical Fees</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Complex Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">70,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Major Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">35,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intermediate Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">17,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Minor Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">8,750</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Anaesthetist's Fee</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">21,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">10,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">5,250</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,625</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Operating Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">21,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">10,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">5,250</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,625</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Doctor's Consultation (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">1,760</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Specialist's Consultation (Referred & recommended by attending physician in writing)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">4,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Cashless Gastroscopy / Colonoscopy at designated Day Surgery Centre (Pre-admission assessment is required)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2"><Trans>Full Cover</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Day Surgery for Gastroscopy / Colonoscopy</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2">23,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Pre & Post-Hospitalization Treatment (Including 1 pre-admission visit within 30 consecutive days before admission to hospital and all related post-hospitalization treatment within 90 consecutive days after discharge from hospital)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Special Nursing (Maximum 30 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">600</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash Benefit (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">650</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash for coordinate Benefits (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">650</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Final Tribute Costs</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per case limit</Trans></p>
                  <span className="mb-0 ml-2">70,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Extended Major Medical Benefit (80% Reimbursement) Please Refer to the policy terms for coverage details.</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">70,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Deductible</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>NIL</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Max. limit per disability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">100,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Overseas Emergency Medical Evacuation  (Applicable to member age below 70)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">$2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Greater China Card (Deposit-free Admission)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>100% Top Up Overseas Benefits for Hospitalization Benefits due to Accident (Exclude China, Hong Kong and Macau)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ></p>
                  <span className="mb-0 ml-2"><Trans>Hospital Benefits increase to 200%</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Psychiatric Treatment</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ><Trans>per policy year</Trans></p>
                  <span className="mb-0 ml-2">40,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN3")}
            onClick={event => {
              store.setHospitalOption(store.openModalClassNo, "PLAN3");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 3</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN3") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Room & Board Charges (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">1,150</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intensive Care Unit (Maximum 10 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">1,750</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Special Services</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2">15,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Surgical Fees</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Complex Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">50,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Major Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">25,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intermediate Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">12,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Minor Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">6,250</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Anaesthetist's Fee</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">15,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">7,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,750</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,875</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Operating Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">15,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">7,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,750</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,875</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Doctor's Consultation (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">1,150</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Specialist's Consultation (Referred & recommended by attending physician in writing)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">3,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Cashless Gastroscopy / Colonoscopy at designated Day Surgery Centre (Pre-admission assessment is required)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2"><Trans>Full Cover</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Day Surgery for Gastroscopy / Colonoscopy</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2">17,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Pre & Post-Hospitalization Treatment (Including 1 pre-admission visit within 30 consecutive days before admission to hospital and all related post-hospitalization treatment within 90 consecutive days after discharge from hospital)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">1,200</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Special Nursing (Maximum 30 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">400</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash Benefit (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">450</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash for coordinate Benefits (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">450</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Final Tribute Costs</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per case limit</Trans></p>
                  <span className="mb-0 ml-2">50,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Extended Major Medical Benefit (80% Reimbursement) Please Refer to the policy terms for coverage details.</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">50,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Deductible</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>NIL</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Max. limit per disability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">50,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Overseas Emergency Medical Evacuation  (Applicable to member age below 70)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">$2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Greater China Card (Deposit-free Admission)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>100% Top Up Overseas Benefits for Hospitalization Benefits due to Accident (Exclude China, Hong Kong and Macau)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ></p>
                  <span className="mb-0 ml-2"><Trans>Hospital Benefits increase to 200%</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Psychiatric Treatment</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ><Trans>per policy year</Trans></p>
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN4")}
            onClick={event => {
              store.setHospitalOption(store.openModalClassNo, "PLAN4");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 4</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN4") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Room & Board Charges (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">730</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intensive Care Unit (Maximum 10 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">940</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Special Services</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2">11,500</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Surgical Fees</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>per disability limit</Trans>
                  </p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Complex Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">32,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Major Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">16,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Intermediate Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">8,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Minor Operation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">4,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">Anaesthetist's Fee</p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">9,600</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">4,800</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,400</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,200</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Operating Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2" />
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Complex Theatre</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">9,600</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Major Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">4,800</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Intermediate Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,400</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Minor Operation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,200</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Doctor's Consultation (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">730</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>In-Hospital Specialist's Consultation (Referred & recommended by attending physician in writing)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">2,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Cashless Gastroscopy / Colonoscopy at designated Day Surgery Centre (Pre-admission assessment is required)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2"><Trans>Full Cover</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Day Surgery for Gastroscopy / Colonoscopy</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per procedure limit</Trans></p>
                  <span className="mb-0 ml-2">10,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Pre & Post-Hospitalization Treatment (Including 1 pre-admission visit within 30 consecutive days before admission to hospital and all related post-hospitalization treatment within 90 consecutive days after discharge from hospital)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per disability limit</Trans></p>
                  <span className="mb-0 ml-2">1,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    Special Nursing (Maximum 30 days per disability)
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">200</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash Benefit (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">275</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Daily Cash for coordinate Benefits (Maximum 91 days per disability)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>limit per day</Trans></p>
                  <span className="mb-0 ml-2">275</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Final Tribute Costs</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0"><Trans>per case limit</Trans></p>
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Extended Major Medical Benefit (80% Reimbursement) Please Refer to the policy terms for coverage details.</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Deductible</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>NIL</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0" />
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Max. limit per disability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Free Overseas Emergency Medical Evacuation  (Applicable to member age below 70)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">$2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Greater China Card (Deposit-free Admission)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>100% Top Up Overseas Benefits for Hospitalization Benefits due to Accident (Exclude China, Hong Kong and Macau)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ></p>
                  <span className="mb-0 ml-2"><Trans>Hospital Benefits increase to 200%</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Psychiatric Treatment</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ><Trans>per policy year</Trans></p>
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_small_wd pl-3 pr-3 pt-3 pb-1">
          <div className="small_wd1 d-flex align-items-center mb-2 ">
            <div className="col-lg-6 sma-l p-0">
              <p className="m-0 font-weight-bold">
                *<Trans>All prices in HKD</Trans>
              </p>
            </div>
            <div className="col-lg-6 sma-r p-0">
              <h6 className="m-0 text-right selected_text">
                <Trans>Selected</Trans>:
                <span className="font-weight-bold text-uppercase">
                  {i18next.t(planToOption(store["class_hospitalPlan_" + store.openModalClassNo]))}
                </span>
              </h6>
            </div>
          </div>
          <div className="small_wd2 d-flex align-items-center ">
            <div className="col-lg-6 sma-l p-0">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_hospitalPlan_" + store.openModalClassNo);
                  store.toggleHospitalBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
            </div>
            <div className="col-lg-6 sma-r p-0 d-flex justify-content-end">
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleHospitalBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Mobile;
