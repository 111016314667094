import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ErrorMsg from "../InlineErrorMsg";
import { Trans } from "react-i18next";

@inject("store")
@observer
class QuestionOne extends Component {
  render() {
    const { store } = this.props;
    return (
      <div className="other_new_elem d-flex mb-4 justify-content-sm-start justify-content-lg-start justify-content-md-start justify-content-center">
        <span className="d-flex col-lg-1 dspan mr-n5 pl-0 pr-0 font-weight-bold col-md-1 col-sm-1 col-2">
          1
        </span>
        <div className="cont_new_elem ml-lg-0 col-lg-11 col-md-10 ml-md-4 col-sm-10 col-11 ml-sm-4 ml-3">
          <p className="mb-4" name="header_client_question1">
            <Trans>question-one-text</Trans>
          </p>
          <div
            className="both_checkbox_elems dis__button"
            data-error="Answer required"
            data-quest="Please answer first question."
          >
            <p>
              <input
                type="radio"
                id="test1"
                name="header_client_question1_yes"
                className="track_class"
                checked={store.header_client_question1 === "Y"}
                onClick={event => {
                  store.setHongKongOutsider("Y");
                }}
              />
              <label htmlFor="test1">
                <Trans>Yes</Trans>
              </label>
            </p>

            <p>
              <input
                type="radio"
                id="test2"
                name="header_client_question1_no"
                checked={store.header_client_question1 === "N"}
                onClick={event => {
                  store.setHongKongOutsider("N");
                }}
              />
              <label htmlFor="test2">
                <Trans>No</Trans>
              </label>
            </p>
            <ErrorMsg msg={store.header_client_question1 ? "" : "Answer required"} classNo="H" />
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionOne;
