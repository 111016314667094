import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import i18next from "i18next";
import { planToOption } from "../../util";
@inject("store")
@observer
class OutpatientBenefits extends Component {
  setOutpatientPlanOption(opt) {
    const { store } = this.props;
    store.setOutpatientPlanOption(store.openModalClassNo, opt);
  }
  render() {
    const { store } = this.props;
    let valueSelected = store["class_outPPlan_attr1_" + store.openModalClassNo];
    const isActive = option => {
      if (option == store["class_outPPlan_attr1_" + store.openModalClassNo])
        return " active_wed_el";
      return "";
    };
    return (
      <Fragment>
        <div className="hospit_wrap hospit_with_paddings col-12 p-0 d-flex align-items-start justify-content-between pl-3 pr-3 flex-wrap">
          <div className="col-lg-7 p-0 pt-5 left_hospit" style={{ maxWidth: "428px" }}>
            <h2 className="font-weight-bold mb-3">
              <Trans>COVERAGE</Trans>
            </h2>
            <div className="wrap_hospit_dchar">
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">1</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>General Doctor's Consultation (Maximum 30 visits per policy year)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per visit per day</Trans>
                </h6>
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">2</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Physiotherapist/Chiropractic Treatment* (Maximum 10 visits per policy year)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per visit per day</Trans>
                </h6>
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">3</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Specialist Consultation (Maximum 10 visits per policy year)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per visit per day</Trans>
                </h6>
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">4</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Chinese Herbalist / Bonesetter / Acupuncture Treatment (Maximum 10 visits per policy year)</Trans>*
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per visit per day</Trans>
                </h6>
              </div>

              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">5</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Diagnostic X-ray / Laboratory Test</Trans>*
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>max limit policy year</Trans>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6 not_full_wr right_hospit p-0 d-flex align-items-start justify-content-between ">
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN1")
              }
              onClick={event => {
                store.setOutpatientPlanOption(store.openModalClassNo, "PLAN1");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 1</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>350</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>670</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>670</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>240</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,500</p>
              </div>
            </div>

            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column" +
                isActive("PLAN2")
              }
              onClick={event => {
                store.setOutpatientPlanOption(store.openModalClassNo, "PLAN2");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 2</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>280</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>520</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>520</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>200</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,600</p>
              </div>
            </div>

            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column" +
                isActive("PLAN3")
              }
              onClick={event => {
                store.setOutpatientPlanOption(store.openModalClassNo, "PLAN3");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 3</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>210</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>400</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>400</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>180</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,750</p>
              </div>
            </div>

            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column" +
                isActive("PLAN4")
              }
              onClick={event => {
                store.setOutpatientPlanOption(store.openModalClassNo, "PLAN4");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 4</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>170</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>320</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>320</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>150</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,050</p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 after_text pl-0 pr-0">
            <div className="after_txt1 pt-lg-3 pb-lg-3 pt-md-2 pb-md-2">
              <p className="mb-0">
                <Trans>Items 1-4 shall be subject to overall maximum 30 visits per policy year</Trans>
                . <br />(<Trans>for both non-panel network doctors and panel network doctors</Trans>
                )
              </p>
            </div>
            <div className="after_txt2 pt-lg-3 pb-lg-3 pt-md-2 pb-md-2">
              <p className="mb-0">
                <span>
                  <img src="img/caret.png" alt="caret" />
                </span>
                  {i18n.t("outpatient-popup-note")}
               
              </p>
            </div>
            <div className="after_txt_note pt-lg-0 pb-lg-0 pt-md-2 pb-md-2 ">
              <p className="mb-0">
                <span>
                  <img src="img/caret.png" alt="caret" />
                </span>
                <span>
                  <span>
                    <Trans>Note 1</Trans>:
                  </span>         
                  <Trans>Outpatient-noteOne-line-1</Trans>
                  <br />
                  <Trans>Outpatient-noteOne-line-2</Trans> <br />     
                  <Trans>Outpatient-noteOne-line-3</Trans>
                  <br />
                  <Trans>Outpatient-noteOne-line-4</Trans>
                </span>
              </p>
            </div>
            <div className="after_txt_note pt-lg-0 pb-lg-0 pt-md-2 pb-md-2 ">
              <p className="mb-0">
                <span>
                  <img src="img/caret.png" alt="caret" />
                </span>
                <span>
                  <span>
                    <Trans>Note 2</Trans>:
                  </span>
                  <Trans>Outpatient-noteTwo-line-1</Trans>
                  <br />
                  <Trans>Outpatient-noteTwo-line-2</Trans> <br />
                  <Trans>Outpatient-noteTwo-line-3</Trans>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="bottom_wd d-flex align-items-center justify-content-between">
          <div className="col-lg-6 col-md-5 left_wd d-flex align-items-center justify-content-start not_covered">
            <div className={"check_wd d-flex align-items-center justify-content-center rounded mb-0 mr-2 "+isActive("Not Covered")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "Not Covered");
            }}>
              <p
                className="m-0"
              >
                <input type="checkbox" id="cover4" checked={valueSelected == "Not Covered"} />
                <div class="bl_circle align-items-center justify-content-center not_covered_full"><img src="img/smallds.png" alt="smallds"/></div>
                <span class="text-center font-weight-bold text-uppercase"><Trans>Not Covered</Trans></span>
              </p>
            </div>
            <p className="m-0">
              *<Trans>All prices in HKD</Trans>
            </p>
          </div>
          <div className="col-lg-6 col-md-7 right_wd d-flex align-items-center justify-content-end">
            <div className="res_wnd mr-2">
              <p className="text-center mr-2 mb-0">
                <Trans>Selected</Trans>:{" "}
                <span>{i18next.t(planToOption(store["class_outPPlan_attr1_" + store.openModalClassNo]))}</span>
              </p>
            </div>
            <div className="rd_buttons d-flex align-items-center">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center mr-3"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_outPPlan_attr1_" + store.openModalClassNo);
                  store.toggleoutpatientBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleoutpatientBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OutpatientBenefits;
