import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from "i18next";
@inject("store")
@observer
class AreaDropdown extends Component {
  constructor(props) {

    super(props);
    this.state = { isMenuOpen: false };
    this.handleClick = this.handleClick.bind(this);
  }
  

  setValue(value) {
    
    this.props.store.setBusinessTravelMembersCount(this.props.classNo, value);
  }

  handleClick(e) {
    if (this.refs.menu.contains(e.target)) {
      return;
    }
    this.setState({ isMenuOpen: false });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const { store, classNo } = this.props;
    const isActive = value => {
      if (store["class_bta_plan_area_" + classNo] == value) return "active_ul_type";
      else return "";
    };
    const isEmpCount = value => {
      const val = Number(store["class_employeeNo_0"]) + Number(store["class_employeeNo_1"]) + Number(store["class_employeeNo_2"]) + Number(store["class_employeeNo_3"])
      if (Number(val) < Number(value)) return "display-none";
      else return "";
    }
    return (
      <div 
        ref= {"menu"}
        className={"drop_mod position-relative align-items-center justify-content-center"+
        (this.props.classNo == "0"? " d-flex":" hide_bta")
      } 
      >
        <input
          type="text"
          name="class_bta_plan_member_range_0"
          className="rounded font-weight-bold input_open_modal"
          readOnly
          value={
            store["class_bta_plan_member_range_" + classNo]
              ? store["class_bta_plan_member_range_" + classNo] 
              : i18next.t("Select...")
          }
          readOnly
          onClick={event => {
            this.setState({ isMenuOpen: true });
          }}
        />
        {this.state.isMenuOpen && (
          <div
            style={{
              display: "flex"
            }}
            className="drop_mod_des  flex-column position-absolute rounded"
          >
            <p className="pl-3 pr-3 mt-0 mb-2 font-weight-bold">
              <Trans>Please select</Trans>
            </p>
            <ul>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("2 - 6") + isEmpCount("2")} >
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("2 - 6");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  2 - 6
                </a>
              </li>
              <li className={"pl-3 pr-3 pt-2 pb-2 " + isActive("7 - 10") + isEmpCount("7")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("7 - 10");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  7 - 10
                </a>
              </li>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("11 - 15") + isEmpCount("11")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("11 - 15");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                11 - 15
                </a>
              </li>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("16 - 20") + isEmpCount("16")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("16 - 20");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  16 - 20
                </a>
              </li>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("21 - 30") + isEmpCount("21")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("21 - 30");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  21 - 30
                </a>
              </li>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("31 - 40") + isEmpCount("31")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("31 - 40");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  31 - 40
                </a>
              </li>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("41 - 50") + isEmpCount("41")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("41 - 50");
                    this.setState({ isMenuOpen: false });
                  }}
                >
                  41 - 50
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default AreaDropdown;
