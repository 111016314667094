import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from "i18next";
const { initMobileModal, planToOption } = require("../../util");

@inject("store")
@observer
class TermLifeBenefits extends Component {
  componentDidMount() {
    initMobileModal();
  }

  render() {
    const { store } = this.props;
    const isActive = option => {
      if (option == store["class_tLifePlan_" + store.openModalClassNo]) return " active_hospit ";
      return "";
    };
    return (
      <Fragment>
        <div className="small_hospit_wrap d-flex flex-column align-items-center justify-content-center m-0 p-2 col-12">
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN1")}
            onClick={event => {
              store.setTermLifePlanOption(store.openModalClassNo, "PLAN1");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 1</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN1") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Term Life Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death & Disablement Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,000,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN2")}
            onClick={event => {
              store.setTermLifePlanOption(store.openModalClassNo, "PLAN2");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 2</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN2") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Term Life Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">750,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death & Disablement Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">750,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN3")}
            onClick={event => {
              store.setTermLifePlanOption(store.openModalClassNo, "PLAN3");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 3</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN3") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Term Life Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">500,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death & Disablement Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">500,000</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN4")}
            onClick={event => {
              store.setTermLifePlanOption(store.openModalClassNo, "PLAN4");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 4</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN4") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Term Life Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">250,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death & Disablement Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">250,000</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Not Covered")}
            onClick={event => {
              store.setTermLifePlanOption(store.openModalClassNo, "Not Covered");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Not Covered</Trans>
              </p>
            </div>
          </div>
        </div>

        <div className="bottom_small_wd pl-3 pr-3 pt-3 pb-1">
          <div className="small_wd1 d-flex align-items-center mb-2 ">
            <div className="col-lg-6 sma-l p-0">
              <p className="m-0 font-weight-bold">
                *<Trans>All prices in HKD</Trans>
              </p>
            </div>
            <div className="col-lg-6 sma-r p-0">
              <h6 className="m-0 text-right selected_text">
                <Trans>Selected</Trans>:{" "}
                <span className="font-weight-bold text-uppercase">
                  {i18next.t(planToOption(store["class_tLifePlan_" + store.openModalClassNo]))}
                </span>
              </h6>
            </div>
          </div>
          <div className="small_wd2 d-flex align-items-center ">
            <div className="col-lg-6 sma-l p-0">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();
                  store.toggleTermLifeBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
            </div>
            <div className="col-lg-6 sma-r p-0 d-flex justify-content-end">
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleTermLifeBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TermLifeBenefits;
