import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from 'i18next';

const { initMobileModal, planToOption } = require("../../util");

@inject("store")
@observer
class DentalBenefits extends Component {
  componentDidMount() {
    initMobileModal();
  }

  render() {
    const { store } = this.props;
    let valueSelected = store["class_dentalPlan_" + store.openModalClassNo];
    const isActive = option => {
      if (option == valueSelected) return " active_hospit ";
      return "";
    };

    return (
      <Fragment>
        <div className="small_hospit_wrap d-flex flex-column align-items-center justify-content-center m-0 p-2 col-12">
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN1")}
            onClick={event => {
              store.setDentalOption(store.openModalClassNo, "PLAN1");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 1</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN1") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Oral examination, Scaling & Polishing</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>Once a year</Trans>
                  </p>
                  <span className="mb-0 ml-2">500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Dental X-ray & Medications</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Filling & Simple Extractions</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Emergency treatment of dental pain (Palliative)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Impaction</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Denture (due to accident only)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex no-bord-dchar justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Overall Maximum</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" ><Trans>per policy year</Trans></p>
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Not Covered")}
            onClick={event => {
              store.setDentalOption(store.openModalClassNo, "Not Covered");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Not Covered</Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="bottom_small_wd pl-3 pr-3 pt-3 pb-1">
          <div className="small_wd1 d-flex align-items-center mb-2 ">
            <div className="col-lg-6 sma-l p-0">
              <p className="m-0 font-weight-bold">
                *<Trans>All prices in HKD</Trans>
              </p>
            </div>
            <div className="col-lg-6 sma-r p-0">
              <h6 className="m-0 text-right selected_text">
                <Trans>Selected</Trans>:{" "}
                <span className="font-weight-bold text-uppercase">
                  {i18next.t(planToOption(valueSelected))}
                </span>
              </h6>
            </div>
          </div>
          <div className="small_wd2 d-flex align-items-center ">
            <div className="col-lg-6 sma-l p-0">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_dentalPlan_" + store.openModalClassNo);
                  store.toggleDentalBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
            </div>
            <div className="col-lg-6 sma-r p-0 d-flex justify-content-end">
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleDentalBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DentalBenefits;
