import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class Class34InfoModal extends Component {
  render() {
    const { store } = this.props;

    return (
      <div
        className="modal fade"
        id="classModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ml-auto mr-auto" role="document">
          <div className="modal-content position-relative">
            <button
              type="button"
              className="close close_di"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img
                src="img/close.png"
                alt="close"
                onClick={event => {
                  store.toggleClass34InfoModal();
                }}
              />
            </button>

            <div className="modal-body">
              <div className="container d-flex flex-column col-12 p-0">
                <div className="elem_class mb-4">
                  <h2 className="font-weight-bold mb-2"><Trans>Class 3</Trans></h2>
                  <p className="mb-0">
                    <Trans>class3-text</Trans>
                    <br />
                    <span className="font-weight-bold">
                      <Trans>class3-text-example</Trans>
                    </span>
                  </p>
                </div>

                <div className="elem_class">
                  <h2 className="font-weight-bold mb-2"><Trans>Class 4</Trans></h2>
                  <p>
                    <Trans>class4-text</Trans>

                    <br />
                    <span className="font-weight-bold">
                      <Trans>class4-text-example</Trans>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Class34InfoModal;
