import React, { Component } from "react";
import ErrorMsg from "./ErrorMsg";
import WarnMsg from "./WarnMsg";

import { inject, observer } from "mobx-react";

@inject("store")
@observer
class ErrorMessage extends Component {
  render() {
    const store = this.props.store;
    const errorMsges = () => {
      return store.errorMessages.map(msg => {
        return <ErrorMsg msg={msg} />;
      });
    };
    const warnMsges = () => {
      return store.warnMessages.map(msg => {
        return <WarnMsg msg={msg} />;
      });
    };
    return (
      <div className="block_with_errors position-relative d-flex align-items-center justify-content-center flex-column col-12 p-0">
        {errorMsges()}
        {warnMsges()}
      </div>
    );
  }
}

export default ErrorMessage;
