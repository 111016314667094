import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class ErrorMsg extends Component {
  render() {
    const { store, classNo } = this.props;
    let shouldDisplay = false;
    if (classNo === "H" && store.displayErrorBasic) {
      shouldDisplay = true;
    } else {
      shouldDisplay = store["displayError_class_" + classNo];
    }

    if (shouldDisplay && this.props.msg) {
      return (
        <div className="after_date_inp d-flex col-12 position-absolute p-0">
          <p>
            <img src={"img/" + "warn.png"} alt="warn" />
            <span>
              <Trans>{this.props.msg}</Trans>
            </span>
          </p>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default ErrorMsg;
