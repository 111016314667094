import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Modal } from "reactstrap";
import Full from "./Full";
import Mobile from "./Mobile";
import { Trans } from "react-i18next";

@inject("store")
@observer
class DentalBenefits extends Component {
  render() {
    const { store } = this.props;
    let valueSelected = store["class_dentalPlan_" + store.openModalClassNo];

    return (
      <div id="modalWD3">
        <Modal
          isOpen={store.dentalBenefitsModalOpen}
          toggle={store.toggleDentalBenefitsModal}
          className={"ml-auto mr-auto fullscreen_modal"}
          backdropClassName={"modal-backdrop"}
          modalClassName={"fscreen"}
          contentClassName={"position-relative"}
          wrapClassName={"modalWD3"}
          fade={true}
        >
          <button
            type="button"
            className="close close_di"
            data-dismiss="modal"
            aria-label="Close"
            onClick={event => {
              event.preventDefault();
              store.cancelSelectionInModal("class_dentalPlan_" + store.openModalClassNo);
              store.toggleDentalBenefitsModal();
            }}
          >
            <img src={"img/" + "close.png"} alt="close" />
          </button>

          <div className="modal-body pb-0 pl-0 pr-0 ">
            <div className="container d-flex flex-column col-12 p-0">
              <div className="head_bf col-12 p-0 m-0 mb-lg-3 mb-md-3 mb-sm-3 mb-2 pl-3 pr-3">
                <h2 className="m-0 d-flex align-items-center justify-content-start font-weight-bold pr-4">
                  <span>
                    <img src={"img/" + "pill_med.png"} alt="circle_med" className="mr-2" />
                  </span>
                  <Trans>Dental Benefits</Trans>
                  <span className="ml-1">
                    (<Trans>Optional Cover</Trans>)
                  </span>
                  <span className="ml-1">
                    (<Trans>80% Reimbursement</Trans>)
                  </span>
                </h2>
                <h5 class="float_text">
                  <Trans>80% Reimbursement</Trans>
                </h5>
              </div>
              {store.isMobile ? <Mobile /> : <Full />}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DentalBenefits;
