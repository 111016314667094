import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
@inject("store")
@observer
class StaffClassTotal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { store, classNo } = this.props;

    return (
      <div className="res_lu w-100 not_active_  d-flex align-items-center justify-content-start pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2">
        <p className="m-0 font-weight-bold" name="class_totalPremium_0">
          {store.quote["premiumClass" + classNo]}
        </p>
      </div>
    );
  }
}

export default StaffClassTotal;
