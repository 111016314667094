import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import ErrorMsg from "../InlineErrorMsg";

@inject("store")
@observer
class QuestionTwo extends Component {
  render() {
    const { store } = this.props;
    return (
      <div className="other_new_elem d-flex mb-4 justify-content-sm-start justify-content-lg-start justify-content-md-start justify-content-center">
        <span className="d-flex col-lg-1 dspan mr-n5 pl-0 pr-0 font-weight-bold col-md-1 col-sm-1 col-2">
          2
        </span>
        <div className="cont_new_elem ml-lg-0 col-lg-11 col-md-10 ml-md-4 col-sm-10 col-11 ml-sm-4 ml-3">
          <p className="mb-4">
            <Trans>Are any proposed insured employees classified as</Trans>{" "}
            <span
              className="font-weight-bold"
              data-toggle="modal"
              data-target="#classModal"
              onClick={event => {
                store.toggleClass34InfoModal();
              }}
            >
              <u>
                <Trans>class 3 or class 4?</Trans>
              </u>
            </span>
          </p>
          <div
            className="both_checkbox_elems dis__button"
            data-error="Answer required"
            data-quest="Please answer second question."
          >
            <p>
              <input
                type="radio"
                id="test3"
                name="header_client_question2_yes"
                className="track_class"
                checked={store.header_client_question2 === "Y"}
                onClick={event => {
                  store.setClass34Option("Y");
                }}
              />
              <label htmlFor="test3">
                <Trans>Yes</Trans>
              </label>
            </p>

            <p>
              <input
                type="radio"
                id="test4"
                name="header_client_question2_no"
                checked={store.header_client_question2 === "N"}
                onClick={event => {
                  store.setClass34Option("N");
                }}
              />
              <label htmlFor="test4">
                <Trans>No</Trans>
              </label>
            </p>
            <ErrorMsg msg={store.header_client_question2 ? "" : "Answer required"} classNo="H" />
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionTwo;
