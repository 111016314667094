import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Opt1Headers from "./Headers/Opt1Headers";
import Opt2Headers from "./Headers/Opt2Headers";
import StaffClasses from "./StaffClasses";
import MobileStaffClasses from "./MobileStaffClasses";
@inject("store")
@observer
class DesignYourPlan extends Component {
  render() {
    const { store } = this.props;

    const headers = () => {
      if (store.activeQuoteMethod == "opt1") {
        return <Opt1Headers lang={store.lang} />;
      } else {
        return <Opt2Headers lang={store.lang} />;
      }
    };

    if (store.isMobile) {
      return <MobileStaffClasses lang={store.lang} />;
    } else {
      return (
        <div className="design_block_main justify-content-between align-items-start mt-5 d-lg-flex d-md-flex d-sm-none d-none">
          {headers()}
          <StaffClasses lang={store.lang} />
        </div>
      );
    }
  }
}

export default DesignYourPlan;
