import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from "i18next";

const { initMobileModal, planToOption } = require("../../util");

@inject("store")
@observer
class BusinessTravelBenefits extends Component {
  componentDidMount() {
    initMobileModal();
  }

  render() {
    const { store } = this.props;
    const isActive = option => {
      if (option == store["class_bta_plan_" + store.openModalClassNo]) return " active_hospit";
      return "";
    };
    return (
      <Fragment>
        <div className="small_hospit_wrap d-flex flex-column align-items-center justify-content-center m-0 p-2 col-12">
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Option 1")}
            onClick={event => {
              store.setBusinessTravelPlanOption(store.openModalClassNo, "Option 1");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 1</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div className="content_dhosp">
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>PERSONAL ASSISTANCE</Trans>
                </h6>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death & Permanent Disablement (Scale II)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,200,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Additional Indemnity for Public Conveyance</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,200,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Major Burns</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    150,000
                  </span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death due to Natural Catastrophe</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>MEDICAL EXPENSES AND ASSISTANCE</Trans>
                </h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Medical Expenses</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Cash (Daily Limit- HKD500)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">7,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Comatose State (Weekly Limit- HKD500, a maximum limit  of 50 weeks)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">25,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Worldwide Assistance Services</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Included</Trans></span>
                </div>
              </div>
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>TRAVEL INCONVENIENCE COVER</Trans>
                </h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Loss of Deposit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Trip Curtailment or Disruption</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Unexpected Delay (HKD500 for every 6 hours)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Travel Misconnection (after 24 hours)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">10,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Flight Overbooking (HKD500 for every 6 hours)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,500</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>LOSS OF PERSONAL EFFECTS OVERSEAS</Trans>
                </h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Baggage Delay (after 6 hours)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,500</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Personal Property</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">15,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Laptop</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">10,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Sports Equipment</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">5,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0"></span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Other items</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Personal Money and Loss of Travel Document (Cash Limit- HKD3,000)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">10,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>LIABILITY ISSUE WHILE ABOARD</Trans>
                </h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Personal Liability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,500,000</span>
                </div>
              </div>

          

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>EXTENDED PROTECTION TOWARDS THE EMPLOYER</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Alternative Employee Expenses</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                   20,000
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Political or Natural Disaster Evacuation</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                   15,000
                  </span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>EXTENDED WELLBEING TO THE EMPLOYEE</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Credit Card Protection</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Education Fee Subsidy</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">25,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Funeral Expenses & Repatriation of Remains</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                   20,000
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Home Modiﬁcation Expenses</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                   25,000
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">21</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Rehabilitation Tuition Expenses</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">25,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">22</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Scarring of Face</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">25,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">23</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Spouse Retraining</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">24</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trauma Incident Counselling Beneﬁt (HKD1,500 /day /visit)</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">15,000</span>
                </div>
              </div>
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>24 HOURS WORLDWIDE ASSISTANCE HOTLINE SERVICES</Trans></h6>
              </div>
              {/* <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">25</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Emergency Travel Services Assistance</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">26</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Telephone Medical Advice</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">27</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Inoculation information</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">28</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Consulate and Embassy Information</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">29</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Legal Referral Service</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Free</Trans></span>
                </div>
              </div> */}
            </div>
          </div>

         {/*} <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Option 2")}
            onClick={event => {
              store.setBusinessTravelPlanOption(store.openModalClassNo, "Option 2");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 2</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div className="content_dhosp">
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>PERSONAL ACCIDENT</Trans>
                </h6>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death and Permanent Disablement</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>ccidental Death and Permanent Disablement Due to War</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">90,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Terrorism Extension</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    <Trans>Covered</Trans>
                  </span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Burns Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,000,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Fracture Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">20,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death While Traveling in Common Carrie</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">175,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Compassionate Allowance</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">23,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Child Education Fund</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">85,000</span>
                </div>
              </div>
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>MEDICAL SECTION</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Medical Expenses</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,200,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Medical Expenses Incurred Upon Returning to Hong Kong</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Treatment by Traditional Chinese Medicine Practitioner and Chiropractor</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">4,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Hospital Daily Income Benefit (Overseas)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000 (1,000/day)</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Hospital Visit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Compassionate Visit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>EVACUATION AND REPATRIATION</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Greater China Card (Deposit-free Admission)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Emergency Medical Evacuation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Repatriation of Mortal Remains</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Emergency Telephone Charges</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>24-hour Medical and Travel Assistance</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>LIABILITY</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Personal Liability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">4,500,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>INCONVENIENCES</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">21</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Concelation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">22</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Curtailment</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">23</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Rearrangement</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">24</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Disruption</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">25</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Travel Delay</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    6,000 <br />
                    (1,200/6 hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">26</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Travel / Flight Misconnection</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,500 <br />
                    (600/6 hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">27</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Flight Diversion</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,800 <br />
                    (600/6hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">28</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Travel Overbooking</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,160</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">29</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Baggage Delay</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,500 <br />
                    (1,200/6 hrs)
                  </span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>PROPERTY LOSSES</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">30</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Loss of or Damage to Baggage & Personal Effects</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    23,000 <br />
                    (6,000/item)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">31</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Loss of or Damage to Portable Business Equipment</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    12,000 <br />
                    (6,000/item)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">32</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Loss of Personal Money and Travel Documents (Loss of Personal Money is limited to HK$3,000)</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">12,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>OTHERS</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">33</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Employee Replacement Benefit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">35,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">34</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Bail Bond Facility</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">85,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">35</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Hijack Benefit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    30,000 <br />
                    (1,200/12hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">36</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                  <Trans>Quarantine Following Infections Diseases</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    4,000 <br />
                    (300/day)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">37</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Automatic Extension</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">7 Days</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Option 3")}
            onClick={event => {
              store.setBusinessTravelPlanOption(store.openModalClassNo, "Option 3");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 3</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div className="content_dhosp">
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0">
                  <Trans>PERSONAL ACCIDENT</Trans>
                </h6>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death and Permanent Disablement</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,200,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death and Permanent Disablement Due to War</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">150,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Terrorism Extension</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Burns Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">3,200,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Fracture Benefit</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">32,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">6</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Accidental Death While Traveling in Common Carrier</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">300,000</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">7</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Compassionate Allowance</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">30,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">8</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Child Education Fund</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">145,000</span>
                </div>
              </div>
              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>MEDICAL SECTION</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">9</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Medical Expenses</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,750,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">10</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Medical Expenses Incurred Upon Returning to Hong Kong</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">11</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Treatment by Traditional Chinese Medicine Practitioner and Chiropractor</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">6,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">12</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Hospital Daily Income Benefit (Overseas)</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000 (1,000/day)</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">13</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Hospital Visit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">14</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Overseas Compassionate Visit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">45,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>EVACUATION AND REPATRIATION</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">15</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Greater China Card (Deposit-free Admission)</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">16</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Emergency Medical Evacuation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">2,000,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">17</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Repatriation of Mortal Remains</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2"><Trans>Covered</Trans></span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">18</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Emergency Telephone Charges</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,500</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">19</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    24-hour Medical and Travel Assistance</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">Covered</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>LIABILITY</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">20</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Personal Liability</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">6,000,000</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>INCONVENIENCES</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">21</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Concelation</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">22</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Curtailment</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">23</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Rearrangement</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">60,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">24</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Trip Disruption</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">40,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">25</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Travel Delay</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    6,000 <br />
                    (1,200/6 hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">26</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Travel / Flight Misconnection</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,500 <br />
                    (600/6 hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">27</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Flight Diversion</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,800 <br />
                    (600/6hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">28</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Travel Overbooking</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">1,450</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">29</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Baggage Delay</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    5,500 <br />
                    (1,200/6 hrs)
                  </span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>PROPERTY LOSSES</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">30</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Loss of or Damage to Baggage & Personal Effects</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    30,000 <br />
                    (6,000/item)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">31</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Loss of or Damage to Portable Business Equipment</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    12,000 <br />
                    (6,000/item)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">32</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Loss of Personal Money and Travel Documents (Loss of Personal Money is limited
                    to HK$3,000)</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">17,500</span>
                </div>
              </div>

              <div className="pers_acc d-flex justify-content-start align-items-start p3">
                <p className="col-1" />
                <h6 className="col-11 pl-0 text-uppercase pr-0"><Trans>OTHERS</Trans></h6>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">33</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Employee Replacement Benefit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">40,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">34</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Bail Bond Facility</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">85,000</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">35</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Hijack Benefit</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    30,000 <br />
                    (1,200/12hrs)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">36</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>                    Quarantine Following Infections Diseases</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">
                    6,000 <br />
                    (300/day)
                  </span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">37</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0"><Trans>Automatic Extension</Trans></p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0" />
                  <span className="mb-0 ml-2">7 Days</span>
                </div>
              </div>
            </div>
          </div>*/}

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Not Covered")}
            onClick={event => {
              store.setBusinessTravelPlanOption(store.openModalClassNo, "Not Covered");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold"><Trans>Not Covered</Trans></p>
            </div>
          </div>
        </div>
        <div className="bottom_small_wd pl-3 pr-3 pt-3 pb-1">
          <div className="small_wd1 d-flex align-items-center mb-2 ">
            <div className="col-lg-6 sma-l p-0">
              <p className="m-0 font-weight-bold">
                *<Trans>All prices in HKD</Trans>
              </p>
            </div>
            <div className="col-lg-6 sma-r p-0">
              <h6 className="m-0 text-right selected_text ">
                <Trans>Selected</Trans>:
                <span className="font-weight-bold text-uppercase">
                  {i18next.t(planToOption(store["class_bta_plan_" + store.openModalClassNo]))}
                </span>
              </h6>
            </div>
          </div>
          <div className="small_wd2 d-flex align-items-center ">
            <div className="col-lg-6 sma-l p-0">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_bta_plan_" + store.openModalClassNo);
                  store.toggleBusinessTravelModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
            </div>
            <div className="col-lg-6 sma-r p-0 d-flex justify-content-end">
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleBusinessTravelModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BusinessTravelBenefits;
