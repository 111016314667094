import React, { Component, Fragment, useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import AreaDropdown from "./AreaDropdown";
import Reimburse from "./ReimburseDropdown";
import BusinessMembersRange from "./BusinessTravelersDropdown";
import ErrorMsg from "../../../../InlineErrorMsg";
import { Trans } from "react-i18next";

import i18next from "i18next";
import { initTooltip } from "../../../../util";
@inject("store")
@observer
class StaffClassByOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalOnly: null,
      hospitalOnlyLabel: "Select Premium",
      employeeNo: 0,
      spouseNo: 0,
      childrenNo: 0,
      isDropdownOpen: false
    };
  }
  componentDidMount() {
    initTooltip();
  }

  render() {
    const { store, classNo } = this.props;
    const hospitalPlan = store["class_hospitalPlan_" + classNo];

    return (
      <Fragment>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="grey_space d-flex align-items-center justify-content-start switching_"
        />

        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="hospitalization_block d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_ dis__hosp"
        >
          <div className="d_modal d-flex justify-content-center align-items-center">
            {hospitalPlan ? (
              <input
                className="rounded font-weight-bold"
                name="class_hospitalPlan_0 "
                type="text"
                readOnly
                value={i18next.t(hospitalPlan.replace(/plan/gi, "Option "))}
                style={{ display: "inline-block" }}
                onClick={event => {
                  event.preventDefault();
                  store.toggleHospitalBenefitsModal(classNo);
                }}
              />
            ) : (
              <Fragment>
                <a
                  data-error="Staff Class 1"
                  className="rounded d-flex justify-content-center align-items-center font-weight-bold"
                  name="hospitalPlan_btn"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    store.toggleHospitalBenefitsModal(classNo);
                  }}
                  style={
                    store["displayError_class_" + classNo] && !hospitalPlan
                      ? { border: "1px solid red" }
                      : { color: "#fff" }
                  }
                >
                  <img src={"img/" + "plus_white.png"} className="mr-2" alt="plus_white" />
                  <Trans>Select Option</Trans>
                </a>
                <ErrorMsg msg={"Please select one"} classNo={classNo} />
              </Fragment>
            )}
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="patient_block d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
        >
          <div className="d_modal d-flex justify-content-center align-items-center">
            {store["class_outPPlan_attr1_" + classNo] ? (
              <input
                className="rounded font-weight-bold "
                type="text"
                readOnly
                value={
                  store["class_outPPlan_attr1_" + classNo]
                    ? i18next.t(store["class_outPPlan_attr1_" + classNo].replace(/plan/gi, "Option "))
                    : i18next.t("Not Covered")
                }
                onClick={event => {
                  event.preventDefault();
                  store.toggleoutpatientBenefitsModal(classNo);
                }}
              />
            ) : (
              <Fragment>
                <a
                  data-error="Staff Class 1"
                  className="rounded d-flex justify-content-center align-items-center font-weight-bold"
                  name="hospitalPlan_btn"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    store.toggleoutpatientBenefitsModal(classNo);
                  }}
                  style={
                    store["displayError_class_" + classNo] && !hospitalPlan
                      ? { border: "1px solid red" }
                      : { color: "#fff" }
                  }
                >
                  <img src={"img/" + "plus_white.png"} className="mr-2" alt="plus_white" />
                  <Trans>Select Option</Trans>
                </a>
              </Fragment>
            )}
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className={
            "reimburse_block child_small d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_ " +
            (store["class_outPPlan_attr1_" + classNo] == "" ||
            store["class_outPPlan_attr1_" + classNo] == "Not Covered"
              ? " not_active_"
              : "")
          }
        >
          <Reimburse classNo={classNo} />
        </div>

        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="grey_space switching_"
        />
        <div
          className={
            "dental_block child_small d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_" +
            (store["class_outPPlan_attr1_" + classNo] == "" ||
            store["class_outPPlan_attr1_" + classNo] == "Not Covered"
              ? " not_active_"
              : "")
          }
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <div className="d_modal">
            <input
              className="font-weight-bold rounded"
              name="class_dentalPlan_0"
              readOnly
              type="text"
              value={
                store["class_dentalPlan_" + classNo]
                  ? i18next.t(store["class_dentalPlan_" + classNo].replace(/plan/gi, "Option "))
                  : i18next.t("select")
              }
              onClick={event => {
                event.preventDefault();
                store.toggleDentalBenefitsModal(classNo);
              }}
            />
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="term_block  d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
        >
          <div className="d_modal">
            <input
              className="font-weight-bold rounded"
              name="class_tLifePlan_0"
              type="text"
              value={
                store["class_tLifePlan_" + classNo]
                  ? i18next.t(store["class_tLifePlan_" + classNo].replace(/plan/gi, "Option "))
                  : i18next.t("select")
              }
              readOnly
              onClick={event => {
                event.preventDefault();
                store.toggleTermLifeBenefitsModal(classNo);
              }}
            />
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className={
            "business_block  d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_" +
            (hospitalPlan ? "" : " not_active_")
          }
        >
          <div className="d_modal" style={classNo == "0" ? {}:{display:"none"}}>
            <input
              className="font-weight-bold rounded"
              name="class_bta_plan_0"
              type="text"
              value={
                store["class_bta_plan_" + classNo]
                  ? i18next.t(store["class_bta_plan_" + classNo].replace(/plan/gi, "Option "))
                  : i18next.t("select")
              }
              readOnly
              onClick={event => {
                event.preventDefault();
                store.toggleBusinessTravelModal(classNo);
              }}
            />
          </div>
        </div>

        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className={
            "travel_cov_block child_small d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_ "
           + (store["class_bta_plan_" + classNo] == "" ||
            store["class_bta_plan_" + classNo] == "Not Covered"
              ? " not_active_"
              : "")
          }
        >
         <p style={classNo == "0" ? {}:{display:"none"}}> <Trans>Worldwide</Trans></p>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className={
            "reimburse_block child_small  d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_" +
            (store["class_bta_plan_" + classNo] == "" ||
            store["class_bta_plan_" + classNo] == "Not Covered"
              ? " not_active_"
              : "")
          }
        >       
          <BusinessMembersRange  classNo={classNo} />
        </div>

     
      </Fragment>
    );
  }
}

export default StaffClassByOption;
