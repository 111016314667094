import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
@inject("store")
@observer
class StaffClassTotal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { store, classNo } = this.props;

    return (
      <div className="res_lu d-flex justify-content-between">
        <div className="col-6 lf_small d-flex align-items-center justify-content-start main_res_lu ">
          <h2 className="m-0 font-weight-bold">
            <Trans>Total Premium By Class</Trans>
          </h2>
        </div>
        <div className="col-6  rf_small not_active_  d-flex align-items-center">
          <p className="m-0 font-weight-bold" name="class_totalPremium_0">
            HK$ {store.quote["premiumClass" + classNo]}
          </p>
        </div>
      </div>
    );
  }
}

export default StaffClassTotal;
