import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import * as RegexSetting from "../../../config/regex";
import i18next from "i18next";

@inject("store")
@observer
class ContactInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      clientEmailFormatError:false,
      clientEmailEmptyError:false
    };
  }

  render() {
    const { store, moveNextStep, moveBackStep } = this.props;

    const content = () => {
      return (
        <div className="contact_elem sec_contact flex-column col-12 p-0  d-flex">
          <h2 className="mt-0 mb-3">
            <Trans>Client Information</Trans>
          </h2>
          <div className="wrap_form d-flex flex-column">
            <div className="el_form mb-3 col-6 p-0">
              <p className="mb-0">
                <Trans>Company Name</Trans>
              </p>
              <input
                className="rounded pl-2 pr-2"
                type="text"
                name="header_client_name"
                placeholder={i18next.t("Name of Company")}
                value={store["header_client_name"]}
                onChange={event => {
                  store.setHeaderField("header_client_name", event.target.value);
                }}
              />
            </div>
            <div className="el_form mb-3 col-6 p-0">
              <p className="mb-0">
                <Trans>Contact Person</Trans>
              </p>
              <input
                className="rounded pl-2 pr-2"
                type="text"
                name="header_client_person"
                placeholder={i18next.t("Full Name")}
                value={store["header_client_person"]}
                onChange={event => {
                  store.setHeaderField("header_client_person", event.target.value);
                }}
              />
            </div>
            <div className="two_el_form col-11 d-flex flex-row p-0 mb-sm-5 mb-4">
              <div className="el_form col-5 p-0">
                <p className="mb-0">
                  <Trans>Contact Number</Trans>
                </p>
                <input
                  className="rounded pl-2 pr-2 cont_phone"
                  name="header_client_no"
                  type="text"
                  placeholder="+852"
                  value={store["header_client_no"]}
                  onChange={event => {
                    store.setHeaderField("header_client_no", event.target.value);
                  }}
                />
              </div>
              <div className="el_form position_relative offset-1 col-5 p-0">
                <p className="mb-0">
                  <Trans>Email Address</Trans>
                </p>
                <input
                  className="mail_inp rounded pl-2 pr-2"
                  name="header_client_email"
                  type="text"
                  placeholder={i18next.t("Email")}
                  value={store["header_client_email"]}
                  onChange={event => {
                    store.setHeaderField("header_client_email", event.target.value);
                  }}
                  style={this.state.error ? { border: "1px solid red" } : { width: "100%" }}
                />
                {this.state.clientEmailEmptyError && (
                  <div>
                    <Trans>Fill this field</Trans>.
                  </div>
                )}
                {this.state.clientEmailFormatError && (
                  <div>
                    <Trans>Invalid Email Address</Trans>.
                  </div>
                )}
                <span />
              </div>
            </div>
            <div className="subm_el_form d-flex justify-content-end align-items-center">
              <div className="col-6 left-subm-el">
                <a
                  href="#"
                  name="contact_back"
                  onClick={event => {
                    event.preventDefault();
                    moveBackStep();
                  }}
                >
                  <img className="mr-2" src={"img/" + "right_arrow.png"} alt="right_arrow" />
                  <Trans>Back</Trans>
                </a>
              </div>
              <div className="col-6 right-subm-el d-flex justify-content-end p-0">
                <a
                  href="#"
                  name="contact_skip_2"
                  className="skip_button rounded font-weight-bold d-flex align-items-center justify-content-center mr-3"
                  onClick={event => {
                    event.preventDefault();
                    store.setHeaderField("header_client_email", "");
                    store.setHeaderField("header_client_no", "");
                    store.setHeaderField("header_client_name", "");
                    store.setHeaderField("header_client_person", "");
                    moveNextStep();
                  }}
                >
                  <Trans>Skip</Trans>
                </a>
                <a
                  href="#"
                  name="contact_next_2"
                  className="next_button rounded font-weight-bold d-flex align-items-center justify-content-center"
                  onClick={event => {
                    event.preventDefault();
                    this.setState({
                      clientEmailEmptyError: false,
                      clientEmailFormatError: false,
                      error: false
                    },()=>{
                      if (store.header_client_email) {
                        if(!(RegexSetting.email_regex.test(store.header_client_email)))
                        {
                          this.setState({ clientEmailEmptyError: false });
                          this.setState({ clientEmailFormatError: true });
                          this.setState({ error: true });
                        }
                        else
                        {
                          this.setState({ clientEmailEmptyError: false });
                          this.setState({ clientEmailFormatError: false });
                          this.setState({ error: false });
                          moveNextStep();
                        }
                      }
                      else {
                        moveNextStep();
                      }

                    });

                  }}
                >
                  <Trans>Next</Trans>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="container contact_block d-flex flex-column col-12 p-0">
        <div className="contact_head d-flex align-items-start justify-content-start">
          <h2 className="mt-0 mb-3 ">
            <img className="mr-2" src={"img/" + "ccl_mail.png"} alt="ccl_mail" />
            <Trans>Contact Information</Trans>
            <span>
              (<Trans>Optional</Trans>)
            </span>
          </h2>
        </div>

        <div className="contact_block">{content()}</div>
      </div>
    );
  }
}

export default ContactInfoModal;
