import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ErrorMsg from "../../../../InlineErrorMsg";
import { Trans } from "react-i18next";
import i18next from "i18next";

@inject("store")
@observer
class ChooseClassDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  setValue(value) {
    this.props.store.setTravelLocation(this.props.classNo, value);
  }

  handleClick(e) {
    if (this.refs.menu.contains(e.target)) {
      return;
    }
    this.setState({ isMenuOpen: false });
  }
  componentDidMount() {
    document.addEventListener("touchstart", this.handleClick);
    document.addEventListener("mousedown", this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("touchstart", this.handleClick);
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const { store, classNo } = this.props;
    const currentValue = store["class_name_" + classNo];
    const isActiveClassType = type => {
      if (type == currentValue) {
        return "active_ul_type ";
      } else {
        return "";
      }
    };

    const innerInput = () => {
      return (
        <div className="drop_block position-relative">
          <input
            name="class_name_0"
            className="pl-2 pr-4 rounded font-weight-bold"
            type="text"
            value="All Staff"
            readOnly
            style={{ display: "inline-block" }}
            value={currentValue}
            onClick={event => {
              this.setState({ isMenuOpen: true });
            }}
          />
          {this.state.isMenuOpen && (
            <div className="drop_mod_des flex-column column position-absolute rounded">
              <p className="pl-3 pr-3 mt-0 mb-2 font-weight-bold">
                <Trans>Select a Class Type</Trans>
              </p>
              <ul>
                <li className={isActiveClassType(i18next.t("All Staff"))}>
                  <a
                    href="#"
                    className="pl-3 pr-3 pt-2 pb-2"
                    onClick={event => {
                      event.preventDefault();
                      store.setClassName(classNo, i18next.t("All Staff"));
                      this.setState({ isMenuOpen: false });
                    }}
                  >
                    <Trans>All Staff</Trans>
                  </a>
                </li>
                <li className={isActiveClassType(i18next.t("General Staff / Admin Staff"))}>
                  <a
                    href="#"
                    className="pl-3 pr-3 pt-2 pb-2"
                    onClick={event => {
                      event.preventDefault();

                      store.setClassName(classNo, i18next.t("General Staff / Admin Staff"));
                      this.setState({ isMenuOpen: false });
                    }}
                  >
                    <Trans>General Staff / Admin Staff</Trans>
                  </a>
                </li>
                <li className={isActiveClassType(i18next.t("Senior / Supervisor Staff"))}>
                  <a
                    href="#"
                    className="pl-3 pr-3 pt-2 pb-2"
                    onClick={event => {
                      event.preventDefault();

                      store.setClassName(classNo, i18next.t("Senior / Supervisor Staff"));
                      this.setState({ isMenuOpen: false });
                    }}
                  >
                    <Trans>Senior / Supervisor Staff</Trans>
                  </a>
                </li>
                <li className={isActiveClassType(i18next.t("Manager Level"))}>
                  <a
                    href="#"
                    className="pl-3 pr-3 pt-2 pb-2"
                    onClick={event => {
                      event.preventDefault();

                      store.setClassName(classNo, i18next.t("Manager Level"));
                      this.setState({ isMenuOpen: false });
                    }}
                  >
                    <Trans>Manager Level</Trans>
                  </a>
                </li>
                <li className={isActiveClassType(i18next.t("Director / Chairman Level"))}>
                  <a
                    href="#"
                    className="pl-3 pr-3 pt-2 pb-2"
                    onClick={event => {
                      event.preventDefault();

                      store.setClassName(classNo, i18next.t("Director / Chairman Level"));
                      this.setState({ isMenuOpen: false });
                    }}
                  >
                    <Trans>Director / Chairman Level</Trans>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      );
    };

    const wrapper = () => {
      return (
        <Fragment>
          <a
            href="#"
            className="choose_one rounded d-flex align-items-center justify-content-center"
            data-quest="Staff Class 1"
            name="choose_class_btn"
            onClick={event => {
              event.preventDefault();
              this.setState({
                isMenuOpen: true
              });
            }}
            style={
              store["displayError_class_" + classNo] && !currentValue
                ? { border: "1px solid red" }
                : { color: "#fff" }
            }
          >
            <img className="mr-2" src={"img/" + "plus_white.png"} alt="plus_white" />
            {currentValue ? i18next.t(currentValue) : i18next.t("Choose Class")}
          </a>
          <ErrorMsg msg={currentValue ? "" : "Select an option"} classNo={classNo} />
        </Fragment>
      );
    };

    return (
      <div
        className="col-6 rf_small classification_ dis__sec d-flex align-items-center justify-content-center"
        ref={"menu"}
      >
        {!currentValue && !this.state.isMenuOpen ? wrapper() : innerInput()}
      </div>
    );
  }
}

export default ChooseClassDropdown;
