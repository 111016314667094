import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

import i18n from "i18next";
import i18next from "i18next";

@inject("store")
@observer
class Opt1Headers extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { store } = this.props;
    return (
      <div className="column_one col-3 pl-0 pr-0 ">
        <div style={{ display: "none" }}>{this.props.store.activeQuoteMethod}</div>
        <h2 className="font-weight-bold m-0 drow_1 pl-0 pr-2 d-flex align-items-center">
          <Trans>Design Your Plan</Trans>
        </h2>

        <div className="column_one_block pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <p className="mb-0 stand_text text-right">
            <Trans>Classification of Insured</Trans>
          </p>
        </div>
        <div className="grey_space pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex align-items-center justify-content-start">
          <h2 className="m-0 font-weight-bold">
            <Trans>Number of Insured Members</Trans>
          </h2>
        </div>
        <div className="employees_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Employees</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18next.t("The number of staff that will be insured by the plan.")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div className="spouse_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Spouses</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18next.t(
                "The number of spouses  of staff that will be insured by the plan."
              )}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div className="children_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Children</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18next.t("No-of-children-helper-text")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div
          className="grey_space pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex align-items-center justify-content-start switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 font-weight-bold">
            <Trans>Major Coverage</Trans>
          </h2>
        </div>
        <div
          className="hospitalization_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_ dis__hospdis__hosp"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0">
            <Trans>Hospitalization</Trans>
          </h2>
        </div>
        <div
          className="patient_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0">
            <Trans>Out-Patient</Trans>
          </h2>
        </div>
        <div
          className="reimburse_block child_small d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center">
            <Trans>Out-Patient Reimbursement</Trans>
            <button
              className="ml-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18next.t("outpatient-reimbursement-helper-text")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div
          className="grey_space pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex align-items-center justify-content-start switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 font-weight-bold">
            <Trans>Additional Riders</Trans>
          </h2>
        </div>
        <div
          className="dental_block child_small d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center">
            <Trans>Dental</Trans>
            <button
              className="ml-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18next.t("dental-helper-text")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div
          className="term_block  d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center">
            <Trans>Term Life</Trans>
          </h2>
        </div>
        <div
          className="business_block  d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center text-right">
            <Trans>Business Easy Smart Travel (BEST)</Trans>
          </h2>
        </div>
        <div
          className="travel_cov_block child_small  d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
        </div>
        <div
          className="business_count_block child_small  d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center">
            <Trans>No. of Frequent Business Travelers</Trans>
            <button
              className="ml-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18n.t("no-of-frequent-travelers-helper-text")}
              aria-describedby="tooltip931279"
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>

        <div className="res_lu main_res_lu d-flex align-items-center justify-content-start  pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2">
          <h2 className="m-0 font-weight-bold">
            <Trans>Total Premium By Class</Trans>
          </h2>
        </div>
      </div>
    );
  }
}

export default Opt1Headers;
