import React, { Component, Fragment } from "react";
import AboutEasyCareModal from "./AboutEasyCareModal";
import AgeCalculatorModal from "./AgeCalculatorModal";
import HospitalBenefit from "./HospitalBenefit";
import OutpatientBenefits from "./OutPatientBenefits";
import DentalBenefits from "./DentalBenefits";
import TermLifeBenefits from "./TermLifeBenefits";
import BusinessTravelBenefits from "./BusinessTravelBenefits";
import Clas34Info from "./Class34Info";
import GetQuoteModal from "./GetQuoteModal";
import MoreInfoRequiredModal from "./MoreInfoRequiredModal";
import { inject, observer } from "mobx-react";
@inject("store")
@observer
class Modals extends Component {
  render() {
    const { store } = this.props;
    return (
      <Fragment>
        <AboutEasyCareModal lang={store.lang} />
        <AgeCalculatorModal lang={store.lang} />
        <HospitalBenefit lang={store.lang} />
        <OutpatientBenefits lang={store.lang} />
        <DentalBenefits lang={store.lang} />
        <TermLifeBenefits lang={store.lang} />
        <BusinessTravelBenefits lang={store.lang} />
        <Clas34Info lang={store.lang} />
        <GetQuoteModal lang={store.lang} />
        <MoreInfoRequiredModal lang={store.lang} />
      </Fragment>
    );
  }
}

export default Modals;
