import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class OutpatientDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  setValue(value) {
    this.props.store.setTravelLocation(this.props.classNo, value);
  }

  handleClick(e) {
    if (this.refs.menu.contains(e.target)) {
      return;
    }
    this.setState({ isMenuOpen: false });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const { store, classNo } = this.props;
    const currentSelection = store["class_outPPlan_attr1_" + classNo];

    const isActive = value => {
      if (currentSelection == value) return " active_ul_type ";
      else return "";
    };
    const option = opt => {
      return (
        <li className={isActive(opt.plan) + " pl-3 pr-3 pt-2 pb-2"}>
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              store.setHospitalOutpatientOption(classNo, opt);
              this.setState({ isMenuOpen: false });
            }}
          >
            {"HK$ " + opt.label}
          </a>
        </li>
      );
    };

    const options = () => {
      return store.budgetOptions.map(opt => {
        if (opt.age === store.version_ageGroup && opt.inout == "INOUT") {
          return option(opt);
        }
      });
    };

    const wrapper = () => {
      return (
        <a
          href="#"
          name="budget_INOUT_btn"
          className="rounded  justify-content-center align-items-center font-weight-bold"
          onClick={event => {
            event.preventDefault();
            this.setState({
              isMenuOpen: true
            });
          }}
        >
          <img className="mr-2" src={"img/" + "plus_white.png"} alt="plus_white" />
          <Trans>Select Premium</Trans>
        </a>
      );
    };

    const innerInput = () => {
      return (
        <div
          className="drop_mod position-relative align-items-center justify-content-center"
          style={{ display: "inline-block" }}
        >
          <input
            type="text"
            className="rounded font-weight-bold input_open_modal"
            value={store["i_hospitalOutpientOptionLabel_" + classNo]}
            onClick={event => {
              this.setState({ isMenuOpen: true });
            }}
          />
          {this.state.isMenuOpen && (
            <div
              style={{ display: "inline-block" }}
              className="drop_mod_des  flex-column position-absolute rounded"
            >
              <p className="pl-3 pr-3 mt-0 mb-2 font-weight-bold">
                <Trans>Select Premium</Trans>
              </p>
              <ul>{options()}</ul>
            </div>
          )}
        </div>
      );
    };

    return (
      <div
        data-error="Staff Class 1"
        className="prem_hops2_block  one_of_two  d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching__"
        ref={node => {
          if (node) {
            node.style.setProperty("display", "flex", "important");
          }
        }}
        ref={"menu"}
      >
        {!currentSelection && !this.state.isMenuOpen ? wrapper() : innerInput()}
      </div>
    );
  }
}

export default OutpatientDropdown;
