import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import StaffClassByBudeget from "./ClassByBudget";
import StaffClassByOption from "./ClassByOption";

@inject("store")
@observer
class StaffClassWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isClassActive: false };
  }
  render() {
    const { store } = this.props;

    if (store.activeQuoteMethod == "opt1") {
      return (
        <StaffClassByOption
          classNo={this.props.classNo}
          index={this.props.index}
          removeClass={this.props.removeClass}
          lang={store.lang}
        />
      );
    } else {
      return (
        <StaffClassByBudeget
          classNo={this.props.classNo}
          index={this.props.index}
          removeClass={this.props.removeClass}
          lang={store.lang}
        />
      );
    }
  }
}
export default StaffClassWrapper;
