import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import { planToOption } from "../../util";
import i18next from "i18next";

@inject("store")
@observer
class DentalBenefits extends Component {
  render() {
    const { store } = this.props;
    let valueSelected = store["class_dentalPlan_" + store.openModalClassNo];
    const isActive = option => {
      if (option == valueSelected) return " active_wed_el";
      return "";
    };

    return (
      <Fragment>
        <div className="hospit_wrap hospit_with_paddings col-12 p-0 d-flex align-items-start justify-content-between pl-3 pr-3">
          <div className="col-lg-7 col-md-9 p-0 pt-5 left_hospit">
            <h2 className="font-weight-bold mb-3">
              <Trans>COVERAGE</Trans>
            </h2>
            <div className="wrap_hospit_dchar">
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">1</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Oral examination, Scaling & Polishing</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>Once a year, limit per visit</Trans>
                </h6>
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">2</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Dental X-ray & Medications</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">3</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Filling & Simple Extractions</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>

              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">4</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Emergency treatment of dental pain (Palliative)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>

              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">5</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Impaction</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>

              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">6</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Denture (due to accident only)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>

              <div className="dchar_elem max_grey no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Overall Maximum</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" ><Trans>per policy year</Trans></h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 right_hospit p-0 d-flex align-items-start justify-content-between ">
            <div
              className={
                "col-lg-5 col-md-10 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN1")
              }
              onClick={event => {
                store.setDentalOption(store.openModalClassNo, "PLAN1");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 1</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_wd d-flex align-items-center justify-content-between">
          <div className="col-lg-5 col-md-5 left_wd d-flex align-items-center justify-content-start not_covered">
            <div className={"check_wd d-flex align-items-center justify-content-center rounded mb-0 mr-2 "+isActive("Not Covered")}
            onClick={event => {
              store.setDentalOption(store.openModalClassNo, "Not Covered");
            }}
            >
              <p
                className="m-0"
              >
                <input type="checkbox" id="cover3" checked={valueSelected == "Not Covered"} />
                <div class="bl_circle align-items-center justify-content-center not_covered_full"><img src="img/smallds.png" alt="smallds"/></div>
                <span class="text-center font-weight-bold text-uppercase"><Trans>Not Covered</Trans></span>
              </p>
            </div>
            <p className="m-0">
              *<Trans>All prices in HKD</Trans>
            </p>
          </div>
          <div className="col-lg-7 col-md-7 right_wd d-flex align-items-center justify-content-end">
            <div className="res_wnd mr-2">
              <p className="text-center mr-2 mb-0">
                <Trans>Selected</Trans>: <span>{i18next.t(planToOption(valueSelected))}</span>
              </p>
            </div>
            <div className="rd_buttons d-flex align-items-center">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center mr-3"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_dentalPlan_" + store.openModalClassNo);
                  store.toggleDentalBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleDentalBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DentalBenefits;
