import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import AreaDropdown from "./AreaDropdown";
import Reimburse from "./ReimburseDropdown";
import BusinessMembersRange from "./BusinessTravelersDropdown";
import StaffClassCommon from "../StaffClassCommon";
import ErrorMsg from "../../../../InlineErrorMsg";
import StaffClassTotal from "../StaffClassTotal";
import { Trans } from "react-i18next";
import i18next from "i18next";

import { initTooltip } from "../../../../util";

@inject("store")
@observer
class StaffClassByOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalOnly: null,
      hospitalOnlyLabel: "Select Premium",
      employeeNo: 0,
      spouseNo: 0,
      childrenNo: 0,
      isDropdownOpen: false
    };
  }
  componentDidMount() {
    initTooltip();
  }

  render() {
    const { store, classNo } = this.props;

    const hospitalPlan = store["class_hospitalPlan_" + classNo];

    return (
      <div className="elem_design_small d-flex flex-column align-items-center justify-content-center whole_col">
        <StaffClassCommon
          classNo={classNo}
          index={this.props.index}
          removeClass={this.props.removeClass}
          lang={store.lang}
        />

        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="grey_space d-flex align-items-center justify-content-start switching_"
        >
          <div className="col-12">
            <p className="mb-0 font-weight-bold">
              <Trans>Major Coverage</Trans>
              <button
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18next.t(
                  "What is your budget for each insured's premium per year?"
                )}
              >
                <img src={"img/" + "info.png"} alt="info" />
              </button>
            </p>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="small_block_d1 hsix d-flex justify-content-between switching_ h_eight"
        >
          <div className="col-6 lf_small  d-flex align-items-center justify-content-end ">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Hospitalization</Trans>
            </p>
          </div>

          <div className="col-6 rf_small hospitalization_block d-flex align-items-center justify-content-center dis__hosp">
            <div className="d_modal d-flex justify-content-center align-items-center">
              {hospitalPlan ? (
                <input
                  className="rounded font-weight-bold"
                  name="class_hospitalPlan_0 "
                  type="text"
                  readOnly
                  value={hospitalPlan.replace(/plan/gi, "Option ")}
                  style={{ display: "inline-block" }}
                  onClick={event => {
                    event.preventDefault();
                    store.toggleHospitalBenefitsModal(classNo);
                  }}
                />
              ) : (
                <Fragment>
                  <a
                    data-error="Staff Class 1"
                    className="rounded d-flex justify-content-center align-items-center font-weight-bold"
                    name="hospitalPlan_btn"
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      store.toggleHospitalBenefitsModal(classNo);
                    }}
                    style={
                      store["displayError_class_" + classNo] && !hospitalPlan
                        ? { border: "1px solid red" }
                        : { color: "#fff" }
                    }
                  >
                    <img src={"img/" + "plus_white.png"} className="mr-2" alt="plus_white" />
                    <Trans>Select Option</Trans>
                  </a>
                  <ErrorMsg msg={hospitalPlan ? "" : "Please select one"} classNo={classNo} />
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="small_block_d1  hsix d-flex justify-content-between switching_"
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Out-Patient</Trans>
            </p>
          </div>
          <div
            className={
              "col-6 rf_small patient_block d-flex align-items-center justify-content-center " +
              "_not_active_"
            }
          >
            <div className="d_modal d-flex justify-content-center align-items-center">
              <input
                className="rounded font-weight-bold "
                type="text"
                readOnly
                value={
                  store["class_outPPlan_attr1_" + classNo]
                    ? store["class_outPPlan_attr1_" + classNo].replace(/plan/gi, "Option ")
                    : i18next.t("Not Covered")
                }
                name="class_outPPlan_attr1_0"
                onClick={event => {
                  event.preventDefault();
                  store.toggleoutpatientBenefitsModal(classNo);
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className={
            "small_block_d1  hsix d-flex justify-content-between switching_" +
            (store["class_outPPlan_attr1_" + classNo] == "" ||
            store["class_outPPlan_attr1_" + classNo] == "Not Covered"
              ? " half_text"
              : "")
          }
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Out-Patient Reimbursement</Trans>
              <button
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18next.t("outpatient-reimbursement-helper-text")}
              >
                <img src={"img/" + "info.png"} alt="info" />
              </button>
            </p>
          </div>
          <div
            className={
              "col-6 rf_small reimburse_block   patient_block d-flex align-items-center justify-content-center" +
              (store["class_outPPlan_attr1_" + classNo] == "" ||
              store["class_outPPlan_attr1_" + classNo] == "Not Covered"
                ? " not_active_"
                : "")
            }
          >
            <Reimburse classNo={classNo} />
          </div>
        </div>
        <div
          className="grey_space d-flex align-items-center justify-content-start switching_"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <div className="col-12">
            <p className="mb-0 font-weight-bold">
              <Trans>Additional Riders</Trans>
            </p>
          </div>
        </div>
        <div
          className={
            "small_block_d1  hsix d-flex justify-content-between switching_" +
            (store["class_outPPlan_attr1_" + classNo] == "" ||
            store["class_outPPlan_attr1_" + classNo] == "Not Covered"
              ? " not_active_"
              : "")
          }
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Dental</Trans>
              <button
                className="ml-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Dental Benefits (Optional Cover) (80% Reimbursement)"
              >
                <img src={"img/" + "info.png"} alt="info" />
              </button>
            </p>
          </div>
          <div
            className={
              "col-6 rf_small reimburse_block  patient_block d-flex align-items-center justify-content-center"
            }
          >
            <div className="d_modal">
              <input
                className="font-weight-bold rounded"
                name="class_dentalPlan_0"
                readOnly
                type="text"
                value={
                  store["class_dentalPlan_" + classNo]
                    ? store["class_dentalPlan_" + classNo].replace(/plan/gi, "Option ")
                    : i18next.t("select")
                }
                onClick={event => {
                  event.preventDefault();
                  store.toggleDentalBenefitsModal(classNo);
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="small_block_d1  hsix d-flex justify-content-between switching_"
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right">
              <Trans>Term Life</Trans>
            </p>
          </div>

          <div className="col-6 rf_small  reimburse_block  patient_block d-flex align-items-center justify-content-center">
            <div className="d_modal">
              <input
                className="font-weight-bold rounded"
                name="class_tLifePlan_0"
                type="text"
                value={
                  store["class_tLifePlan_" + classNo]
                    ? store["class_tLifePlan_" + classNo].replace(/plan/gi, "Option ")
                    : i18next.t("select")
                }
                readOnly
                onClick={event => {
                  event.preventDefault();
                  store.toggleTermLifeBenefitsModal(classNo);
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              if(classNo=="0")
                node.style.setProperty("display", "flex", "important");
              else
                node.style.setProperty("display", "none", "important");
            }
          }}
          className="small_block_d1  hsix d-flex justify-content-between switching_"
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Business Easy Smart Travel (BEST)</Trans>
            </p>
          </div>
          <div
            className={
              "col-6 rf_small  reimburse_block  patient_block d-flex align-items-center justify-content-center" +
              (hospitalPlan ? "" : " not_active_")
            }
          >
            <div className="d_modal">
              <input
                className="font-weight-bold rounded"
                name="class_bta_plan_0"
                type="text"
                value={
                  store["class_bta_plan_" + classNo]
                    ? store["class_bta_plan_" + classNo].replace(/plan/gi, "Option ")
                    : i18next.t("select")
                }
                readOnly
                onClick={event => {
                  event.preventDefault();
                  store.toggleBusinessTravelModal(classNo);
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              if(classNo=="0")
                node.style.setProperty("display", "flex", "important");
              else
                node.style.setProperty("display", "none", "important");
            }
          }}
          className={
            "small_block_d1  hsix d-flex justify-content-between switching_ " +
            (store["class_bta_plan_" + classNo] == "" ||
            store["class_bta_plan_" + classNo] == "Not Covered"
              ? " half_text"
              : "")
          }
        >
          <div
            className={
              "col-6 rf_small reimburse_block  patient_block d-flex align-items-center justify-content-center" +
              (store["class_bta_plan_" + classNo] == "" ||
              store["class_bta_plan_" + classNo] == "Not Covered"
                ? " not_active_"
                : "")
            } style={{marginLeft:"50%",borderLeft:"1px solid rgba(51, 55, 64, 0.05)"}}
          >
            <p> <Trans>Worldwide</Trans></p>
          </div>
        </div>
        <div
          ref={node => {
            if (node) {
              if(classNo=="0")
                node.style.setProperty("display", "flex", "important");
              else
                node.style.setProperty("display", "none", "important");
            }
          }}
          className={
            "small_block_d1  hsix d-flex justify-content-between switching_" +
            (store["class_bta_plan_" + classNo] == "" ||
            store["class_bta_plan_" + classNo] == "Not Covered"
              ? " half_text"
              : "")
          }
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>No. of Frequent Business Travelers</Trans>
              <button
                className="ml-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18next.t("no-of-frequent-travelers-helper-text")}
              >
                <img src={"img/" + "info.png"} alt="info" />
              </button>
            </p>
          </div>
          <div
            className={
              "col-6 rf_small reimburse_block  patient_block d-flex align-items-center justify-content-center" +
              (store["class_bta_plan_" + classNo] == "" ||
              store["class_bta_plan_" + classNo] == "Not Covered"
                ? " not_active_"
                : "")
            }
          >
           <BusinessMembersRange classNo={classNo} />
          </div>
        </div>
        <StaffClassTotal classNo={classNo} lang={store.lang} />
      </div>
    );
  }
}

export default StaffClassByOption;
