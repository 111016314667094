import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ChooseClassDropdown from "./ChooseClassDropdown";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { debounce } from "throttle-debounce";
//var debounce = require("debounce");

@inject("store")
@observer
class StaffClassCommon extends Component {
  constructor(props) {
    super(props);

    this.debouncedUpdate = debounce(1200, this.debouncedUpdate);
  }

  debouncedUpdate() {
    this.props.store.getQuote();
  }

  render() {
    const { store, removeClass, index } = this.props;
    const { classNo } = this.props;

    return (
      <Fragment>
        <div className="rounded-top head_sml col-12 pl-0 pr-0 d-flex align-items-center justify-content-center">
          <img
            src="img/close_white.png"
            alt="className_white"
            style={{ display: "none" }}
            onClick={event => {
              event.preventDefault();
              this.props.store.setClassSel(this.props.classNo, "off");
            }}
          />
          <h2 className="text-uppercase">
            {i18n.t("Staff Class " + (Number(this.props.index) + 1))}
          </h2>
          {index >= 1 && (
            <img
              src="img/close_white.png"
              alt="class_white"
              style={{ display: "block" }}
              onClick={event => {
                removeClass(index);
              }}
            />
          )}
        </div>

        <div className="small_block_d1 h_eight d-flex justify-content-between">
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Classification of Insured</Trans>
            </p>
          </div>
          <div style={{ display: "none" }}>{store.activeQuoteMethod}</div>
          <ChooseClassDropdown value={i18n.t("Choose Class")} classNo={classNo} />
        </div>

        <div className="grey_space d-flex align-items-center justify-content-start">
          <div className="col-12">
            <p className="mb-0 font-weight-bold">
              <Trans>Number of Insured Members</Trans>
            </p>
          </div>
        </div>

        <div className="small_block_d1 hsix d-flex justify-content-between count_inputs">
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>No. of Employees</Trans>
              <button
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18n.t("The number of staff that will be insured by the plan.")}
              >
                <img src="img/info.png" alt="info" />
              </button>
            </p>
          </div>

          <div className="col-6 rf_small d-flex align-items-center justify-content-center">
            <div className="wrap_of_count w-100 position-relative check__guys">
              <a
                className="position-absolute min_ d-flex align-items-center justify-content-center"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_employeeNo_",
                    this.props.classNo,
                    parseInt(store["class_employeeNo_" + classNo]) - 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/minus_act.png" alt="" />
              </a>
              <input
                maxLength="2"
                placeholder="0"
                name="class_employeeNo_0 "
                data-quest="Staff Class 1"
                className="main_value_input w-100 rounded text-center font-weight-bold "
                type=""
                value={store["class_employeeNo_" + classNo]}
                onChange={event => {
                  this.setState({ employeeNo: event.target.value });
                  store.updateHeadCount("class_employeeNo_", classNo, event.target.value);
                  this.debouncedUpdate();
                }}
                style={
                  store["displayError_class_" + classNo] &&
                  parseInt(store["class_employeeNo_" + classNo]) < 1
                    ? { border: "1px solid red" }
                    : { color: "initial" }
                }
              />
              <a
                className="position-absolute add_ d-flex align-items-center justify-content-center"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_employeeNo_",
                    this.props.classNo,
                    parseInt(store["class_employeeNo_" + classNo]) + 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/plus.png" alt="plus" />
              </a>
            </div>
          </div>
        </div>
        <div className="small_block_d1 hsix d-flex justify-content-between count_inputs">
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>No. of Spouses</Trans>
              <button
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18n.t(
                  "The number of spouses  of staff that will be insured by the plan."
                )}
              >
                <img src="img/info.png" alt="info" />
              </button>
            </p>
          </div>
          <div className="col-6 rf_small d-flex align-items-center justify-content-center check__guys">
            <div className="wrap_of_count w-100 position-relative">
              <a
                className="position-absolute min_ d-flex align-items-center justify-content-start"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_spouseNo_",
                    classNo,
                    parseInt(store["class_spouseNo_" + classNo]) - 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/minus_act.png" alt="" />
              </a>
              <input
                maxLength="2"
                placeholder="0"
                name="class_spouseNo_0"
                className="w-100 rounded text-center font-weight-bold one_of_not_main_values"
                type=""
                value={store["class_spouseNo_" + classNo]}
                onChange={event => {
                  store.updateHeadCount("class_spouseNo_", classNo, event.target.value);
                  this.debouncedUpdate();
                }}
              />
              <a
                className="position-absolute add_ d-flex align-items-center justify-content-start"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_spouseNo_",
                    classNo,
                    parseInt(store["class_spouseNo_" + classNo]) + 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/plus.png" alt="plus" />
              </a>
            </div>
          </div>
        </div>

        <div className="small_block_d1 hsix d-flex justify-content-between h_eight count_inputs">
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>No. of Children</Trans>
              <button
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={i18n.t("No-of-children-helper-text")}
              >
                <img src="img/info.png" alt="info" />
              </button>
            </p>
          </div>
          <div className="col-6 rf_small d-flex align-items-center justify-content-center check__guys">
            <div className="wrap_of_count w-100 position-relative">
              <a
                className="position-absolute min_ d-flex align-items-center justify-content-start"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_childrenNo_",
                    classNo,
                    parseInt(store["class_childrenNo_" + classNo]) - 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/minus_act.png" alt="" />
              </a>
              <input
                maxLength="2"
                placeholder="0"
                name="class_childrenNo_0"
                className="w-100 rounded text-center font-weight-bold one_of_not_main_values"
                type=""
                value={store["class_childrenNo_" + classNo]}
                onChange={event => {
                  store.updateHeadCount("class_childrenNo_", classNo, event.target.value);
                  this.debouncedUpdate();
                }}
              />
              <a
                className="position-absolute add_ d-flex align-items-center justify-content-start"
                href="#"
                onClick={event => {
                  event.preventDefault();
                  store.updateHeadCount(
                    "class_childrenNo_",
                    classNo,
                    parseInt(store["class_childrenNo_" + classNo]) + 1
                  );
                  this.debouncedUpdate();
                }}
              >
                <img src="img/plus.png" alt="plus" />
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StaffClassCommon;
