import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import HospitalDropdown from "./HospitalDropdown";
import OutpatientDropdown from "./OutpatientDropdown";
import StaffClassCommon from "../StaffClassCommon";
import StaffClassTotal from "../StaffClassTotal";
import { Trans } from "react-i18next";

import { initTooltip } from "../../../../util";

@inject("store")
@observer
class StaffClassByBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitalOnly: null,
      hospitalOnlyLabel: "Select Premium",
      employeeNo: 0,
      spouseNo: 0,
      childrenNo: 0
    };
  }
  componentDidMount() {
    initTooltip();
  }
  render() {
    const { classNo } = this.props;

    return (
      <div className="elem_design_small d-flex flex-column align-items-center justify-content-center whole_col">
        <StaffClassCommon
          classNo={classNo}
          index={this.props.index}
          removeClass={this.props.removeClass}
        />
        <div className="grey_space d-flex align-items-center justify-content-start switching__">
          <div className="col-12">
            <p className="mb-0 font-weight-bold">
              <Trans>Choose Premium Budget</Trans>
              <button
                className="ml-1"
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="What is your budget for each insured's premium per year?"
              >
                <img src={"img/" + "info.png"} alt="info" />
              </button>
            </p>
          </div>
        </div>

        <div
          data-error="Staff Class 1"
          className="small_block_d1   hsix d-flex justify-content-between  switching__  d_hosp_block"
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
        >
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Hospitalization Only</Trans>
            </p>
          </div>
          <HospitalDropdown classNo={classNo} />
        </div>

        <div className="small_block_d1   hsix d-flex justify-content-between  switching__ d_hosp_block">
          <div className="col-6 lf_small d-flex align-items-center justify-content-end">
            <p className="mb-0 text-right d-flex align-items-center">
              <Trans>Hospitalization & Out-Patient</Trans>
            </p>
          </div>
          <OutpatientDropdown classNo={classNo} />
        </div>
        <StaffClassTotal classNo={classNo} />
      </div>
    );
  }
}

export default StaffClassByBudget;
