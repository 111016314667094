import { configure } from "mobx";
import SessionStore from "./SessionStore";

configure({ enforceActions: "observed" });
class RootStore {
  constructor() {
    this.store = new SessionStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
