import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class AboutEasyCareModal extends Component {

  render() {
    
    const { store } = this.props;
    return (
      <div
        className="modal fade"
        id="moreInfoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ml-auto mr-auto" role="document">
          <div className="modal-content position-relative">
            <button
              type="button"
              className="close close_di"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={"img/" + "close.png"} alt="close" />
            </button>

            <div className="modal-body pl-lg-4 pr-lg-4 pl-md-4 pr-md-4 pl-sm-4 pr-sm-4   pl-3 pr-3">
              <div className="container more_info_container d-flex flex-column col-12 p-0">
                <div className="head_dmore">
                  <h2 className="mb-3 pr-lg-5 pr-md-5 pr-sm-4 pr-4 font-weight-bold ">
                    <Trans>easycare about popup title</Trans>
                  </h2>
                </div>
                <ul className="pl-lg-3 pl-md-3 pl-sm-2 pl-0">
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>Medical, Dental, Life and Travel package plan</Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>Various hospital plans with optional benefits</Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>Free choice of doctors and dentists</Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>Outpatient network credit card facility provided</Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>Full reimbursement or 80% reimbursed for cost control</Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <img
                          className="mr-lg-3 mr-md-3 mr-sm-2 mr-2"
                          src={"img/" + "Tick.png"}
                          alt="Tick"
                        />
                      </span>
                      <Trans>and more</Trans> …
                    </p>
                  </li>
                </ul>
                <div className="two_buttons_more d-flex flex-lg-row flex-md-row flex-sm-row flex-column pl-lg-3 pl-md-3 pl-sm-2 pl-0  justify-content-between align-items-center">
                  <a
                    href={store.brochureUrl}
                    target="_blank"
                    className="dwnlbutton rounded d-flex mb-lg-0 mb-md-0 mb-sm-0 mb-3 align-items-center justify-content-center position-relative"
                  >
                    <img
                      className="mr-3"
                      src={"img/" + "download.png"}
                      alt="download"
                      name="download_brochure"
                    />
                    <Trans>Download Brochure</Trans>
                  </a>
                 
                  <a
                  href = {store.lang ==='en'?"https://www.libertyinsurance.com.hk/easycare": "https://www.libertyinsurance.com.hk/zh/easycare"}
                  //  href="/easycare"
                    
                    target="_blank"
                    className="exbutton rounded d-flex align-items-center justify-content-center position-relative"
                  >
                    <img
                      className="mr-3"
                      src={"img/" + "extlink_white.png"}
                      alt="extlink_white"
                      name="visit_website"
                    />
                    <Trans>Visit Website</Trans>
                  </a>
 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutEasyCareModal;
