import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18next from "i18next";
@inject("store")
@observer
class AreaDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { isLocationMenuOpen: false };
    this.handleClick = this.handleClick.bind(this);
  }
  setValue(value) {
    this.props.store.setTravelLocation(this.props.classNo, value);
  }

  handleClick(e) {
    if (this.refs.menu.contains(e.target)) {
      return;
    }
    this.setState({ isLocationMenuOpen: false });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const { store, classNo } = this.props;
    const isActive = value => {
      if (store["class_bta_plan_area_" + classNo] == value) return "active_ul_type";
      else return "";
    };
    return (
      <div
        className="drop_mod position-relative d-flex align-items-center justify-content-center"
        ref={"menu"}
      >
        <input
          type="text"
          name="class_bta_plan_area_0"
          className="rounded font-weight-bold input_open_modal"
          value={
            store["class_bta_plan_area_" + classNo]
              ? i18next.t(store["class_bta_plan_area_" + classNo])
              : i18next.t("Select...")
          }
          readOnly
          onClick={event => {
            this.setState({ isLocationMenuOpen: true });
          }}
        />
        {this.state.isLocationMenuOpen && (
          <div
            style={{
              display: "flex"
            }}
            className="drop_mod_des flex-column position-absolute rounded"
          >
            <p className="pl-3 pr-3 mt-0 mb-2 font-weight-bold">
              <Trans>Select Location</Trans>
            </p>
            <ul>
              <li className={" pl-3 pr-3 pt-2 pb-2 " + isActive("Asia")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("Asia");
                    this.setState({ isLocationMenuOpen: false });
                  }}
                >
                  <Trans>Asia</Trans>
                </a>
              </li>
              <li className={"pl-3 pr-3 pt-2 pb-2 " + isActive("Worldwide")}>
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    this.setValue("Worldwide");
                    this.setState({ isLocationMenuOpen: false });
                  }}
                >
                  <Trans>Worldwide</Trans>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default AreaDropdown;
