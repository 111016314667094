import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";

const { initMobileModal, planToOption } = require("../../util");

@inject("store")
@observer
class OutpatientBenefits extends Component {
  componentDidMount() {
    initMobileModal();
  }

  setOutpatientPlanOption(opt) {
    const { store } = this.props;
    store.setOutpatientPlanOption(store.openModalClassNo, opt);
  }
  render() {
    const { store } = this.props;
    let valueSelected = store["class_outPPlan_attr1_" + store.openModalClassNo];
    const isActive = option => {
      if (option === valueSelected) return " active_hospit ";
      return "";
    };
    const bottomNote = () => {
      return (
        <div className="col-lg-12 after_text pl-0 pr-0">
          <div className="after_txt1 pl-3 pr-2 pt-2 pb-2">
            <p className="mb-0">
              <Trans>Items 1-4 shall be subject to overall maximum 30 visits per policy year</Trans>
              . <br />(<Trans>for both non-panel network doctors and panel network doctors</Trans>)
            </p>
          </div>
          <div className="after_txt2 pl-3 pr-2 pt-2 pb-2">
            <p className="mb-0" />
            <span>
              <img src="img/caret.png" alt="caret" />
            </span>
            {i18next.t("outpatient-popup-note")}.
          </div>
          <div className="after_txt_note pt-lg-0 pb-lg-0 pt-md-2 pb-md-2  pl-3 pr-3  pb-2">
            <p className="mb-0">
              <span>
                <img src="img/caret.png" alt="caret" />
              </span>
              <span>
                <span>
                  <Trans>Note 1</Trans>:
                </span>
                <Trans>Outpatient-noteOne-line-1</Trans>
                <br />
                <Trans>Outpatient-noteOne-line-2</Trans> <br />
                <Trans>Outpatient-noteOne-line-3</Trans>
                <br />
                <Trans>Outpatient-noteOne-line-4</Trans>
              </span>
            </p>
          </div>
          <div className="after_txt_note pt-lg-0 pb-lg-0 pt-md-2 pb-md-2  pl-3 pr-3  pb-2">
            <p className="mb-0">
              <span>
                <img src="img/caret.png" alt="caret" />
              </span>
              <span>
                <span>
                  <Trans>Note 2</Trans>:
                </span>
                <Trans>Outpatient-noteTwo-line-1</Trans>
                <br />
                <Trans>Outpatient-noteTwo-line-2</Trans> <br />
                <Trans>Outpatient-noteTwo-line-3</Trans>
              </span>
            </p>
          </div>
        </div>
      );
    };
    return (
      <Fragment>
        <div className="small_hospit_wrap d-flex flex-column align-items-center justify-content-center m-0 p-2 col-12">
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN1")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "PLAN1");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 1</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN1") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>General Doctor's Consultation (Maximum 30 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">350</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    {i18next.t("outpatient-popup-physiotherapis")}
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">670</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Specialist Consultation (Maximum 10 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">670</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Chinese Herbalist / Bonesetter / Acupuncture Treatment (Maximum 10 visits per policy year)</Trans>*
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">240</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Diagnostic X-ray / Laboratory Test</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>max limit</Trans> <br /> <Trans>policy year</Trans>
                  </p>
                  <span className="mb-0 ml-2">3,500</span>
                </div>
              </div>

              {bottomNote()}
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN2")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "PLAN2");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 2</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN2") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>General Doctor's Consultation (Maximum 30 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">280</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    {i18next.t("outpatient-popup-physiotherapis")}
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">520</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Specialist Consultation (Maximum 10 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">520</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Chinese Herbalist / Bonesetter / Acupuncture Treatment (Maximum 10 visits per policy year)</Trans>*
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">200</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Diagnostic X-ray / Laboratory Test</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>max limit</Trans> <br /> <Trans>policy year</Trans>
                  </p>
                  <span className="mb-0 ml-2">2,600</span>
                </div>
              </div>

              {bottomNote()}
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3 " + isActive("PLAN3")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "PLAN3");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 3</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN3") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>General Doctor's Consultation (Maximum 30 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">210</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    {i18next.t("outpatient-popup-physiotherapis")}
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">400</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Specialist Consultation (Maximum 10 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">400</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Chinese Herbalist / Bonesetter / Acupuncture Treatment (Maximum 10 visits per policy year)</Trans>*
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">180</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Diagnostic X-ray / Laboratory Test</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>max limit</Trans> <br /> <Trans>policy year</Trans>
                  </p>
                  <span className="mb-0 ml-2">1,750</span>
                </div>
              </div>

              {bottomNote()}
            </div>
          </div>

          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("PLAN4")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "PLAN4");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Option 4</Trans>
              </p>
              <img src={"img/" + "triangle_arrow.png"} alt="smallds" />
            </div>
            <div
              className="content_dhosp"
              style={isActive("PLAN4") ? { display: "block" } : { display: "none" }}
            >
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">1</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>General Doctor's Consultation (Maximum 30 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">170</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">2</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    {i18next.t("outpatient-popup-physiotherapis")}
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">320</span>
                </div>
              </div>

              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">3</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Specialist Consultation (Maximum 10 visits per policy year)</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">320</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">4</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Chinese Herbalist / Bonesetter / Acupuncture Treatment (Maximum 10 visits per policy year)</Trans>*
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>limit per visit per day</Trans>
                  </p>
                  <span className="mb-0 ml-2">150</span>
                </div>
              </div>
              <div className="dhosp_el d-flex justify-content-between align-items-center p-3">
                <div className="col-sm-8  lhos p-0 d-flex align-items-center ">
                  <span className="col-sm-1 p-0">5</span>
                  <p className="col-sm-11 p-0 font-weight-bold m-0">
                    <Trans>Diagnostic X-ray / Laboratory Test</Trans>
                  </p>
                </div>
                <div className="col-sm-4  rhos p-0 d-flex align-items-center justify-content-end ">
                  <p className="mb-0">
                    <Trans>max limit</Trans> <br /> <Trans>policy year</Trans>
                  </p>
                  <span className="mb-0 ml-2">1,050</span>
                </div>
              </div>

              {bottomNote()}
            </div>
          </div>
          <div
            className={"elem_small_hospit w-100 mb-3" + isActive("Not Covered")}
            onClick={event => {
              store.setOutpatientPlanOption(store.openModalClassNo, "Not Covered");
            }}
          >
            <div className="head_dhosp d-flex align-items-center justify-content-start position-relative pl-3 pr-3">
              <div className="blcircle d-flex align-items-center justify-content-center mr-2">
                <img src={"img/" + "smallds.png"} alt="smallds" />
              </div>
              <p className="mb-0 text-uppercase font-weight-bold">
                <Trans>Not Covered</Trans>
              </p>
            </div>
          </div>
        </div>

        <div className="bottom_small_wd pl-3 pr-3 pt-3 pb-1">
          <div className="small_wd1 d-flex align-items-center mb-2 ">
            <div className="col-lg-6 sma-l p-0">
              <p className="m-0 font-weight-bold">
                *<Trans>All prices in HKD</Trans>
              </p>
            </div>
            <div className="col-lg-6 sma-r p-0">
              <h6 className="m-0 text-right selected_text ">
                <Trans>Selected</Trans>:{" "}
                <span className="font-weight-bold text-uppercase">
                  {i18next.t(planToOption(store["class_outPPlan_attr1_" + store.openModalClassNo]))}
                </span>
              </h6>
            </div>
          </div>
          <div className="small_wd2 d-flex align-items-center ">
            <div className="col-lg-6 sma-l p-0">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_outPPlan_attr1_" + store.openModalClassNo);
                  store.toggleoutpatientBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
            </div>
            <div className="col-lg-6 sma-r p-0 d-flex justify-content-end">
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleoutpatientBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OutpatientBenefits;
