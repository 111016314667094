import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ChooseClassDropdown from "./ChooseClassDropdown";
import { Trans } from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";
import { debounce } from "throttle-debounce";

@inject("store")
@observer
class StaffClassCommon extends Component {
  constructor(props) {
    super(props);
    this.debouncedUpdate = debounce(1200, this.debouncedUpdate);
  }
  debouncedUpdate() {
    this.props.store.getQuote();
  }
  render() {
    const { store, classNo } = this.props;

    return (
      <Fragment>
        <div className="drow_1 pl-2 pr-2">
          <a
            href="#"
            name="add_class_btn"
            className="h-100  d-flex align-items-center font-weight-bold justify-content-center"
            onClick={event => {
              event.preventDefault();
              store.setClassSel(classNo, "on");
            }}
          >
            <img className="mr-2" src={"img/" + "img_add.png"} alt="add" />
            <Trans>Add Class</Trans>
          </a>
          <div className="staff_  h-100 d-flex align-items-center justify-content-center position-relative">
            <h3 className="text-uppercase m-0 text-center font-weight-bold pr-lg-3 pr-md-0 pt-lg-0 pt-md-1">
              {i18next.t("Staff Class " + (Number(classNo) + 1))}
            </h3>
            <img
              className="position-absolute"
              src={"img/" + "close_white.png"}
              alt="close_white"
              onClick={event => {
                event.preventDefault();
                store.setClassSel(classNo, "off");
              }}
            />
            >
          </div>
        </div>
        <div style={{ display: "none" }}>{store.activeQuoteMethod}</div>

        <ChooseClassDropdown value={i18n.t("Choose Class")} classNo={classNo} />

        <div className="grey_space d-flex align-items-center justify-content-start" />
        <div className="employees_block d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-center	d3_b align-items-center count_inputs">
          <div className="wrap_of_count w-100 position-relative check__guys">
            <a
              className="position-absolute min_ d-flex align-items-center justify-content-center"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_employeeNo_",
                  classNo,
                  parseInt(store["class_employeeNo_" + classNo]) - 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "minus_act.png"} alt="" />
            </a>
            <input
              maxLength="2"
              placeholder="0"
              name="class_employeeNo_0 "
              data-quest="Staff Class 1"
              className="main_value_input w-100 rounded text-center font-weight-bold "
              type=""
              value={store["class_employeeNo_" + classNo]}
              onChange={event => {
                store.updateHeadCount("class_employeeNo_", classNo, event.target.value);
                this.debouncedUpdate();
              }}
              style={
                store["displayError_class_" + classNo] &&
                parseInt(store["class_employeeNo_" + classNo]) < 1
                  ? { border: "1px solid red" }
                  : { color: "initial" }
              }
            />
            <a
              className="position-absolute add_ d-flex align-items-center justify-content-center"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_employeeNo_",
                  classNo,
                  parseInt(store["class_employeeNo_" + classNo]) + 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "plus.png"} alt="plus" />
            </a>
          </div>
        </div>
        <div className="spouse_block count_inputs d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d3_b align-items-center">
          <div className="wrap_of_count w-100 position-relative">
            <a
              className="position-absolute min_ d-flex align-items-center justify-content-start"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_spouseNo_",
                  classNo,
                  parseInt(store["class_spouseNo_" + classNo]) - 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "minus_act.png"} alt="" />
            </a>
            <input
              maxLength="2"
              placeholder="0"
              name="class_spouseNo_0"
              className="w-100 rounded text-center font-weight-bold one_of_not_main_values"
              type=""
              value={store["class_spouseNo_" + classNo]}
              onChange={event => {
                store.updateHeadCount("class_spouseNo_", classNo, event.target.value);
                this.debouncedUpdate();
              }}
            />
            <a
              className="position-absolute add_ d-flex align-items-center justify-content-start"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_spouseNo_",
                  classNo,
                  parseInt(store["class_spouseNo_" + classNo]) + 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "plus.png"} alt="plus" />
            </a>
          </div>
        </div>

        <div className=" count_inputs children_block d-flex pl-lg-4 pr-lg-4 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d3_b align-items-center">
          <div className="wrap_of_count w-100 position-relative">
            <a
              className="position-absolute min_ d-flex align-items-center justify-content-start"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_childrenNo_",
                  classNo,
                  parseInt(store["class_childrenNo_" + classNo]) - 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "minus_act.png"} alt="" />
            </a>
            <input
              maxLength="2"
              placeholder="0"
              name="class_childrenNo_0"
              className="w-100 rounded text-center font-weight-bold one_of_not_main_values"
              type=""
              value={store["class_childrenNo_" + classNo]}
              onChange={event => {
                store.updateHeadCount("class_childrenNo_", classNo, event.target.value);
                this.debouncedUpdate();
              }}
            />
            <a
              className="position-absolute add_ d-flex align-items-center justify-content-start"
              href="#"
              onClick={event => {
                event.preventDefault();
                store.updateHeadCount(
                  "class_childrenNo_",
                  classNo,
                  parseInt(store["class_childrenNo_" + classNo]) + 1
                );
                this.debouncedUpdate();
              }}
            >
              <img src={"img/" + "plus.png"} alt="plus" />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StaffClassCommon;
