import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
@inject("store")
@observer
class Footer extends Component {
  render() {
    const { store } = this.props;
    const isDisabled = () => {
      return store.isInputComplete ? "" : "disabled__foot";
    };
    return (
      <div className="footer_bottom">
        <div className="container d-flex align-items-center justify-content-end">
          <div className={"outer_footer pt-3 pb-3 " + isDisabled()}>
            <a
              href="#"
              className="d-flex justify-content-center align-items-center rounded font-weight-bold"
              name="print"
              onClick={event => {
                event.preventDefault();
                store.toggleExportQuoteModal();
              }}
            >
              <Trans>Get Quote</Trans>
            </a>
          </div>
          <a href="#" id="up_button">
            <img src="img/circle.png" alt="circle" />
          </a> 
        </div>
      </div>
    );
  }
}

export default Footer;
