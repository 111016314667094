import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ErrorMsg from "../InlineErrorMsg";
import { Trans } from "react-i18next";

@inject("store")
@observer
class QuestionFour extends Component {
  render() {
    const { store } = this.props;
    const errorMessage = () => {
      if (!store.version_ageGroup) {
        return "Average age required.";
      }
    };
    return (
      <Fragment>
        <div className="other_new_elem d-flex mb-4 justify-content-sm-start justify-content-lg-start justify-content-md-start justify-content-center">
          <span className="d-flex col-lg-1 dspan mr-n5 pl-0 pr-0 font-weight-bold col-md-1 col-sm-1 col-2">
            4
          </span>
          <div className="cont_new_elem ml-lg-0 col-lg-11 col-md-10 ml-md-4 col-sm-10 col-11 ml-sm-4 ml-3">
            <p className="mb-2">
              <Trans>What is the average age of your employees?</Trans>
            </p>
            <div className="date_input">
              <p className="mb-3">
                <Trans>We require each insured to have a </Trans>
                <span className="font-weight-bold">
                  <Trans>minimum age of 18 & maximum age of 64.</Trans>
                </span>
              </p>
              <div
                className="both_checkbox_elems dis__button"
                id="age_inp"
                data-error="Average age required."
                data-quest="Please choose your average year."
              >
                <p>
                  <input
                    type="radio"
                    id="test5"
                    name="version_ageGroup"
                    className="track_class"
                    checked={store.version_ageGroup === "18-40"}
                    onClick={event => {
                      store.setAgeGroup("18-40");
                      store.toggleAgeUpdate();
                    }}
                  />
                  <label htmlFor="test5">18 - 40</label>
                </p>

                <p>
                  <input
                    type="radio"
                    id="test6"
                    name="version_ageGroup"
                    checked={store.version_ageGroup === "41-65"}
                    onClick={event => {
                      store.setAgeGroup("41-65");
                      store.toggleAgeUpdate();
                    }}
                  />
                  <label htmlFor="test6">41 - 64</label>
                </p>
                <ErrorMsg msg={errorMessage()} classNo="H" />
              </div>
            </div>
          </div>
        </div>

        <div className="average_elem col-12 d-flex flex-row pl-0 pr-0">
          <div className="d-flex d-flex col-lg-1 dspan mr-n5 pl-0 pr-0 font-weight-bold col-md-1 col-sm-1 col-2" />
          <div className="d-flex cont_new_elem ml-lg-0 col-lg-11 col-md-10 ml-md-4 col-sm-10 col-11 ml-sm-4 ml-3 flex-column av_calc">
            <p className="mb-2">
              <Trans>need-help?</Trans>
            </p>
            <a
              className="d-flex align-items-center justify-content-center"
              href="#"
              name="age"
              onClick={event => {
                event.preventDefault();
                store.toggleCalculatorModal();
              }}
            >
              <img src={"img/" + "extlink_black.png"} className="mr-1" alt="extlink_black" />
              <Trans>Average Age Calculator</Trans>
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default QuestionFour;
