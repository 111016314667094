import React, { Component } from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class AgeItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDate: "",
      age: ""
    };
    this.clearRow = this.clearRow.bind(this);
    this.dateRef = React.createRef();
  }

  componentDidMount() {
    $(".elem_calc>input:nth-child(2)").datetimepicker({
      format: "MM/DD/YYYY",
      icons: {
        next: "fas fa-angle-right",
        previous: "fas fa-angle-left"
      }
    });
  }
  clearRow() {
    this.dateRef.current.value = "";
  }

  render() {
    const { index, store, key } = this.props;
    const ageItem = store.calculatingAges[index];

    const dateStyle =
      this.state.birthDate && (this.state.age < 18 || this.state.age > 64)
        ? { border: "1px solid red" }
        : { border: "1px solid rgb(213, 214, 216)" };
    const ageStyle =
      this.state.birthDate && (this.state.age < 18 || this.state.age > 64)
        ? { border: "1px solid red" }
        : { border: "1px solid rgb(213, 214, 216)" };

    return (
      <div className="elem_calc d-flex align-items-center mb-3">
        <p className="pl-0 col-lg-1 col-md-1 col-sm-1 col-1 pr-0 mb-0 font-weight-bold">
          {index + 1}
        </p>
        <input
          className="col-lg-5 col-md-5 col-sm-5 col-5 rounded  pr-1 date_age"
          type="text"
          name="birthday_0"
          placeholder="MM/DD/YYYY"
          maxLength="10"
          defaultValue={ageItem.birthDate}
          ref={this.dateRef}
          onBlur={event => {
            let birth = event.target.value;
            if (birth) {
              let dates = birth.split("/");
              let birthday = moment(`${dates[2]}-${dates[0]}-${dates[1]}`);
              let now = moment();
              let diff = now.diff(birthday, "years");
              this.setState({ birthDate: event.target.value, age: diff });
              store.updateAgeItem({ birthDate: event.target.value, age: diff }, index);
            } else {
              store.updateAgeItem({ age: 0 }, index);
            }
          }}
          style={dateStyle}
        />
        <span className="col-lg-1 col-md-1 col-sm-1 col-1 pl-0 pr-0 text-center font-weight-bold">
          or
        </span>
        <input
          className="or_age age_numb col-lg-2 col-md-2 col-sm-2 col-2 rounded text-center pl-1 pr-1"
          type="text"
          name="age_0"
          placeholder="0"
          maxLength="2"
          value={ageItem.age}
          onChange={event => {
            this.setState({ birthDate: "", age: event.target.value });
            store.updateAgeItem({ age: event.target.value }, index);
            this.refs.birthDate.value = "";
          }}
          style={ageStyle}
        />
        <a
          href="#"
          className="remove_bin col-lg-2 col-2"
          onClick={event => {
            event.preventDefault();
            this.clearRow();
            store.removeAgeItem(index);
          }}
        >
          <img src={"img/" + "remove.png"} alt="remove" />
        </a>
      </div>
    );
  }
}

export default AgeItem;
