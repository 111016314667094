import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const initI18n = (en, zh) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translations: en
      },
      zh: {
        translations: zh
      }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });
};
const setLang = lang => {
  i18n.changeLanguage(lang);
};

export default { initI18n, setLang };
