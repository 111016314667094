import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import HospitalDropdown from "./HospitalDropdown";
import OutpatientDropdown from "./OutpatientDropdown";
import { initTooltip } from "../../../../util";

@inject("store")
@observer
class StaffClassByBudget extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    initTooltip();
  }

  render() {
    const { classNo, store } = this.props;
    return (
      <Fragment>
        <div
          ref={node => {
            if (node) {
              node.style.setProperty("display", "flex", "important");
            }
          }}
          className="grey_space switching__"
        />
        <HospitalDropdown classNo={classNo} lang={store.lang} />
        <OutpatientDropdown classNo={classNo} lang={store.lang} />
      </Fragment>
    );
  }
}

export default StaffClassByBudget;
