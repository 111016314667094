import React, { Component, Fragment } from "react";
import Header from "./components/Header";
import Intro from "./components/Intro";
import BasicInfo from "./components/BasicInfo";
import GetYourQuote from "./components/GetYourQuote";
import DesignYourPlan from "./components/DesignYourPlan";
import QuoteResult from "./components/QuoteResult";
import Footer from "./components/Footer";
import Modals from "./components/Modals";
import ErrorsBlock from "./components/ErrorsBlock";
import { inject, observer } from "mobx-react";
import 'url-search-params-polyfill';


import { initMobileModal, initTooltip } from "./components/util";
import ReactGA from 'react-ga';


@inject("store")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.changeLang = this.changeLang.bind(this);
  }

  handleResize = () => {
    this.props.store.setScreenSize(window.innerWidth);
  };

  componentDidMount() {
   
   
    const windowUrl=window.location.search;
    const params=new URLSearchParams(windowUrl);
    const brokerCode=params.get('brokerCode');
    
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.props.store.initData().then(res=>{    
      console.log("https://www.googletagmanager.com/gtag/js?id="+this.props.store.GA);
      console.log(this);
      this.getGAScript("https://www.googletagmanager.com/gtag/js?id="+this.props.store.GA, this.initializeGA(this.props.store.GA));
    });

    this.props.store.getBrokerLogo(brokerCode);

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  componentDidUpdate() {
    $("#up_button").on("click", function(e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, 600);
    });

    $(".arrow_close").on("click", function(e) {
      e.preventDefault();
      if ($(this).hasClass("active_triangle")) {
        $(this).removeClass("active_triangle");
        $(this).css("transform", "rotate(-90deg)");
        if (
          $(this)
            .closest(".center_info_input")
            .find(".other_elems ").length
        ) {
          $(this)
            .closest(".center_info_input")
            .find(".other_elems ")
            .slideDown();
        }
        if (
          $(this)
            .closest(".center_info_input")
            .find(".two_ways_quote").length
        ) {
          $(this)
            .closest(".center_info_input")
            .find(".two_ways_quote")
            .slideDown();
        }
      } else {
        $(this).addClass("active_triangle");
        $(this).css("transform", "rotate(0deg)");
        if (
          $(this)
            .closest(".center_info_input")
            .find(".other_elems").length
        ) {
          $(this)
            .closest(".center_info_input")
            .find(".other_elems")
            .slideUp();
        }
        if (
          $(this)
            .closest(".center_info_input")
            .find(".two_ways_quote").length
        ) {
          $(".two_ways_quote").slideUp(500, function() {
            $(this).attr("style", "display:none!important");
          });
        }
      }
    });

    initMobileModal();
    initTooltip();
    
  }

  initializeGA = (ga) => {
    $.getJSON('conf.json', function(emp) { 
      console.log(emp);
    }); 
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', ga);
  }

  getGAScript = (url, callback) => {
    jQuery.ajax({
      url: url,
      dataType: 'script',
      success: callback,
      async: true
    });
  }

  changeLang = async lang => {
    await this.props.store.setLang(lang);
    this.forceUpdate();
  };

  render() {
    const { store } = this.props;
    if (!this.props.store.sessionReady) {
      // return <div>loading...</div>;
      return <div id="loader"></div>
    } else {
      return (
        <Fragment>
          <Header setLang={this.changeLang} />
          <div className="main_block pt-4">
            {/* <a href="#" id="up_button">
              <img src={"img/" + "circle.png"} alt="circle" />
            </a> */}
            <div className="container">
              <div className="outer_content_block mt-2 position-relative">
                <ErrorsBlock lang={store.lang} />
                <Intro lang={store.lang} />
                <BasicInfo lang={store.lang} />
                <GetYourQuote lang={store.lang} />
                <DesignYourPlan lang={store.lang} />
              </div>
            </div>
          </div>
          <div className="after_result mt-4 ">
            <QuoteResult lang={store.lang} />
          </div>
          <Footer lang={store.lang} />
          <Modals lang={store.lang} />
        </Fragment>
      );
    }
  }
}
export default App;