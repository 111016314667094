import React, { Component } from "react";
import { Trans } from "react-i18next";
class WarnMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }

  render() {
    if (this.state.visible && this.props.msg) {
      return (
        <div className="warning_elem d-flex justify-content-start pr-4 pl-2 w-100 col-12">
          <img src={"img/" + "warning.png"} alt="warning" />
          <p>
            <Trans>{this.props.msg}</Trans>
          </p>
          <img
            src={"img/" + "yellowclose.png"}
            alt="yellowclose"
            onClick={event => {
              this.setState({ visible: false });
            }}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default WarnMsg;
