import React, { Component } from "react";
import AgeItem from "./AgeItem";
import { inject, observer } from "mobx-react";
import { Modal } from "reactstrap";

import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
var Chance = require("chance");
var chance = new Chance();

@inject("store")
@observer
class AgeCalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.reRender = this.reRender.bind(this);
  }

  reRender() {
    // this.forceUpdate();
  }
  clearDates() {}
  render() {
    const { store } = this.props;

    const renderAgeItems = () => {
      return store.calculatingAges.map((item, index) => {
        return <AgeItem ageItem={item} index={index} key={"" + store.ageCalSession + index} />;
      });
    };
    let element = store.calculatingAges.length >= 3 ? store.calculatingAges.length : 3;
    let minHeight = 54 * (element - 2);
    let innerStyle = { minHeight: minHeight + "px" };
    return (
      <div id="calculatorModal">
        <Modal
          isOpen={store.calculatorModalOpen}
          toggle={store.toggleCalculatorModal}
          className={"ml-auto mr-auto "}
          backdropClassName={"modal-backdrop"}
          modalClassName={""}
          contentClassName={"position-relative"}
          wrapClassName={"calculatorModal"}
          fade={true}
        >
          <button
            type="button"
            className="close close_di"
            data-dismiss="modal"
            aria-label="Close"
            onClick={event => {
              store.toggleCalculatorModal();
            }}
          >
            <img src={"img/" + "close.png"} alt="close" />
          </button>
          <div className="modal-body pl-0 pr-0 pb-0">
            <div className="calculator_block d-flex flex-column col-12 pl-0 pr-0">
              <div className="head_calc mb-3 pl-3 pr-3">
                <h2 className="font-weight-bold mb-0">
                  <img className="mr-2" src={"img/" + "calc.png"} alt="calc" />
                  <Trans>Age Calculator</Trans>
                </h2>
              </div>
              <p className="mb-3 pl-3 pr-3">{i18next.t("age-calculator-text")}</p>
              {store.ageOutOfLimit ? (
                <div className="after_date_inp justify-content-center d-flex col-12 position-absolute p-0">
                  <p>
                    <img src="img/warn.png" alt="warn" />
                    <span>
                      <Trans>The age of the employee should be within 18 and 64</Trans>
                    </span>
                  </p>
                </div>
              ) : (
                <div />
              )}

              <div
                className="pl-3 pr-3 inner_calculator d-flex flex-column col-12 pl-0 pr-0"
                style={innerStyle}
              >
                {renderAgeItems()}
              </div>
              <div className="pl-3 pr-3 after_inn d-flex col-11 offset-lg-1 offset-md-1 offset-sm-1 offset-1 pl-0 mb-3">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center rounded mb-2 ml-lg-n1 ml-md-n1 ml-sm-n1 ml-n1"
                  onClick={event => {
                    event.preventDefault();
                    store.addAgeItem();
                  }}
                >
                  <img src={"img/" + "plus_white.png"} name="add_employee" alt="plus_white" />
                  <Trans>Add Employee</Trans>
                </a>
              </div>
              <div className="pl-3 pr-3 clear_a d-flex col-11 offset-1">
                <a
                  href="#"
                  name="clear_all"
                  className="mb-1 clear_button ml-n1"
                  onClick={event => {
                    event.preventDefault();
                    this.clearDates();
                    store.clearCalculatingAges();
                    this.reRender();
                  }}
                >
                  <Trans>Clear All</Trans>
                </a>
              </div>
              <div className="b_part d-flex col-12 align-items-center justify-content-between rounded">
                <p className="offset-1 mb-0 ">
                  <Trans>Average age</Trans>:
                  <span name="average_age" className="average_age font-weight-bold">
                    {store.averageAge}
                  </span>
                </p>
                <a
                  href="#"
                  name="apply"
                  className="d-flex align-items-center justify-content-center rounded "
                  onClick={event => {
                    event.preventDefault();
                    store.setAverageAge();
                  }}
                >
                  <Trans>Apply</Trans>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AgeCalculatorModal;
