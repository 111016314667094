import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import { planToOption } from "../../util";
import i18next from "i18next";

@inject("store")
@observer
class Full extends Component {
  constructor(props) {
    super(props);
  }
  setHospitalPlanOption(opt) {
    const { store } = this.props;
    store.setHospitalOption(store.openModalClassNo, opt);
  }
  render() {
    const { store } = this.props;
    const currentSelection = store["class_hospitalPlan_" + store.openModalClassNo];
    const isActive = option => {
      if (option == currentSelection) return " active_wed_el";
      return "";
    };
    return (
      <Fragment>
        <div className="hospit_wrap col-12 p-0 d-flex align-items-start justify-content-between pl-3 pr-3 hospit_with_paddings">
          <div className="col-lg-7 p-0 pt-5 left_hospit">
            <h2 className="font-weight-bold mb-3">
              <Trans>COVERAGE</Trans>
            </h2>
            <div className="wrap_hospit_dchar">
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 1 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Room & Board Charges (Maximum 91 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per day</Trans>
                </h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 2 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Intensive Care Unit (Maximum 10 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>limit per day</Trans>
                </h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 3 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Hospital Special Services</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>per disability limit</Trans>
                </h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 4 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Surgical Fees</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0">
                  <Trans>per disability limit</Trans>
                </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Complex Operation</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Major Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Intermediate Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Minor Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 5 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Anaesthetist's Fee</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0"> <Trans>per disability limit</Trans> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Complex Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Major Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Intermediate Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Minor Operation</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> </h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> 6 </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Operating Theatre</Trans> </p>
                <h6 className=" pl-0 pr-0 mb-0"> <Trans>per disability limit</Trans> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Complex Operation</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Major Operation</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Intermediate Operation</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                  <Trans>Minor Operation</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">7</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>In-Hospital Doctor's Consultation (Maximum 91 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>limit per day</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">8</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>In-Hospital Specialist's Consultation (Referred & recommended by attending physician in writing)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>per disability limit</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">9</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Cashless Gastroscopy / Colonoscopy at designated Day Surgery Centre (Pre-admission assessment is required)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>per procedure limit</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">10</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Day Surgery for Gastroscopy / Colonoscopy</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>per procedure limit</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between extra_height_150">
                <span className="col-lg-1 pl-0 pr-0">11</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Pre & Post-Hospitalization Treatment (Including 1 pre-admission visit within 30 consecutive days before admission to hospital and all related post-hospitalization treatment within 90 consecutive days after discharge from hospital)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>per disability limit</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">12</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Special Nursing (Maximum 30 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>limit per day</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">13</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Daily Cash Benefit (Maximum 91 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>limit per day</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">14</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Daily Cash for coordinate Benefits (Maximum 91 days per disability)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>limit per day</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">15</span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"><Trans>Final Tribute Costs</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0"><Trans>per case limit</Trans></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between full_width_dchar">
                <span className="col-lg-1 pl-0 pr-0">16</span>
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Free Extended Major Medical Benefit (80% Reimbursement) Please Refer to the policy terms for coverage details.</Trans>
                </p>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold"><Trans>Deductible</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0" />
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Max. limit per disability</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between full_width_dchar">
                <span className="col-lg-1 pl-0 pr-0">17</span>
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Free Overseas Emergency Medical Evacuation  (Applicable to member age below 70)</Trans>
                </p>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0">18</span>
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Greater China Card (Deposit-free Admission)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" />
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between ">
                <span className="col-lg-1 pl-0 pr-0">19</span>
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>100% Top Up Overseas Benefits for Hospitalization Benefits due to Accident (Exclude China, Hong Kong and Macau)</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" ></h6>
              </div>
              <div className="dchar_elem d-flex align-items-center justify-content-between ">
                <span className="col-lg-1 pl-0 pr-0">20</span>
                <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
                <Trans>Psychiatric Treatment</Trans>
                </p>
                <h6 className=" pl-0 pr-0 mb-0" ><Trans>per policy year</Trans></h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6 right_hospit p-0 d-flex align-items-start justify-content-between ">
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN1")
              }
              onClick={event => {
                store.setHospitalOption(store.openModalClassNo, "PLAN1");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src="img/smallds.png" alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 1</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 2, 700 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 4, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 30, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 90, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 45, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 22, 500 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 11, 250 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>27,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>13,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>6,750</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,375</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>27,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>13,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>6,750</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,375</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,700</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>5,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Full Cover</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>28,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center extra_height_150">
                <p>4,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>800</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>90,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                {/* <p>90,000</p> */}
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>NIL</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>150,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>$2,000,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center hb_value_text " style={{width:'100%'}} >
                <p><Trans>Hospital Benefits increase to 200%</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center psychiatric_line ">
                <p>50,000</p>
              </div>

            </div>
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN2")
              }
              onClick={event => {
                store.setHospitalOption(store.openModalClassNo, "PLAN2");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src="img/smallds.png" alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 2</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 1, 760 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 2, 700 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 23, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 70, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 35, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 17, 500 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 8, 750 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>21,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>5,250</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,625</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>21,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>5,250</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,625</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,760</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>4,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Full Cover</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>23,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center extra_height_150">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>600</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>650</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>650</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>70,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                {/* <p>70,000</p> */}
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>NIL</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>100,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>$2,000,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center hb_value_text " style={{width:'100%'}} >
                <p><Trans>Hospital Benefits increase to 200%</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center psychiatric_line ">
                <p>40,000</p>
              </div>
            </div>
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN3")
              }
              onClick={event => {
                store.setHospitalOption(store.openModalClassNo, "PLAN3");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src="img/smallds.png" alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 3</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 1, 150 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 1, 750 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 15, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 50, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 25, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 12, 500 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 6, 250 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>15,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>7,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,750</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,875</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>15,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>7,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,750</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,875</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,150</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Full Cover</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>17,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center extra_height_150">
                <p>1,200</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>400</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>450</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>450</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>50,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                {/* <p>50,000</p> */}
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>NIL</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>50,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>$2,000,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center hb_value_text " style={{width:'100%'}} >
                <p><Trans>Hospital Benefits increase to 200%</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center psychiatric_line ">
                <p>30,000</p>
              </div>
            </div>
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("PLAN4")
              }
              onClick={event => {
                store.setHospitalOption(store.openModalClassNo, "PLAN4");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src="img/smallds.png" alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 4</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 730 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 940 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 11, 500 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 32, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 16, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 8, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> 4, 000 </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>9,600</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>4,800</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,400</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,200</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>9,600</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>4,800</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,400</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,200</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>730</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>2,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Full Cover</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center extra_height_150">
                <p>1,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>200</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>275</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>275</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>30,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                {/* <p>30,000</p> */}
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>NIL</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>30,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>$2,000,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center hb_value_text " style={{width:'100%'}} >
                <p><Trans>Hospital Benefits increase to 200%</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center psychiatric_line ">
                <p>20,000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_wd d-flex align-items-center justify-content-between">
          <div className="col-lg-6 col-md-5 left_wd d-flex align-items-center justify-content-start">
            <p className="m-0">
              *<Trans>All prices in HKD</Trans>
            </p>
          </div>
          <div className="col-lg-6 col-md-7 right_wd d-flex align-items-center justify-content-end">
            <div className="res_wnd mr-2">
              <p className="text-center mr-2 mb-0">
                <Trans>Selected</Trans>:
                <span> {i18next.t(planToOption(store["class_hospitalPlan_" + store.openModalClassNo]))} </span>
              </p>
            </div>
            <div className="rd_buttons d-flex align-items-center">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center mr-3"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_hospitalPlan_" + store.openModalClassNo);
                  store.toggleHospitalBenefitsModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleHospitalBenefitsModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Full;
