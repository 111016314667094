import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
@inject("store")
@observer
class GetYourQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuoteMethod: "opt1"
    };
  }

  render() {
    const isActiveMethod = opt => {
      if (this.props.store.activeQuoteMethod == opt) return "active_quote_way";
      return "";
    };

    return (
      <div className="center_info_input p-lg-4 p-md-3 p-sm-3 p-3 col-12 two_choosen pb-lg-3 pb-md-3 pb-sm-3 pb-3">
        <div className="inside_header col-12 position-relative p-0">
          <h2 className="font-weight-bold m-0">
            <Trans>Get Your Quote</Trans>
          </h2>
          <a href="#" className="position-absolute arrow_close">
            <img src={"img/" + "triangle_arrow.png"} alt="triangle_arrow" />
          </a>
        </div>
        <div className="two_ways_quote d-flex flex-column col-12 justify-content-center align-items-center pl-lg-5 pr-lg-5 pd-md-3 pl-md-3 pl-sm-3 pr-sm-3 pl-0 pr-0 mt-3">
          <p className="mb-lg-4 mb-md-4 mb-sm-32">
            <Trans>There are two ways to get your quote</Trans>
          </p>
          <div className="inner_quote_way d-flex justify-content-between col-lg-10 col-md-11 col-sm-12 pl-lg-5 pl-md-3  pr-lg-5 pr-md-3 flex-lg-row flex-md-row flex-sm-column flex-column justify-content-center align-items-center pb-2 pb-lg-3 pb-md-3 pb-sm-2 ">
            <div
              className={
                "elem_quote_way d-flex align-items-center justify-content-center flex-column col-lg-5 col-md-5 col-sm-9 col-12 mb-lg-0 mb-md-0 mb-sm-3 mb-3 pl-lg-3 pr-lg-3  pl-md-2 pr-md-2 pl-sm-4 pr-sm-4 " +
                isActiveMethod("opt1")
              }
              data-id="opt1"
              name="version_planBy_details"
              onClick={event => {
                event.preventDefault();
                this.props.store.setactiveQuoteMethod("opt1");
              }}
            >
              <img className="position-absolute" src={"img/" + "checklarge.png"} alt="checklarge" />
              <h2 className="font-weight-bold mb-2 text-center">
                <Trans>By Options</Trans>
              </h2>
              <p className="text-center mb-0">
                <Trans>Let us know what benefits and options you want</Trans>
              </p>
            </div>
            <div
              className={
                "elem_quote_way d-flex align-items-center justify-content-center flex-column col-lg-5  col-md-5 col-sm-9 col-12 mb-lg-0 mb-md-0 mb-sm-3 pl-lg-3 pr-lg-3  pl-md-2 pr-md-2 pl-sm-4 pr-sm-4 " +
                isActiveMethod("opt2")
              }
              data-id="opt2"
              name="version_planBy_budget"
              onClick={event => {
                event.preventDefault();
                this.props.store.setactiveQuoteMethod("opt2");
              }}
            >
              <img className="position-absolute" src={"img/" + "checklarge.png"} alt="checklarge" />
              <h2 className="font-weight-bold mb-2 text-center">
                <Trans>By Budget</Trans>
              </h2>
              <p className="text-center mb-0">
                <Trans>Let us know how much you want to spend</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GetYourQuote;
