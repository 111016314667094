import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import i18n from "i18next";

@inject("store")
@observer
class Opt2Headers extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const toDisplayBlock = opt => {
      if (opt == this.props.store.activeQuoteMethod) {
        return "flex";
      } else {
        return "none";
      }
    };
    return (
      <div className="column_one col-3 pl-0 pr-0 ">
        <div style={{ display: "none" }}>{this.props.store.activeQuoteMethod}</div>
        <h2 className="font-weight-bold m-0 drow_1 pl-0 pr-2 d-flex align-items-center">
          {i18n.t("Design Your Plan")}
        </h2>

        <div className="column_one_block pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <p className="mb-0 stand_text text-right">
            <Trans>Classification of Insured</Trans>
          </p>
        </div>
        <div className="grey_space pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex align-items-center justify-content-start">
          <h2 className="m-0 font-weight-bold">
            <Trans>Number of Insured Members</Trans>
          </h2>
        </div>
        <div className="employees_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Employees</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18n.t("The number of staff that will be insured by the plan.")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div className="spouse_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Spouses</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18n.t(
                "The number of spouses  of staff that will be insured by the plan."
              )}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div className="children_block d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center">
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>No. of Children</Trans>
            <button
              className="ml-2"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18n.t("No-of-children-helper-text")}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>

        <div
          className="grey_space pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex align-items-center justify-content-start switching__"
          ref={node => {
            if (node) {
              node.style.setProperty("display", toDisplayBlock("opt2"), "important");
            }
          }}
        >
          <h2 className="m-0 font-weight-bold d-flex align-items-center justify-content-center text-left">
            <Trans>Choose Premium Budget</Trans>
            <button
              className="ml-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title={i18n.t(
                "What is your budget for each insured's premium per year?"
              )}
            >
              <img src={"img/" + "info.png"} alt="info" />
            </button>
          </h2>
        </div>
        <div
          className="prem_hops_block child_small  d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching__"
          ref={node => {
            if (node) {
              node.style.setProperty("display", toDisplayBlock("opt2"), "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>Hospitalization Only</Trans>
          </h2>
        </div>
        <div
          className="prem_hops2_block    d-flex pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2 d-flex justify-content-end d2_b align-items-center switching__"
          ref={node => {
            if (node) {
              node.style.setProperty("display", toDisplayBlock("opt2"), "important");
            }
          }}
        >
          <h2 className="m-0 d-flex align-items-center justify-content-end text-right">
            <Trans>Hospitalization & Out-Patient</Trans>
          </h2>
        </div>
        <div className="res_lu main_res_lu d-flex align-items-center justify-content-start  pl-lg-3 pr-lg-3 pl-md-2 pr-md-2 pl-sm-2 pr-sm-2 pl-2 pr-2">
          <h2 className="m-0 font-weight-bold">
            <Trans>Total Premium By Class</Trans>
          </h2>
        </div>
      </div>
    );
  }
}

export default Opt2Headers;
