const confUrl = "conf.json";
import { observable, action, runInAction } from "mobx";
import _ from "lodash";
import i18n from "./i18n";
import i18next from 'i18next';
import { captureRejectionSymbol } from "events";

const fileSaver = require("file-saver");
const qs = require("qs");
const https = require("https");
const httsAgent = new https.Agent({
  rejectUnauthorized: false
});
const axios = require("axios");
const axiosCookieJarSupport = require("axios-cookiejar-support");
const tough = require("tough-cookie");
const parseString = require("xml2js").parseString;

axiosCookieJarSupport(axios);
const cookieJar = new tough.CookieJar();

class SessionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    
    if (window.innerWidth < 768) this.isMobile = true;
  }

  initSession = async () => {
    await axios.get(this.sessionUrl, {
      httpsAgent: httsAgent,
      jar: cookieJar,
      withCredentials: true
    });
  };


  @action
  initData = async () => {
    let enTransUrl = "trans-en.json";
    let zhTransUrl = "trans-zh.json";
    try {
      let confFile = await axios.get(confUrl);
      let conf = confFile.data;
      runInAction(() => {
        this.sessionUrl = conf.sessionUrl;
        this.apiBaseUrl = conf.apiBaseUrl + "?";
        this.pdfDownUrl = conf.pdfDownUrl;
        this.brochureUrl = conf.brochureUrl;
        console.log(conf.GA);
        this.GA = conf.GA;
      });

      enTransUrl = conf.enTransUrl;
      zhTransUrl = conf.zhTransUrl;





      let homepage = await axios.get(this.sessionUrl, {
        httpsAgent: httsAgent,
        jar: cookieJar,
        withCredentials: true
      });
      runInAction(async () => {
        let start = homepage.data.indexOf("budgetArray");
        let end = homepage.data.indexOf("budgetSelIN");
        let arrayString = homepage.data.substring(start, end);
        let sections = arrayString.split("),");
        for (let i = 0; i < sections.length; i++) {
          let line = sections[i];
          let s = line.indexOf("IN");
          let clean = line.substring(s);
          let cleanSections = clean.split("','");
          this.budgetOptions.push({
            inout: cleanSections[0],
            label: cleanSections[1],
            age: cleanSections[2],
            value: cleanSections[3],
            plan: cleanSections[4],
            plan2: cleanSections[5]
          });
        }
      });
    } catch (error) { }

    let enTrans = await axios.get(enTransUrl);
    let zhTrans = await axios.get(zhTransUrl);
    await i18n.initI18n(enTrans.data, zhTrans.data);
    await i18n.setLang("zh");
    await i18n.setLang("en");
    runInAction(() => {
      this.sessionReady = true;
    });
  };

  logoUrlPath = "";

  @action
  getBrokerLogo = async (brokerCode) => {
    if (brokerCode != null) {
      let confFile = await axios.get(confUrl);
      let conf = confFile.data;
      runInAction(() => {
        this.brokerLogoFetchUrl = conf.brokerLogoFetchUrl + "?brokerCode=" + brokerCode;
      });
      axios.get(this.brokerLogoFetchUrl).then(response => response.data)
        .then((data) => {
          this.logoUrlPath = data.url;
        });
    }
    else {
      this.logoUrlPath = null;
    }

  };



  @action
  setLang = async lang => {
    await i18n.setLang(lang);
    runInAction(() => {
      this.lang = lang;
    });
  };

  @action
  setScreenSize = size => {
    if (size < 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  };

  sessionUrl = "";
  apiBaseUrl = "";
  pdfDownUrl = "";
  GA = "";


  @observable
  sessionReady = false;
  @observable
  agesCalSession = 1;
  @observable
  lang = "en";
  @observable
  isInputComplete = false;

  @observable
  errorMessages = [];
  @observable
  warnMessages = [];
  @observable
  history = null;

  @observable
  displayErrorBasic = false;
  @observable
  displayError_class_0 = false;
  @observable
  displayError_class_1 = false;
  @observable
  displayError_class_2 = false;
  @observable
  displayError_class_3 = false;

  @observable
  budgetOptions = [];

  @observable
  quote = {
    premiumClass0: "0",
    premiumClass1: "0",
    premiumClass2: "0",
    premiumClass3: "0",
    headCntEE: "0",
    headCntCH: "0",
    headCntSP: "0",
    headCntTOT: "0",
    premiumTOT: "0",
    premium1stYrDISC: "0",
    premiumOrgTOT: "0",
    totalPremiumWithLevy: "0",
    levyAmt: "0",
    proposedEffecttiveDate: "",
    generalStatement: ""
  };

  @observable
  isMobile = false;
  @observable
  counter = 1;

  @observable
  header_client_question1 = "N";
  @observable
  version_ageGroup = "18-40";
  @observable
  proposeEffDate = "";
  @observable
  header_client_question2 = "N";

  totalEmployee = 0;

  @observable
  calculatingAges = [
    { birthDate: "", age: 0 },
    { birthDate: "", age: 0 },
    { birthDate: "", age: 0 }
  ];
  @observable
  averageAge = 0;
  @observable
  ageOutOfLimit = false;

  @observable
  next_active_class = 1;

  @observable
  class_sel_0 = "on";
  @observable
  class_name_0 = "";
  @observable
  class_employeeNo_0 = 0;
  @observable
  class_spouseNo_ck_0 = "";
  @observable
  class_spouseNo_0 = 0;
  @observable
  class_childrenNo_ck_0 = "";
  @observable
  class_childrenNo_0 = 0;
  @observable
  class_hospitalPlan_0 = "";
  @observable
  class_hospitalPlanBudget_0 = "";
  @observable
  class_outPPlan_attr1_0 = ""; //PLAN2
  @observable
  class_tLifePlan_0 = ""; //PLAN4
  @observable
  class_bta_plan_0 = ""; //Option 1
  @observable
  class_bta_plan_area_0 = "";
  @observable
  class_bta_plan_member_range_0 = "";
  @observable
  class_dentalPlan_0 = ""; //PLAN1
  @observable
  class_outPPlan_attr2_0 = ""; //other possible values: 80, 100
  @observable
  class_bta_members_0 = 1;
  @observable
  class_bta_plan_business_0 = "Business"; // other possible value "Business Only", Business_Leisure
  @observable
  i_hospitalOutpientOption_0 = ""; //option1, option2, option3
  @observable
  i_hospitalOutpientOptionLabel_0 = ""; //
  @observable
  class_hospitalPlanLabel_0 = "";
  @observable
  i_hospitalOnlyPlan_0 = "";
  @observable
  class_hospitalPlanLabel_0 = "";
  version_suggested_budget_IN_0 = "";
  version_suggested_budget_INOUT_0 = "";

  @observable
  class_sel_1 = "off";
  @observable
  class_name_1 = "";
  @observable
  class_employeeNo_1 = 0;
  @observable
  class_spouseNo_ck_1 = "";
  @observable
  class_spouseNo_1 = 0;
  @observable
  class_childrenNo_ck_1 = "";
  @observable
  class_childrenNo_1 = 0;
  @observable
  class_hospitalPlan_1 = "";
  @observable
  class_hospitalPlanBudget_1 = "";
  @observable
  class_outPPlan_attr1_1 = "";
  @observable
  class_tLifePlan_1 = "";
  @observable
  class_bta_plan_1 = "";
  @observable
  class_bta_plan_area_1 = "";
  @observable
  class_bta_plan_member_range_1 = "";
  @observable
  class_dentalPlan_1 = "";
  @observable
  class_outPPlan_attr2_1 = "";
  @observable
  class_bta_members_1 = 1;
  @observable
  class_bta_plan_business_1 = "Business";
  @observable
  i_hospitalOutpientOption_1 = ""; //option1, option2, option3
  @observable
  i_hospitalOutpientOptionLabel_1 = ""; //
  @observable
  class_hospitalPlanLabel_1 = "";
  @observable
  i_hospitalOnlyPlan_1 = "";
  @observable
  class_hospitalPlanLabel_1 = "";
  version_suggested_budget_IN_1 = "";
  version_suggested_budget_INOUT_1 = "";

  @observable
  class_sel_2 = "off";
  @observable
  class_name_2 = "";
  @observable
  class_employeeNo_2 = 0;
  @observable
  class_spouseNo_ck_2 = "";
  @observable
  class_spouseNo_2 = 0;
  @observable
  class_childrenNo_ck_2 = "";
  @observable
  class_childrenNo_2 = 0;
  @observable
  class_hospitalPlan_2 = "";
  @observable
  class_hospitalPlanBudget_2 = "";
  @observable
  class_outPPlan_attr1_2 = "";
  @observable
  class_tLifePlan_2 = "";
  @observable
  class_bta_plan_2 = "";
  @observable
  class_bta_plan_area_2 = "";
  @observable
  class_bta_plan_member_range_2 = "";
  @observable
  class_dentalPlan_2 = "";
  @observable
  class_outPPlan_attr2_2 = "";
  @observable
  class_bta_members_2 = 1;
  @observable
  class_bta_plan_business_2 = "Business";
  @observable
  i_hospitalOutpientOption_2 = ""; //option1, option2, option3
  @observable
  i_hospitalOutpientOptionLabel_2 = ""; //
  @observable
  class_hospitalPlanLabel_2 = "";
  @observable
  i_hospitalOnlyPlan_2 = "";
  @observable
  class_hospitalPlanLabel_2 = "";
  version_suggested_budget_IN_2 = "";
  version_suggested_budget_INOUT_2 = "";

  @observable
  class_sel_3 = "off";
  @observable
  class_name_3 = "";
  @observable
  class_employeeNo_3 = 0;
  @observable
  class_spouseNo_ck_3 = "";
  @observable
  class_spouseNo_3 = 0;
  @observable
  class_childrenNo_ck_3 = "";
  @observable
  class_childrenNo_3 = 0;
  @observable
  class_hospitalPlan_3 = "";
  @observable
  class_hospitalPlanBudget_3 = "";
  @observable
  class_outPPlan_attr1_3 = "";
  @observable
  class_tLifePlan_3 = "";
  @observable
  class_bta_plan_3 = "";
  @observable
  class_bta_plan_area_3 = "";
  @observable
  class_bta_plan_member_range_3 = "";
  @observable
  class_dentalPlan_3 = "";
  @observable
  class_outPPlan_attr2_3 = "";
  @observable
  class_bta_members_3 = 1;
  @observable
  class_bta_plan_business_3 = "Business"; //Business_Leisure
  @observable
  i_hospitalOutpientOption_3 = ""; //option1, option2, option3
  @observable
  i_hospitalOnlyPlan_3 = "";
  @observable
  class_hospitalPlanLabel_3 = "";
  @observable
  i_hospitalOutpientOptionLabel_3 = ""; //
  version_suggested_budget_IN_3 = "";
  version_suggested_budget_INOUT_3 = "";

  @observable
  activePath = "/";
  @observable
  totalPremium1 = "";

  @observable
  activeQuoteMethod = "opt2";

  backedupValue = "";

  @observable
  hospitalBenefitsModalOpen = false;
  @observable
  outpatientBenefitsModalOpen = false;
  @observable
  termLifeBenefitsModalOpen = false;
  @observable
  businessTravelModalOpen = false;
  @observable
  dentalBenefitsModalOpen = false;
  @observable
  class34InfoModalOpen = false;
  @observable
  calculatorModalOpen = false;
  @observable
  importantNoteModalOpen = false;
  @observable
  contactInfoModalOpen = false;
  @observable
  exportQuoteModalOpen = false;
  @observable
  moreInfoModalOpen = false;
  /*@observable
  exportPdfModalOpen = false;
  */

  @observable
  header_broker = "";
  @observable
  header_handler = "";
  @observable
  header_broker_contact = "";
  @observable
  header_broker_no = "";
  @observable
  header_broker_email = "";
  @observable
  header_client_name = "";
  @observable
  header_client_person = "";
  @observable
  header_client_no = "";
  @observable
  header_client_email = "";

  @action
  cancelSelectionInModal = option => {
    this[option] = this.backedupValue;
  };

  @action
  toggleHospitalBenefitsModal = classNo => {
    this.hospitalBenefitsModalOpen = !this.hospitalBenefitsModalOpen;
    if (classNo != undefined) {
      this.openModalClassNo = classNo;
      let fieldName =
        this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
      this.backedupValue = this[fieldName + classNo];
    }
  };

  @action
  toggleoutpatientBenefitsModal = classNo => {
    this.outpatientBenefitsModalOpen = !this.outpatientBenefitsModalOpen;
    if (classNo != undefined) {
      this.openModalClassNo = classNo;
      this.backedupValue = this["class_outPPlan_attr1_" + classNo];
    }
  };

  @action
  toggleTermLifeBenefitsModal = classNo => {
    this.termLifeBenefitsModalOpen = !this.termLifeBenefitsModalOpen;
    if (classNo != undefined) {
      this.openModalClassNo = classNo;
      this.backedupValue = this["class_tLifePlan_" + classNo];
    }
  };

  @action
  toggleBusinessTravelModal = classNo => {
    this.businessTravelModalOpen = !this.businessTravelModalOpen;
    if (classNo != undefined) {
      this.openModalClassNo = classNo;
      this.backedupValue = this["class_bta_plan_" + classNo];
    }
  };

  @action
  toggleDentalBenefitsModal = classNo => {
    this.dentalBenefitsModalOpen = !this.dentalBenefitsModalOpen;
    if (classNo != undefined) {
      this.openModalClassNo = classNo;
      this.backedupValue = this["class_dentalPlan_" + classNo];
    }
  };

  @action
  toggleClass34InfoModal = () => {
    this.class34InfoModalOpen = !this.class34InfoModalOpen;
  };

  @action
  toggleCalculatorModal = () => {
    this.calculatorModalOpen = !this.calculatorModalOpen;
  };

  @action
  toggleImportantNoteModal = () => {
    this.importantNoteModalOpen = !this.importantNoteModalOpen;
  };

  @action
  toggleContactInfoModal = () => {
    if (this.importantNoteModalOpen) this.importantNoteModalOpen = false;
    this.contactInfoModalOpen = !this.contactInfoModalOpen;
  };

  @action
  toggleExportQuoteModal = () => {
    if (this.validateInput()) {
      this.exportQuoteModalOpen = !this.exportQuoteModalOpen;
    } else {
      $("#up_button").click();
    }
  };

  @action
  toggleMoreInfoModal = () => {
    this.moreInfoModalOpen = !this.moreInfoModalOpen;
  };

  @action
  updateFrequentNo = (classNo, count) => {
    if (count < 0) count = 0;
    this["class_bta_members_" + classNo] = count;
  };

  @observable
  openModalClassNo = 0;

  @action
  setHongKongOutsider = value => {
    this.header_client_question1 = value;
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };
  @action
  setClass34Option = value => {
    this.header_client_question2 = value;
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };

  @action
  setEffDate = date => {
    this.proposeEffDate = date;
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };

  @action
  toggleDateUpdate = () => {
    this.getQuote();
  }

  @action
  setAgeGroup = age => {
    if (this.version_ageGroup != age) {
      this.version_ageGroup = age;
      this.clearBudgetInput(0);
      this.clearBudgetInput(1);
      this.clearBudgetInput(2);
      this.clearBudgetInput(3);
    }
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };

  @action
  toggleAgeUpdate = () => {
    this.getQuote();
  };

  clearBudgetInput = classNo => {
    this["class_hospitalPlanBudget_" + classNo] = "";
    this["class_hospitalPlanLabel_" + classNo] = "";
    this["class_outPPlan_attr1_" + classNo] = "";
    this["i_hospitalOutpientOptionLabel_" + classNo] = "";
    this.testIsInputComplete();
  };

  clearInput = classNo => {
    this["class_name_" + classNo] = "";
    this["class_employeeNo_" + classNo] = 0;
    this["class_spouseNo_ck_" + classNo] = "";
    this["class_spouseNo_" + classNo] = 0;
    this["class_childrenNo_ck_" + classNo] = "";
    this["class_childrenNo_" + classNo] = 0;
    this["class_hospitalPlan_" + classNo] = "";
    this["class_hospitalPlanBudget_" + classNo] = "";
    this["class_outPPlan_attr1_" + classNo] = "";
    this["class_tLifePlan_" + classNo] = "";
    this["class_bta_plan_" + classNo] = "";
    this["class_bta_plan_area_" + classNo] = "";
    this["class_bta_plan_member_range_" + classNo] = "";
    this["class_dentalPlan_" + classNo] = "";
    this["class_outPPlan_attr2_" + classNo] = "";
    this["class_bta_members_" + classNo] = 1;
    this["class_bta_plan_business_" + classNo] = "Business";
    this["i_hospitalOutpientOption_0" + classNo] = "";
    this["i_hospitalOutpientOptionLabel_" + classNo] = "";
    this["class_hospitalPlanLabel_" + classNo] = "";
    this["i_hospitalOnlyPlan_" + classNo] = "";
    this["class_hospitalPlanLabel_" + classNo] = "";
    this["version_suggested_budget_IN_" + classNo] = "";
    this["version_suggested_budget_INOUT_" + classNo] = "";

    this["displayError_class_" + classNo] = false;
    this.testIsInputComplete();
  };

  shiftLeft = classNo => {
    let nextClassNo = Number(classNo) + 1;
    this["class_sel_" + classNo] = this["class_sel_" + nextClassNo];
    if (this["class_sel_" + nextClassNo] == "on") {
      this["class_name_" + classNo] = this["class_name_" + nextClassNo];
      this["class_employeeNo_" + classNo] = this["class_employeeNo_" + nextClassNo];
      this["class_spouseNo_ck_" + classNo] = this["class_spouseNo_ck_" + nextClassNo];
      this["class_spouseNo_" + classNo] = this["class_spouseNo_" + nextClassNo];
      this["class_childrenNo_ck_" + classNo] = this["class_childrenNo_ck_" + nextClassNo];
      this["class_childrenNo_" + classNo] = this["class_childrenNo_" + nextClassNo];
      this["class_hospitalPlan_" + classNo] = this["class_hospitalPlan_" + nextClassNo];
      this["class_hospitalPlanBudget_" + classNo] = this["class_hospitalPlanBudget_" + nextClassNo];
      this["class_outPPlan_attr1_" + classNo] = this["class_outPPlan_attr1_" + nextClassNo];
      this["class_tLifePlan_" + classNo] = this["class_tLifePlan_" + nextClassNo];
      this["class_bta_plan_" + classNo] = this["class_bta_plan_" + nextClassNo];
      this["class_bta_plan_area_" + classNo] = this["class_bta_plan_area_" + nextClassNo];
      this["class_bta_plan_member_range_" + classNo] = this["class_bta_plan_member_range_" + nextClassNo];
      this["class_dentalPlan_" + classNo] = this["class_dentalPlan_" + nextClassNo];
      this["class_outPPlan_attr2_" + classNo] = this["class_outPPlan_attr2_" + nextClassNo];
      this["class_bta_members_" + classNo] = this["class_bta_members_" + nextClassNo];
      this["class_bta_plan_business_" + classNo] = this["class_bta_plan_business_" + nextClassNo];
      this["i_hospitalOutpientOption_0" + classNo] = this[
        "i_hospitalOutpientOption_0" + nextClassNo
      ];
      this["i_hospitalOutpientOptionLabel_" + classNo] = this[
        "i_hospitalOutpientOptionLabel_" + nextClassNo
      ];
      this["class_hospitalPlanLabel_" + classNo] = this["class_hospitalPlanLabel_" + nextClassNo];
      this["i_hospitalOnlyPlan_" + classNo] = this["i_hospitalOnlyPlan_" + nextClassNo];
      this["class_hospitalPlanLabel_" + classNo] = this["class_hospitalPlanLabel_" + nextClassNo];
      this["class_hospitalPlanLabel_" + classNo] = this["class_hospitalPlanLabel_" + nextClassNo];
      this["version_suggested_budget_INOUT_" + classNo] = this[
        "version_suggested_budget_INOUT_" + nextClassNo
      ];

      this["displayError_class_" + classNo] = this["displayError_class_" + nextClassNo];
    } else {
      this.clearInput(classNo);
    }
    this.testIsInputComplete();
  };

  @action
  setAverageAge = () => {
    if (this.averageAge <= 40) this.setAgeGroup("18-40");
    if (this.averageAge > 40) this.setAgeGroup("41-65");

    this.toggleCalculatorModal();
    this.testIsInputComplete();
  };

  @action
  clearCalculatingAges = () => {
    this.calculatingAges = [
      { birthDate: "", age: 0 },
      { birthDate: "", age: 0 },
      { birthDate: "", age: 0 }
    ];
    this.agesCalSession++;
    $("input.date_age").val("");
    this.calculateAverage();
  };

  @action
  updateAgeItem(ageItem, index) {
    let tmpItems = this.calculatingAges;
    tmpItems[index] = ageItem;
    this.calculatingAges = [...tmpItems];
    this.calculateAverage();
  }

  @action
  addAgeItem() {
    if (this.calculatingAges.length < 30) {
      this.calculatingAges = [...this.calculatingAges, { date: "", age: 0 }];
    }
  }

  @action
  removeAgeItem(index) {
    let tmpItems = this.calculatingAges;
    tmpItems.splice(index, 1);
    this.calculatingAges = [...tmpItems];
    this.calculateAverage();
  }

  calculateAverage() {
    this.ageOutOfLimit = false;
    let ageItems = this.calculatingAges;

    let total = 0;
    let numbers = 0;
    for (let i = 0; i < ageItems.length; i++) {
      if (ageItems[i].age || ageItems[i].birthDate) {
        let ageNumber = ageItems[i].age;
        total += Number(ageNumber);
        numbers++;

        if (ageNumber > 64 || ageNumber < 18) {
          this.ageOutOfLimit = true;
        }
      }
    }
    this.averageAge = numbers > 0 ? parseInt(total / numbers) : 0;
  }

  @action
  setClassSel = (classNo, status) => {
    this["class_sel_" + classNo] = status;
    let activeClassCount = 0;
    if (status === "off") {
      for (let i = classNo; i <= 3; i++) {
        if (this["class_sel_" + i] === "on") {
          activeClassCount++;
        }
        this.shiftLeft(i);
      }
      if (activeClassCount === 0) {
        this["class_sel_0"] = "on";
      }
      if (this.displayErrorBasic) {
        this.validateInput();
      }
    }
    this.setNextActiveClass();
    this.testIsInputComplete();
    this.getQuote();
  };

  setNextActiveClass = () => {
    for (let i = 0; i <= 2; i++) {
      if (this["class_sel_" + i] === "on" && this["class_sel_" + (i + 1)] != "on") {
        this.next_active_class = i + 1;
      }
    }
  };

  @action
  setClassName = (classNo, name) => {
    this["class_name_" + classNo] = name;
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };

  @action
  updateHeadCount = (name, classNo, count) => {
    if (!isNaN(count)) {
      if (count == -1) count = 0;
      if (count > 99) count = 99;
      this[name + classNo] = count;
      if (name === "class_spouseNo_" && count > 0) {
        this["class_spouseNo_ck_" + classNo] = "Y";
      } else if (name === "class_spouseNo_" && count == 0) {
        this["class_spouseNo_ck_" + classNo] = "";
      }
      if (name === "class_childrenNo_" && count > 0) {
        this["class_childrenNo_ck_" + classNo] = "Y";
      } else if (name === "class_childrenNo_" && count == 0) {
        this["class_childrenNo_ck_" + classNo] = "";
      }
    }
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.testIsInputComplete();
  };

  @action
  setHospitalOption = (classNo, option, label) => {
    let fieldName =
      this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
    this[fieldName + classNo] = option;
    this["class_hospitalPlanLabel_" + classNo] = label;
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.getQuote();
    this.testIsInputComplete();
  };


  @action
  setHospitalOnlyOption = (classNo, opt) => {
    let fieldName =
      this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
    this[fieldName + classNo] = opt.plan;
    this["version_suggested_budget_IN_" + classNo] = opt.value;
    this["class_hospitalPlanLabel_" + classNo] = "HK$ " + opt.label;

    this["class_outPPlan_attr1_" + classNo] = "";
    this["class_outPPlan_attr2_" + classNo] = "";
    this["i_hospitalOutpientOptionLabel_" + classNo] = "";
    if (this.displayErrorBasic) {
      this.validateInput();
    }
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setHospitalOutpatientOption = (classNo, opt) => {
    let fieldName =
      this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
    this[fieldName + classNo] = opt.plan;
    this["class_outPPlan_attr1_" + classNo] = opt.plan2.split("_")[0];
    this["class_outPPlan_attr2_" + classNo] = Number(opt.plan2.split("_")[1]);
    this["version_suggested_budget_INOUT_" + classNo] = opt.value;
    this["i_hospitalOutpientOptionLabel_" + classNo] = "HK$ " + opt.label;
    this["class_hospitalPlanLabel_" + classNo] = "";
    this["version_suggested_budget_IN_" + classNo] = "";


    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setOutpatientPlanOption = (classNo, option) => {
    this["class_outPPlan_attr1_" + classNo] = option;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setTermLifePlanOption = (classNo, option) => {
    this["class_tLifePlan_" + classNo] = option;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setBusinessTravelPlanOption = (classNo, option) => {
    this["class_bta_plan_" + classNo] = option;
    //Set default bta travel  location to Worldwide
    this["class_bta_plan_area_" + classNo] = "Worldwide";
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setDentalOption = (classNo, option) => {
    this["class_dentalPlan_" + classNo] = option;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setactiveQuoteMethod = opt => {
    this.activeQuoteMethod = opt;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setTravelLocation = (classNo, option) => {
    this["class_bta_plan_area_" + classNo] = option;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setBusinessTravelMembersCount = (classNo, range) => {
    this["class_bta_plan_member_range_" + classNo] = range;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  setReimburse = (classNo, option) => {
    this["class_outPPlan_attr2_" + classNo] = option;
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  toggleLeisure = classNo => {
    if (this["class_bta_plan_business_" + classNo] == "Business_Leisure") {
      this["class_bta_plan_business_" + classNo] = "Business Only";
    } else {
      this["class_bta_plan_business_" + classNo] = "Business_Leisure";
    }

    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  toggleBusinessTravelCount = classNo => {
    if (!this["class_bta_plan_business_" + classNo] || (this["class_bta_plan_business_" + classNo] != "Business_Leisure" && this["class_bta_plan_business_" + classNo] != "Business Only")) {
      this["class_bta_plan_business_" + classNo] = "Business Only";
    }
    this.getQuote();
    this.testIsInputComplete();
  };

  @action
  getQuote = async () => {
    var params = {
      formAction: "DETAILS_PREMIUM",
      version_ageGroup: this.version_ageGroup,
      proposeEffDate: this.proposeEffDate
    };

    for (let i = 0; i <= 4; i++) {
      if (this["class_sel_" + i] === "on") {
        params["class_sel_" + i] = "on";
        params["class_employeeNo_" + i] = this["class_employeeNo_" + i];
        if (this["class_spouseNo_ck_" + i] === "Y") {
          params["class_spouseNo_ck_" + i] = "Y";
          params["class_spouseNo_" + i] = this["class_spouseNo_" + i];
        }
        if (this["class_childrenNo_ck_" + i] === "Y") {
          params["class_childrenNo_ck_" + i] = "Y";
          params["class_childrenNo_" + i] = this["class_childrenNo_" + i];
        }
        if (this.activeQuoteMethod === "opt2") {
          params["class_hospitalPlan_" + i] = this["class_hospitalPlanBudget_" + i];
          if (this["class_outPPlan_attr1_" + i]) {
            params["class_outPPlan_attr1_" + i] = this["class_outPPlan_attr1_" + i];
            params["class_outPPlan_attr2_" + i] = this["class_outPPlan_attr2_" + i];
          }
        } else {
          params["class_hospitalPlan_" + i] = this["class_hospitalPlan_" + i];
          params["class_outPPlan_attr1_" + i] = this["class_outPPlan_attr1_" + i];
          params["class_outPPlan_attr2_" + i] = this["class_outPPlan_attr2_" + i];
          params["class_tLifePlan_" + i] = this["class_tLifePlan_" + i];
          params["class_bta_plan_" + i] = this["class_bta_plan_" + i];
          params["class_bta_plan_area_" + i] = this["class_bta_plan_area_" + i];
          if (this["class_dentalPlan_" + i] === "PLAN1") {
            params["class_dentalPlan_" + i] = this["class_dentalPlan_" + i];
          }
          params["class_bta_plan_member_range_" + i] = this["class_bta_plan_member_range_" + i];
          params["class_bta_members_" + i] = this["class_bta_members_" + i];
          params["class_bta_plan_business_" + i] = this["class_bta_plan_business_" + i];
          if (
            this["class_outPPlan_attr1_" + i] === "PLAN1" ||
            this["class_outPPlan_attr1_" + i] === "PLAN2" ||
            this["class_outPPlan_attr1_" + i] === "PLAN3"
          ) {
            params["class_outPCard_" + i] = "Y";
          }
        }
      }
    }

    var query = "";
    for (let key in params) {
      query += encodeURIComponent(key) + "=" + encodeURIComponent(params[key]) + "&";
    }

    let api = await axios.get(this.apiBaseUrl + query, {
      maxRedirects: 0,
      httpsAgent: httsAgent,
      jar: cookieJar,
      withCredentials: true
    });
    if (api.status == 200) {
      let self = this;
      parseString(api.data, function (err, result) {
        runInAction(() => {
          self.updateQuoteResult(result.eccPremCalc);
        });
      });
    } else {
      this.initSession();
    }
  };

  @action
  setHeaderField(field, value) {
    this[field] = value;
  }

  @action
  downloadQuotePdf = async () => {
    if (!this.validateInput()) {
      this.exportQuoteModalOpen = false;
      $("#up_button").click();
      return "";
    }

    let dates = this.proposeEffDate.split("/");
    if (!dates) return "";
    var params = {
      formAction: "SIMP_QUOTE",
      version_ageGroup: this.version_ageGroup,
      proposeEffDate: dates[2] + "-" + dates[0] + "-" + dates[1],
      header_client_question1: this.header_client_question1,
      header_client_question2: this.header_client_question2,
      version_totEmployee: this.totalEmployee,
      jasperLogoPath: this.logoUrlPath
    };

    if (this.activeQuoteMethod === "opt2") {
      params.version_planBy_budget = "Y";
      for (let i = 0; i < 4; i++) {
        if (this["class_sel_" + i] === "on") {
          params["class_sel_" + i] = "on";
          params["class_name_" + i] = this["class_name_" + i];
          params["class_employeeNo_" + i] = this["class_employeeNo_" + i];
          if (this["class_spouseNo_ck_" + i] === "Y") {
            params["class_spouseNo_ck_" + i] = "Y";
            params["class_spouseNo_" + i] = this["class_spouseNo_" + i];
          }
          if (this["class_childrenNo_ck_" + i] === "Y") {
            params["class_childrenNo_ck_" + i] = "Y";
            params["class_childrenNo_" + i] = this["class_childrenNo_" + i];
          }

          params["version_suggested_budget_IN_" + i] = this["version_suggested_budget_IN_" + i];
          params["version_suggested_budget_INOUT_" + i] = this[
            "version_suggested_budget_INOUT_" + i
          ];

          params["class_hospitalPlan_" + i] = this["class_hospitalPlanBudget_" + i];
          params["class_outPPlan_attr1_" + i] = this["class_outPPlan_attr1_" + i];
          if (this["class_outPPlan_attr1_" + i]) {
            params["class_outPPlan_attr2_" + i] = this["class_outPPlan_attr2_" + i];
          }

          params["class_tLifePlan_" + i] = "";
        }
        params["class_bta_plan_" + i] = "";
        params["class_bta_plan_area_" + i] = "";
        params["class_bta_plan_business_" + i] = "";
        params["class_bta_members_" + i] = "";
        params["class_bta_plan_member_range_" + i] = "";
      }
    }

    if (this.activeQuoteMethod === "opt1") {
      params.version_planBy_details = "Y";
      for (let i = 0; i < 4; i++) {
        if (this["class_sel_" + i] === "on") {
          params["class_sel_" + i] = "on";
          params["class_name_" + i] = this["class_name_" + i];
          params["class_employeeNo_" + i] = this["class_employeeNo_" + i];
          if (this["class_spouseNo_ck_" + i] === "Y") {
            params["class_spouseNo_ck_" + i] = "Y";
            params["class_spouseNo_" + i] = this["class_spouseNo_" + i];
          }
          if (this["class_childrenNo_ck_" + i] === "Y") {
            params["class_childrenNo_ck_" + i] = "Y";
            params["class_childrenNo_" + i] = this["class_childrenNo_" + i];
          }
          if (this["class_hospitalPlan_" + i] && this["class_hospitalPlan_" + i] != "Not Covered") {
            params["class_hospitalPlan_" + i] = this["class_hospitalPlan_" + i];
          }

          params["class_outPPlan_attr1_" + i] = this["class_outPPlan_attr1_" + i];
          params["class_outPPlan_attr2_" + i] = this["class_outPPlan_attr2_" + i];

          if (this["class_dentalPlan_" + i] === "PLAN1") {
            params["class_dentalPlan_" + i] = this["class_dentalPlan_" + i];
          }
          params["class_tLifePlan_" + i] = this["class_tLifePlan_" + i];
          if (this["class_bta_plan_" + i] && this["class_bta_plan_" + i] != "Not Covered") {
            params["class_bta_plan_" + i] = this["class_bta_plan_" + i];
            params["class_bta_plan_area_" + i] = this["class_bta_plan_area_" + i];
            params["class_bta_members_" + i] = this["class_bta_members_" + i];
            params["class_bta_plan_member_range_" + i] = this["class_bta_plan_member_range_" + i];
            params["class_bta_plan_business_" + i] = this["class_bta_plan_business_" + i];
          } else {
            params["class_bta_plan_" + i] = "";
          }

          params["version_suggested_budget_IN_" + i] = this["version_suggested_budget_IN_" + i];
          params["version_suggested_budget_INOUT_" + i] = this[
            "version_suggested_budget_INOUT_" + i
          ];

          if (
            this["class_outPPlan_attr1_" + i] === "PLAN1" ||
            this["class_outPPlan_attr1_" + i] === "PLAN2" ||
            this["class_outPPlan_attr1_" + i] === "PLAN3"
          ) {
            params["class_outPCard_" + i] = "Y";
          }
        }
      }
    }

    params["header_broker"] = this.header_broker;
    params["header_handler"] = this.header_handler;
    params["header_broker_contact"] = this.header_broker_contact;
    params["header_broker_no"] = this.header_broker_no;
    params["header_broker_email"] = this.header_broker_email;
    params["header_client_name"] = this.header_client_name;
    params["header_client_person"] = this.header_client_person;
    params["header_client_no"] = this.header_client_no;
    params["header_client_email"] = this.header_client_email;

    for (let i = 0; i < 4; i++) {
      if (params['class_tLifePlan_'+i]) {
      
        if (params['class_tLifePlan_'+i] === 'Not Covered') {
          
          params['class_tLifePlan_'+i]="";
        }
      }
      if (params['class_outPPlan_attr1_'+i]) {
        
        if (params['class_outPPlan_attr1_'+i] === 'Not Covered') {
          
          params['class_outPPlan_attr1_'+i]="";

        }
      }
    }
    console.log("params :", params)
    //when cookie expires, padBaseUrl return 302 and redirect to login.jsp.
    //Refresh session before generating pdf download.
    await axios.get(this.sessionUrl, {
      httpsAgent: httsAgent,
      jar: cookieJar, // tough.CookieJar or boolean
      withCredentials: true
    });
    let api = await axios.post(this.apiBaseUrl, qs.stringify(params), {
      httpsAgent: httsAgent,
      jar: cookieJar,
      withCredentials: true
    });

    let homepage = await axios.get(this.sessionUrl, {
      httpsAgent: httsAgent,
      jar: cookieJar, // tough.CookieJar or boolean
      withCredentials: true
    });
    let content = homepage.data;
    let start = homepage.data.indexOf('window.open("download.jsp?type=eccSimp"');
    let a = homepage.data.indexOf("var", start - 30);
    let fileName = homepage.data.substring(a + 3, start - 1);
    fileName = fileName.trim();

    let pdfFile = await axios.get(this.pdfDownUrl, {
      httpsAgent: httsAgent,
      jar: cookieJar,
      withCredentials: true,
      responseType: "blob"
    });
    var blob = new Blob([pdfFile.data], {
      type: "application/pdf"
    });
    fileSaver.saveAs(blob, fileName + ".pdf");
  };

  updateQuoteResult = prem => {
    this.quote = {
      premiumClass0: prem.premiumClass0[0],
      premiumClass1: prem.premiumClass1[0],
      premiumClass2: prem.premiumClass2[0],
      premiumClass3: prem.premiumClass3[0],
      headCntEE: prem.headCntEE[0],
      headCntCH: prem.headCntCH[0],
      headCntSP: prem.headCntSP[0],
      headCntTOT: prem.headCntTOT[0],
      premiumTOT: prem.premiumTOT[0],
      premium1stYrDISC: prem.premium1stYrDISC[0],
      premiumOrgTOT: prem.premiumOrgTOT[0],
      totalPremiumWithLevy: prem.totalPremiumWithLevy[0],
      levyAmt: prem.levyAmt[0],
      proposedEffecttiveDate: prem.proposedEffecttiveDate[0],
      generalStatement: prem.generalStatement[0]
    };
  };

  validateInput = () => {
    this.errorMessages = [];
    this.warnMessages = [];
    if (!this.header_client_question1) {
      this.errorMessages.push(i18next.t("Please answer first question."));
    }
    if (!this.header_client_question2) {
      this.errorMessages.push(i18next.t("Please answer second question."));
    }
    if (!this.proposeEffDate) {
      this.errorMessages.push(
        i18next.t("Policy Effect Date is required. Please select a date before submitting the form.")
      );
    }
    if (!this.version_ageGroup) {
      this.errorMessages.push(i18next.t("Please choose your average year."));
    }
    this.totalEmployee = 0;

    let totalClass = 0;
    for (let i = 0; i < 4; i++) {
      if (this["class_sel_" + i] === "on") {
        totalClass++;
        this.totalEmployee += parseInt(this["class_employeeNo_" + i]);
        if (!this["class_name_" + i]) {
          this.errorMessages.push(i18next.t("Please select a Staff Class option for Staff Class ") + (i + 1));
          this["displayError_class_" + i] = true;
        }
        if (!this["class_employeeNo_" + i]) {
          this.errorMessages.push(
            i18next.t("Please select the Number of Employees required for Staff Class ") + (i + 1)
          );
          this["displayError_class_" + i] = true;
        }
        let fieldName =
          this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
        if (!this[fieldName + i]) {
          this.errorMessages.push(
            i18next.t("Please select a Hospitalization option for Staff Class ") + (i + 1)
          );
          this["displayError_class_" + i] = true;
        }
        if (
          this["class_outPPlan_attr1_" + i] &&
          this["class_outPPlan_attr1_" + i] !== "Not Covered"
        ) {
          if (!this["class_outPPlan_attr2_" + i]) {
            this.errorMessages.push(
              i18next.t("Please select Out-Patient Reimbursement for Staff Class ") + (i + 1)
            );
            this["displayError_class_" + i] = true;
          }
        }

        if (this["class_bta_members_" + i] > this["class_employeeNo_" + i]) {
          this.errorMessages.push(
            i18next.t("No. of Frequent Business Travelers should be less or equal to Employee count at Staff Class ") +
            (i + 1)
          );
          this["displayError_class_" + i] = true;
        }
        if (this["class_bta_plan_" + i] !== "" && this["class_bta_plan_" + i] !== "Not Covered") {
          if (this["class_bta_plan_area_" + i] === "") {
            this.errorMessages.push(i18next.t("Please select travel coverage area at Staff Class ") + (i + 1));
            this["displayError_class_" + i] = true;
          }
        }
        if (this["class_bta_plan_" + i] !== "" && this["class_bta_plan_" + i] !== "Not Covered") {
          if (this["class_bta_plan_member_range_" + i] === "") {
            this.errorMessages.push(i18next.t("Please select no. of frequent business travelers"));
            this["displayError_class_" + i] = true;
          }
        }
      }
    }
    /*
    if (this.totalEmployee <= 5 && totalClass > 1) {
      this.warnMessages.push(
        "For groups with 5 empolyees or below, all employees must be under the same plan. <br /> A minimum of 2 employees will be charged per policy even if there are fewer than 2 employees"
      );
    }
    */
    if (this.errorMessages.length || this.warnMessages.length) {
      this.displayErrorBasic = true;
      return false;
    } else {
      if (this.header_client_question1 === "Y" || this.header_client_question2 === "Y") {
        this.moreInfoModalOpen = true;
        return false;
      }
    }

    return true;
  };

  

  testIsInputComplete = () => {
    if (!this.header_client_question1) {
      this.isInputComplete = false;
      return;
    }
    if (!this.header_client_question2) {
      this.isInputComplete = false;
      return;
    }
    if (!this.proposeEffDate) {
      this.isInputComplete = false;
      return;
    }
    if (!this.version_ageGroup) {
      this.isInputComplete = false;
      return;
    }

    for (let i = 0; i < 4; i++) {
      if (this["class_sel_" + i] === "on") {
        if (!this["class_name_" + i]) {
          this.isInputComplete = false;
          return;
        }

        if (!this["class_employeeNo_" + i]) {
          this.isInputComplete = false;
          return;
        }
        let fieldName =
          this.activeQuoteMethod === "opt1" ? "class_hospitalPlan_" : "class_hospitalPlanBudget_";
        if (!this[fieldName + i]) {
          this.isInputComplete = false;
          return;
        }
      }
    }

    this.isInputComplete = true;
  };


  selectLink = (link) => {
    console.log('this.lang', this.lang);
    console.log('link', link);
    console.log('!process.env.NODE_ENV', process.env.NODE_ENV)
    
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('ínside dev')
      // dev code
      if (this.lang == "en") {
        if (link === "acceptable") {
          window.open("https://uat.libertyinsurance.com.hk/acceptable-use-policy", "_blank")
        } else if (link === "cookie") {
          window.open("https://uat.libertyinsurance.com.hk/cookie-policy", "_blank")
        } else if (link === "end") {
          window.open("https://uat.libertyinsurance.com.hk/mobile-application-privacy-policy", "_blank")
        } else if (link === "premium") {
          window.open("https://uat.libertyinsurance.com.hk/premium-levy", "_blank")
        } else if (link === "personal") {
          window.open("https://uat.libertyinsurance.com.hk/personal-information-collection-statement", "_blank")
        } else if (link === "privacy") {
          window.open("https://uat.libertyinsurance.com.hk/privacy-policy", "_blank")
        } else if (link === "security") {
          window.open("https://uat.libertyinsurance.com.hk/security-policy", "_blank")
        } else if (link === "tnc") {
          window.open("https://uat.libertyinsurance.com.hk/terms-and-conditions", "_blank")
        }
      } else {
        if (link === "acceptable") {
          window.open("https://uat.libertyinsurance.com.hk/zh/acceptable-use-policy", "_blank")
        } else if (link === "cookie") {
          window.open("https://uat.libertyinsurance.com.hk/zh/cookie-policy", "_blank")
        } else if (link === "end") {
          window.open("https://uat.libertyinsurance.com.hk/zh/mobile-application-privacy-policy", "_blank")
        } else if (link === "premium") {
          window.open("https://uat.libertyinsurance.com.hk/zh/premium-levy", "_blank")
        } else if (link === "personal") {
          window.open("https://uat.libertyinsurance.com.hk/zh/personal-information-collection-statement", "_blank")
        } else if (link === "privacy") {
          window.open("https://uat.libertyinsurance.com.hk/zh/privacy-policy", "_blank")
        } else if (link === "security") {
          window.open("https://uat.libertyinsurance.com.hk/zh/security-policy", "_blank")
        } else if (link === "tnc") {
          window.open("https://uat.libertyinsurance.com.hk/zh/terms-and-conditions", "_blank")
        }
      }
    } else {
      // production code
      if (this.lang == "en") {
        if (link === "acceptable") {
          window.open("https://www.libertyinsurance.com.hk/acceptable-use-policy", "_blank")
        } else if (link === "cookie") {
          window.open("https://www.libertyinsurance.com.hk/cookie-policy", "_blank")
        } else if (link === "end") {
          window.open("https://www.libertyinsurance.com.hk/mobile-application-privacy-policy", "_blank")
        } else if (link === "premium") {
          window.open("https://www.libertyinsurance.com.hk/premium-levy", "_blank")
        } else if (link === "personal") {
          window.open("https://www.libertyinsurance.com.hk/personal-information-collection-statement", "_blank")
        } else if (link === "privacy") {
          window.open("https://www.libertyinsurance.com.hk/privacy-policy", "_blank")
        } else if (link === "security") {
          window.open("https://www.libertyinsurance.com.hk/security-policy", "_blank")
        } else if (link === "tnc") {
          window.open("https://www.libertyinsurance.com.hk/terms-and-conditions", "_blank")
        }
      } else {
        if (link === "acceptable") {
          window.open("https://www.libertyinsurance.com.hk/zh/acceptable-use-policy", "_blank")
        } else if (link === "cookie") {
          window.open("https://www.libertyinsurance.com.hk/zh/cookie-policy", "_blank")
        } else if (link === "end") {
          window.open("https://www.libertyinsurance.com.hk/zh/mobile-application-privacy-policy", "_blank")
        } else if (link === "premium") {
          window.open("https://www.libertyinsurance.com.hk/zh/premium-levy", "_blank")
        } else if (link === "personal") {
          window.open("https://www.libertyinsurance.com.hk/zh/personal-information-collection-statement", "_blank")
        } else if (link === "privacy") {
          window.open("https://www.libertyinsurance.com.hk/zh/privacy-policy", "_blank")
        } else if (link === "security") {
          window.open("https://www.libertyinsurance.com.hk/zh/security-policy", "_blank")
        } else if (link === "tnc") {
          window.open("https://www.libertyinsurance.com.hk/zh/terms-and-conditions", "_blank")
        }
      }
    }

  }
  
}

export default SessionStore;
