import React, { Component, Fragment } from "react";
import StaffClass from "./StaffClass";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

import { initTooltip } from "../../util";

@inject("store")
@observer
class MobileStaffClasses extends Component {
  constructor(props) {
    super(props);
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
  }
  componentDidMount() {
    initTooltip();
  }

  addClass() {
    const { store } = this.props;

    let count = 0;
    for (let i = 0; i < 4; i++) {
      if (store["class_sel_" + i] === "on") {
        count++;
      }
    }
    store.setClassSel(store.next_active_class, "on");
  }

  removeClass(classNo) {
    const { store } = this.props;

    store.setClassSel(classNo, "off");
  }

  render() {
    const { store } = this.props;
    let totalClasses = 0;
    const class0 = () => {
      if (store["class_sel_0"] === "on") {
        totalClasses++;
        return (
          <StaffClass
            classNo={0}
            index={0}
            removeClass={event => {
              this.removeClass(0);
            }}
            lang={store.lang}
          />
        );
      }
    };
    const class1 = () => {
      if (store["class_sel_1"] == "on") {
        totalClasses++;
        return (
          <StaffClass
            classNo={1}
            index={1}
            removeClass={event => {
              this.removeClass(1);
            }}
            lang={store.lang}
          />
        );
      }
    };
    const class2 = () => {
      if (store["class_sel_2"] === "on") {
        totalClasses++;
        return (
          <StaffClass
            classNo={2}
            index={2}
            removeClass={event => {
              this.removeClass(2);
            }}
            lang={store.lang}
          />
        );
      }
    };
    const class3 = () => {
      if (store["class_sel_3"] === "on") {
        totalClasses++;
        return (
          <StaffClass
            classNo={3}
            index={3}
            removeClass={event => {
              this.removeClass(3);
            }}
            lang={store.lang}
          />
        );
      }
    };
    return (
      <div className="design_small_block d-sm-block d-lg-none d-md-none mt-4">
        <h2 className="mt-0 mb-3 font-weight-bold h2">
          <Trans>Design Your Plan</Trans>
        </h2>
        {class0()}
        {class1()}
        {class2()}
        {class3()}
        {totalClasses < 4 && (
          <div className="add-class-button d-flex justify-content-center align-items-center">
            <a
              href="#"
              className="d-flex align-items-center justify-content-center font-weight-bold"
              onClick={event => {
                event.preventDefault();
                this.addClass();
              }}
            >
              <img src={"img/" + "plus.png"} alt="plus" className="mr-3" />
              <Trans>Add Class</Trans>
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default MobileStaffClasses;
