import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import StaffClassByBudeget from "./ClassByBudget";
import StaffClassByOption from "./ClassByOption";
import StaffClassCommon from "./StaffClassCommon";
import StaffClassTotal from "./StaffClassTotal";

@inject("store")
@observer
class StaffClassWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isClassActive: false };
  }
  render() {
    const { store, classNo } = this.props;

    const classOptions = () => {
      if (store.activeQuoteMethod == "opt1") {
        return <StaffClassByOption classNo={classNo} lang={store.lang} />;
      } else {
        return <StaffClassByBudeget classNo={classNo} lang={store.lang} />;
      }
    };
    const isHiddenClass = () => {
      if (store["class_sel_" + classNo] === "on") {
        return "";
      } else {
        return "  hidden_col";
      }
    };

    const isNotNextActive = () => {
      if (store.next_active_class === classNo) {
        return "";
      } else {
        return "  fully_hidden";
      }
    };
    return (
      <div
        className={
          "column_two col-3 d-flex align-items-center justify-content-center pl-0 pr-0 flex-column whole_col " +
          isHiddenClass() +
          isNotNextActive()
        }
      >
        <StaffClassCommon classNo={this.props.classNo} lang={store.lang} />
        {classOptions()}
        <StaffClassTotal classNo={this.props.classNo} lang={store.lang} />
      </div>
    );
  }
}
export default inject("store")(observer(StaffClassWrapper));
