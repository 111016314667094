import React, { Component } from "react";
import { Modal } from "reactstrap";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class MoreInfoRequiredModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  render() {
    const { store } = this.props;

    return (
      <div id="moreIModal">
        <Modal
          isOpen={store.moreInfoModalOpen}
          toggle={store.toggleMoreInfoModal}
          className={"ml-auto mr-auto "}
          backdropClassName={"modal-backdrop fade show"}
          modalClassName={"modal fade fsonsmall"}
          contentClassName={"position-relative"}
          wrapClassName={"moreIModal"}
        >
          <button
            type="button"
            className="close close_di"
            data-dismiss="modal"
            aria-label="Close"
            onClick={event => {
              event.preventDefault();
              this.props.store.toggleMoreInfoModal();
            }}
          >
            <img src="img/close.png" alt="close" />
          </button>

          <div className="modal-body pl-lg-4 pr-lg-4 pl-md-4 pr-md-4 pl-sm-4 pr-sm-4   pl-3 pr-3">
            <div className="container imore_cont d-flex flex-column col-12 p-0">
              <div className="dmore_h2">
                <h2 className="m-0 mb-lg-3 mb-md-3 mb-sm-2 mb-2 font-weight-bold pr-3 flex-wrap	">
                  <img className="mr-2" src="img/alarm_green.png" alt="alarm_green" />
                  <Trans>More Information Is Required</Trans>
                </h2>
              </div>
              <p className="mb-lg-3 mb-md-3 mb-sm-2 mb-2">
                <Trans>We have a few more questions about your case.</Trans> <br />
                <Trans>Please contact Liberty insurance via email or telephone.</Trans>
              </p>
              <div className="imore_contact">
                <h2 className="font-weight-bold	mb-1"><Trans>Contact Details</Trans></h2>
                <p className="mb-2">
                  <span className="mr-2">
                    <img src="img/dm1.png" alt="dm1" />
                  </span>
                  <a href="mailto:ebenquiry@libertymutal.com.hk">ebenquiry@libertymutal.com.hk</a>
                </p>
                <p className="mb-lg-5 mb-md-4 mb-sm-4 mb-4">
                  <span className="mr-2">
                    <img src="img/dm2.png" alt="dm1" />
                  </span>
                  <a href="href=tel:+85228923877">+852 2892 3877</a>
                </p>
              </div>
              <div className="imore_button d-flex justify-content-end	">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center rounded font-weight-bold	"
                  onClick={event => {
                    event.preventDefault();
                    this.props.store.toggleMoreInfoModal();
                  }}
                >
                  <Trans>Close</Trans>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MoreInfoRequiredModal;
