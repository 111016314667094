import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import StaffClass from "./StaffClass";
import { initTooltip } from "../../util";

@inject("store")
@observer
class StaffClasses extends Component {
  componentDidMount() {
    initTooltip();
  }

  render() {
    const { store } = this.props;
    return (
      <Fragment>
        <StaffClass classNo={0} lang={store.lang} />
        <StaffClass classNo={1} lang={store.lang} />
        <StaffClass classNo={2} lang={store.lang} />
        <StaffClass classNo={3} lang={store.lang} />
      </Fragment>
    );
  }
}

export default StaffClasses;
