import React, { Component } from "react";
import { Trans } from "react-i18next";

class Intro extends Component {
  render() {
    return (
      <div className="header_tip_content flex-lg-row  flex-wrap col-12 d-flex align-items-lg-center align-items-md-center justify-content-space-between pt-3 pb-3 flex-sm-row flex-column p-lg-3 p-md-3 p-0 mb-4">
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <h2 className="mt-0 mb-2" name="about_easycare">
            <Trans>About EasyCare</Trans>
          </h2>
          <p className="mb-0">
            <Trans>about-liberty-text</Trans>
          </p>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12  d-flex justify-content-lg-end dmore_info justify-content-sm-start mt-lg-0 mt-md-0 mt-sm-2 mt-2">
          <a href="#" data-toggle="modal" name="more_info_btn" data-target="#moreInfoModal">
            <Trans>More Information</Trans>
            <img className="ml-2" src={"img/" + "extlink_white.png"} alt="ext" />
          </a>
        </div>
      </div>
    );
  }
}

export default Intro;
