import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import { planToOption } from "../../util";
import i18next from "i18next";

@inject("store")
@observer
class BusinessTravelBenefits extends Component {
  render() {
    const { store } = this.props;
    const isActive = option => {
      if (option == store["class_bta_plan_" + store.openModalClassNo]) return " active_wed_el";
      return "";
    };
    const item = (number, text) => {
      return (
        <div className="dchar_elem d-flex align-items-center justify-content-between">
          <span className="col-lg-1 pl-0 pr-0">{number}</span>
          <p className="col-lg-11 pl-0 pr-0 mb-0 font-weight-bold">
            <Trans>{text}</Trans>
          </p>
        </div>
      );
    };
    return (
      <Fragment>
        <div className="hospit_wrap hospit_with_paddings colored_hospit long_hospit_d col-12 p-0 d-flex align-items-start justify-content-between pl-3 pr-3">
          <div className="col-lg-7 p-0 pt-5 left_hospit">
            <h2 className="font-weight-bold mb-3">
              <Trans>COVERAGE</Trans>
            </h2>
            <h6 className="text-uppercase">
              <Trans>PERSONAL ASSISTANCE</Trans>
            </h6>
            <div className="wrap_hospit_dchar">
              {item(1, "Accidental Death & Permanent Disablement (Scale II)")}
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Additional Indemnity for Public Conveyance</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Major Burns</Trans></p>      
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              
              {item(2, "Accidental Death due to Natural Catastrophe")}
              <h6 className="text-uppercase">
                <Trans>MEDICAL EXPENSES AND ASSISTANCE</Trans>
              </h6>
              {item(3, "Medical Expenses")}
              {item(4, "Hospital Cash (Daily Limit- HKD500)")}
              {item(5, "Comatose State (Weekly Limit- HKD500, a maximum limit  of 50 weeks)")}
              {item(6, "Worldwide Assistance  Services")}
              <h6 className="text-uppercase">
                <Trans>TRAVEL INCONVENIENCE COVER</Trans>
              </h6>
              {item(7, "Loss of Deposit")}
              {item(8, "Trip Curtailment or Disruption")}
              {item(9, "Unexpected Delay (HKD500 for every 6 hours)")}
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Travel Misconnection (after 24 hours)</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              {item(10, "Flight Overbooking (HKD500 for every 6 hours)")}

              <h6 className="text-uppercase">
                <Trans>LOSS OF PERSONAL EFFECTS OVERSEAS</Trans>
              </h6> 
              {item(11, "Baggage Delay (after 6 hours)")}
              {item(12, "Personal Property")}
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Laptop</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Sports Equipment</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              <div className="dchar_elem no-bord-dchar d-flex align-items-center justify-content-between">
                <span className="col-lg-1 pl-0 pr-0"> </span>
                <p className="col-lg-8 pl-0 pr-0 mb-0 font-weight-bold"> <Trans>Other items</Trans></p>
                <h6 className=" pl-0 pr-0 mb-0" style={{borderBottom:"0px"}}> </h6>
              </div>
              {item(13, "Personal Money and Loss of Travel Document (Cash Limit- HKD3,000)")}
              <h6 className="text-uppercase">
                <Trans>LIABILITY ISSUE WHILE ABOARD</Trans>
              </h6>

              {item(14, "Personal Liability")}

              <h6 className="text-uppercase">
                <Trans>EXTENDED PROTECTION TOWARDS THE EMPLOYER</Trans>
              </h6>
              {item(15, "Alternative Employee Expenses")}
              {item(16, "Political or Natural Disaster Evacuation")}
              <h6 className="text-uppercase">
                <Trans>EXTENDED WELLBEING TO THE EMPLOYEE</Trans>
              </h6>
              {item(17, "Credit Card Protection")}
              {item(18, "Education Fee Subsidy")}
              {item(19, "Funeral Expenses & Repatriation of Remains")}
              {item(20, "Home Modiﬁcation Expenses")}
              {item(21, "Rehabilitation Tuition Expenses")}
              {item(22, "Scarring of Face")}
              {item(23, "Spouse Retraining")}
              {item(24, "Trauma Incident Counselling Beneﬁt (HKD1,500 /day /visit)")}
              <h6 className="text-uppercase">
                <Trans>24 HOURS WORLDWIDE ASSISTANCE HOTLINE SERVICES</Trans>
              </h6>
              {/* {item(25, "Emergency Travel Services Assistance")}
              {item(26, "Telephone Medical Advice")}
              {item(27, "Inoculation information")}
              {item(28, "Consulate and Embassy Information")}
              {item(29, "Legal Referral Service")} */}
            </div>
          </div>
          <div className="col-lg-6 right_hospit p-0 d-flex align-items-start justify-content-between ">
            <div
              className={
                "col-lg-3 p-lg-3 py-md-3 px-md-2 d-flex align-items-center justify-content-center flex-column " +
                isActive("Option 1")
              }
              onClick={event => {
                store.setBusinessTravelPlanOption(store.openModalClassNo, "Option 1");
              }}
            >
              <div className="head_wd d-flex flex-column align-items-center justify-content-center">
                <div className="bl_circle mb-2 d-flex align-items-center justify-content-center">
                  <img src={"img/" + "smallds.png"} alt="smallds" />
                </div>
                <p className="text-center font-weight-bold text-uppercase">
                  <Trans>Option 1</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,200,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,200,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>150,000</p>            
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>30,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,000,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>7,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>25,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p> <Trans>Included</Trans> </p>             
              </div>

              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  20,000
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>20,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>                   
                 2,500
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                 1,500
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>15,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>5,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>3,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>10,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>1,500,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>20,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>15,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
            <div className="wd_el d-flex align-items-center justify-content-center">
                <p>30,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>17,500</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  6,000 <br />
                  (1,200/6<Trans>hrs</Trans>)
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  5,500 <br />
                  (600/6<Trans>hrs</Trans>)
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  5,800 <br />
                  (600/6<Trans>hrs</Trans>)
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>870</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  5,500 <br />
                  (1,200/6<Trans>hrs</Trans>)
                </p>
            </div>
              {/* <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div> */}
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  20,000 
                </p>
              </div>
              {/* <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                  25,000 
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>20,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>25,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>25,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>25,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>20,000</p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>15,000</p>
              </div>

              <div className="wd_el d-flex align-items-center justify-content-center bigger_then_">
                <p />
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                <Trans>Free</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p>
                <Trans>Free</Trans>
                </p>
              </div>
              <div className="wd_el d-flex align-items-center justify-content-center">
                <p><Trans>Free</Trans></p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="bottom_wd d-flex align-items-center justify-content-between">
          <div className="col-lg-6 col-md-5 left_wd d-flex align-items-center justify-content-start not_covered">
            <div className={"check_wd d-flex align-items-center justify-content-center rounded mb-0 mr-2 "+isActive("Not Covered")}
            onClick={event => {
              store.setBusinessTravelPlanOption(store.openModalClassNo, "Not Covered");
            }}
            >
              <p
                className="m-0"

              >
                <input
                  type="checkbox"
                  id="cover5"
                  checked={store["class_bta_plan_" + store.openModalClassNo] == "Not Covered"}
                />
                <div class="bl_circle align-items-center justify-content-center not_covered_full"><img src="img/smallds.png" alt="smallds"/></div>
                <span class="text-center font-weight-bold text-uppercase"><Trans>Not Covered</Trans></span>
              </p>
            </div>
            <p className="m-0">
              *<Trans>All prices in HKD</Trans>
            </p>
          </div>
          <div className="col-lg-6 col-md-7 right_wd d-flex align-items-center justify-content-end">
            <div className="res_wnd mr-2">
              <p className="text-center mr-2 mb-0">
                <Trans>Selected</Trans>:{" "}
                <span>{i18next.t(planToOption(store["class_bta_plan_" + store.openModalClassNo]))}</span>
              </p>
            </div>
            <div className="rd_buttons d-flex align-items-center">
              <a
                href="#"
                className="cancel_button rounded d-flex align-items-center justify-content-center mr-3"
                onClick={event => {
                  event.preventDefault();
                  store.cancelSelectionInModal("class_bta_plan_" + store.openModalClassNo);
                  store.toggleBusinessTravelModal();
                }}
              >
                <Trans>Cancel</Trans>
              </a>
              <a
                href="#"
                className="select_button rounded d-flex align-items-center justify-content-center "
                onClick={event => {
                  event.preventDefault();
                  store.toggleBusinessTravelModal();
                }}
              >
                <Trans>Select</Trans>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BusinessTravelBenefits;
