import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
@inject("store")
@observer
class Quote extends Component {
  render() {
    const { store } = this.props;
    return (
      <div>
        <div className="container d-flex flex-column pre_quot">

        <h2 className="col-12 mb-lg-4 mb-md-3 mb-sm-3 mb-2 mt-0 font-weight-bold pl-0">
          <Trans>Your Quotation</Trans>
        </h2>
        <div className="inner_after_result rounded  overflow-y col-lg-8 d-flex flex-lg-row flex-md-row flex-sm-column flex-column pr-0 pl-0">
          <div className="left_resul rounded d-flex align-start flex-column  col-lg-5 col-md-5 col-sm-12 pb-3 pt-3 pl-4 pr-4 ">
            <div className="head_rs">
              <h2 className="text-uppercase font-weight-bold mb-4">
                <Trans>headcount</Trans>
              </h2>
            </div>
            <div className="content_rs">
              <div className="elem_rs d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0">
                  <Trans>No. of Employees</Trans>
                </p>
                <span className="font-weight-bold" name="countEE">
                  {store.quote.headCntEE}
                </span>
              </div>

              <div className="elem_rs d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0">
                  <Trans>No. of Spouses</Trans>
                </p>
                <span className="font-weight-bold" name="countSP">
                  {store.quote.headCntSP}
                </span>
              </div>

              <div className="elem_rs d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0">
                  <Trans>No. of Children</Trans>
                </p>
                <span className="font-weight-bold" name="countCH">
                  {store.quote.headCntCH}
                </span>
              </div>

              <div className="elem_rs d-flex align-items-center justify-content-between mb-0 rs_bo">
                <p className="mb-0">
                  <Trans>Total</Trans>
                </p>
                <span className="font-weight-bold" name="conutTot">
                  {store.quote.headCntTOT}
                </span>
              </div>
            </div>
          </div>
          <div className="right_resul rounded-right col-lg-7 col-md-7 col-sm-12 pb-4 pt-3 pl-4 pr-4">
            <div className="head_rs">
              <h2 className="text-uppercase font-weight-bold mb-4">
                <Trans>premium</Trans>
              </h2>
            </div>
            <div className="content_rs">
              <div className="elem_rs d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0">
                  <Trans>Total Premium</Trans>
                </p>
                <span className="font-weight-bold" name="premiumOrgTot">
                  HK$ {store.quote.premiumOrgTOT}
                </span>
              </div>

              <div className="elem_rs d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0 ">
                  <Trans>Premium Levy</Trans>
                </p>
                <span className="font-weight-bold" name="levyAmt">
                  HK$ {store.quote.levyAmt}
                </span>
              </div>

              <div className="elem_rs d-flex align-items-center justify-content-between rs_bo">
                <p className="mb-0 font-weight-bold">
                  <Trans>Total Premium with Levy</Trans>
                </p>
                <span className="font-weight-bold bigger_then_other" name="totalPremiumWithLevy">
                  HK$ {store.quote.totalPremiumWithLevy}
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="footer_links">
          <div class="container">

            <div class="footer-right">
              <ul class="d-flex flex-wrap main-list">
                <li><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("acceptable");
              }}><Trans>Acceptable Use Policy</Trans></a><span class="d-inline">|</span></li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("cookie");
              }}><Trans>Cookie Policy</Trans></a><span class="d-inline">|</span> </li>
                <li class="policy-row" ><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("end");
              }}><Trans>End User Application Policy</Trans></a><span class="d-inline">|</span> </li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("personal");
              }}><Trans>Personal Information Collection Statement</Trans></a><span class="d-inline">|</span>
                </li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("premium");
              }}><Trans>Premium Levy</Trans></a><span class="d-inline">|</span> </li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("privacy");
              }}><Trans>Privacy Policy</Trans></a><span class="d-inline">|</span> </li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("security");
              }}><Trans>Security Policy</Trans></a><span class="d-inline">|</span> </li>
                <li class="policy-row"><a target="_blank" href="#" onClick={event => {
                event.preventDefault();
                store.selectLink("tnc");
              }}><Trans>Terms & Conditions</Trans></a></li>
              </ul>
            </div>
            <div class="copyright mt-lg-0 copy-mt">
              <p class="d-flex mb-0">
                <span>&copy; <Trans>Liberty International Insurance Limited. All Rights Reserved.</Trans></span></p>
            </div>
          </div>
        </div>
      </div>
     
      
      

    );
  }
}

export default Quote;
