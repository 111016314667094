import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class ImportantNotesModal extends Component {
  render() {
    return (
      <div className="container notes_block d-flex flex-column col-12 p-0">
        <div className="notes_head">
          <h2 className="m-0 mb-lg-3 mb-md-3 mb-sm-2 mb-2 font-weight-bold pr-3 flex-wrap	">
            <img className="mr-2" src={"img/" + "Circle_Cloud.png"} alt="alarm_green" />
            <Trans>Important Notes</Trans>
          </h2>
        </div>
        <div className="notes_content d-flex flex-column">
          <p className="mb-2">
            • <Trans>EasyCare is only for employees resident in Hong Kong.</Trans>
          </p>
          <p className="mb-2">
            •<Trans>important-notes-text-class1or2-only</Trans>
          </p>
          <p className="mb-4">
            •<Trans>important-notes-text-hk-only</Trans>
          </p>
        </div>
        <div className="notes_button d-flex justify-content-end">
          <a
            href="#"
            className="rounded font-weight-bold d-flex align-items-center justify-content-center"
            onClick={event => {
              event.preventDefault();
              this.props.moveNextStep();
            }}
          >
            <Trans>Next</Trans>
          </a>
        </div>
      </div>
    );
  }
}

export default ImportantNotesModal;
