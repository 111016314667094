import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "mobx-react";
import store from "./stores";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
//import "./css/style.css";
//import "./css/_spacing.scss";

ReactDOM.render(
  <Router>
    <Provider {...store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("react-root")
);

serviceWorker.unregister();
