import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import QuestionOne from "./QuestionOne";
import QuestionTwo from "./QuestionTwo";
import QuestionThree from "./QuestionThree";
import QuestionFour from "./QuestionFour";
import { Trans } from "react-i18next";

@inject("store")
@observer
class Intro extends Component {
  render() {
    const { store } = this.props;
    return (
      <div className="center_info_input p-lg-4 p-md-4 pt-4 pb-4 pl-sm-3 pr-sm-3 pl-3 pr-3  col-12 mb-4">
        <div className="inside_header col-12 position-relative p-0">
          <h2 className="font-weight-bold m-0" name="basic_info_tab">
            <Trans>Basic Information</Trans>
          </h2>
          <a href="#" className="position-absolute arrow_close">
            <img src={"img/" + "triangle_arrow.png"} alt="triangle_arrow" />
          </a>
        </div>
        <div className="other_elems mt-4">
          <QuestionOne lang={store.lang} />
          <QuestionTwo lang={store.lang} />
          <QuestionThree lang={store.lang} />
          <QuestionFour lang={store.lang} />
        </div>
      </div>
    );
  }
}

export default Intro;
