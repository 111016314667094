import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";

@inject("store")
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
  }
  componentDidUpdate() {
    $(".settings_button").on("click", function(e) {
      e.preventDefault();
      if ($(".droppable_block").hasClass("act_drop")) {
        $(".droppable_block")
          .css("bottom", "200px")
          .removeClass("act_drop");
      } else {
        $(".droppable_block")
          .css("bottom", "-280px")
          .addClass("act_drop");
      }
    });
  }
  render() {
    const { store } = this.props;
    const content = () => {
      return (
        <div className="container h-100 d-flex align-items-center justify-content-space-between">
          <div className="col-lg-6 d-flex flex-direction-column align-items-lg-end align-items-md-end align-items-sm-center align-items-center">
            <a
              href="https://www.libertyinsurance.com.hk/"
              target="_blank"
              className="d-inline-flex align-items-center justify-content-start"
            >
              <img
                className="d-lg-none d-block d-sm-block d-md-none "
                src={"img/" + "logosmall.png"}
                alt="logosmall"
              />
              <img
                className="d-lg-inline d-md-inline d-sm-none d-none"
                style={{height: '64px', width: 'auto'}}
                src={"img/" + "logo_new.png"}
                alt="logo"
              />
            </a>
            <h5 className="easy_care ml-lg-4 ml-md-4 ml-sm-2 ml-2 mb-0" name="site_title">
              <Trans>EasyCare</Trans>
            </h5>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-end">
          <img
                className="d-lg-none d-block d-sm-block d-md-none "
                style={{height: '30px',marginRight:'10px', width: 'auto',display: store.logoUrlPath==null ? 'none !important' : 'inline !important'}}
                src={store.logoUrlPath}
                alt=""
              />
          <img
                className="d-lg-inline d-md-inline d-sm-none d-none"
                style={{height: '35px',marginRight:'20px', width: 'auto',display: store.logoUrlPath==null ? 'none !important' : 'inline !important'}}
                src={store.logoUrlPath}
                alt=""
            />
            <div className={"droppable_block "}>
              <div className="head_drp">
                <p>
                  <Trans>Selected Language</Trans>
                </p>
                <ul name="languages_toggle">
                  <li className={store.lang === "en" ? "active_droppable" : ""}>
                    <a
                      href="#"
                      onClick={event => {
                        event.preventDefault();
                        this.props.setLang("en");
                      }}
                    >
                      English
                    </a>
                  </li>
                  <li className={store.lang === "zh" ? "active_droppable" : ""}>
                    <a
                      href="#"
                      onClick={event => {
                        event.preventDefault();
                        this.props.setLang("zh");
                      }}
                    >
                      <Trans>Traditional Chinese</Trans>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ct_drp" style={{ display: "none" }}>
                <p>
                  <Trans>Selected Currency</Trans>
                </p>
                <ul name="currency_toggle">
                  <li className="active_droppable">
                    <a href="#">HKD $</a>
                  </li>
                  <li>
                    <a href="#">USD $</a>
                  </li>
                </ul>
              </div>
            </div>
            <a
              href="#"
              className="d-md-none  d-sm-flex settings_button"
              onClick={event => {
                this.setState({ isMenuOpen: true });
              }}
            >
              <Trans>Settings</Trans>
              <img src={"img/" + "sett.png"} alt="sett" />
            </a>
            <select
              name=""
              id="language_select"
              className="mr-3 header_selects  d-none d-lg-block d-md-block  d-sm-block"
              value={this.props.store.lang}
              onChange={event => {
                event.preventDefault();
                this.props.setLang(event.target.value);
                this.setState({ isMenuOpen: false });
              }}
              style={{ display: "none" }}
            >
              <option value="en">ENG</option>
              <option value="zh">中文</option>
            </select>
            <select
              name=""
              id="currency_select"
              className=" header_selects d-none  d-lg-block d-md-block  d-sm-none"
            >
              <option value="">HKD</option>
            </select>
          </div>
        </div>
      );
    };

    if (this.props.store.exportQuoteModalOpen && this.props.store.isMobile) {
      return (
        <header
          style={{
            position: "fixed",
            zIndex: 10000,
            top: "0px",
            width: "100%"
          }}
          setLang={this.props.setLang}
        >
          {content()}
        </header>
      );
    } else {
      return <header setLang={this.props.setLang}>{content()}</header>;
    }
  }
}

export default Header;
