import React, { Component, useState, useEffect, setState } from "react";
import { inject, observer } from "mobx-react";
import { Trans } from "react-i18next";
import FadeLoader from "react-spinners/FadeLoader";

@inject("store")
@observer
class ImportantNotesModal extends Component {

	constructor(props) {
    super(props)
		this.loaderRef = React.createRef(null);
  }
	render() {
		const { store, moveBackStep } = this.props;
		// let [loading, setLoading] = useState(true);
		// const [loading, setLoading] = useState(false);

		// useEffect(() => {
		// 	console.log('useEffect');
		// 	setLoading(true)
		// 	console.log('loading', loading);
		// }, [])
		return (
			

			<div className="container export_block d-flex flex-column col-12 p-0">
				<span ref={this.loaderRef} className="export_loader" style={{ display: 'none' }}><><FadeLoader color={"#fff"} loading={true} height={15} width={5} radius={2} margin={2} /></></span>
				<div className="export_head">
					<h2 className="m-0 mb-lg-3 mb-md-3 mb-sm-2 mb-2 font-weight-bold pr-3 flex-wrap">
						<img className="mr-2" src={"img/" + "Circle_Cloud.png"} alt="Circle" />
						<Trans>Export PDF</Trans>
					</h2>
				</div>
				<div className="export_content col-12 p-0">
					<p className="mb-lg-3 mb-md-3 mb-sm-2 mb-2">
						<Trans>Your PDF quotation is ready to download.</Trans> <br />
						<Trans>If you have any queries, please feel free to contact us.</Trans>
					</p>

					<div className="export_details col-12 p-0 d-flex flex-column">
						<h2 className="font-weight-bold	mb-1">
							<Trans>Contact Details</Trans>
						</h2>
						<p className="mb-2">
							<img className="mr-2" src={"img/" + "dm1.png"} alt={"img/" + "dm1.png"} />
							<a href="mailto:ebenquiry@libertymutal.com.hk">ebenquiry@libertymutal.com.hk</a>
						</p>
						<p className="mb-lg-5 mb-md-4 mb-sm-4 mb-4">
							<img className="mr-2" src={"img/" + "dm2.png"} alt={"img/" + "dm2.png"} />
							<a href="href=tel:+85228923877">+852 2892 3877</a>
						</p>
					</div>
					<div className="export_buttons col-12 p-0 d-flex justify-content-between align-items-center">
						<div className="back-exp col-6 p-0 d-flex justify-content-start">
							<a
								href="#"
								className="back_export"
								name="contact_back_2"
								onClick={event => {
									event.preventDefault();
									moveBackStep();
								}}
							>
								<img src={"img/" + "right_arrow.png"} className="mr-2" alt="right_arrow" />
								<Trans>Back</Trans>
							</a>
						</div>
						<div className="down-exp col-6 p-0 d-flex justify-content-end">
							<a
								href="#"
								className="download_pdf rounded d-flex align-items-center justify-content-center "
								name="print"
								onClick={async event => {
									event.preventDefault();
									console.log("this.loaderRef.current ###",this.loaderRef.current);
									if(this.loaderRef.current) {
										console.log("1pankaj");
										this.loaderRef.current.style.display = "flex";
									}

									setTimeout(() => {
										 store.downloadQuotePdf();
										 setTimeout(() => {
											
										   if(this.loaderRef.current) {
											   this.loaderRef.current.style.display = "none";
										   }
									   }, 8000);
									}, 30000);

									
									/*await store.downloadQuotePdf();
									if(this.loaderRef.current) {
										console.log("2pankaj");
										this.loaderRef.current.style.display = "none";
									}*/
								}}
							>
								
								<img className="mr-2" src={"img/" + "download.png"} alt="download" />
								<Trans>Download PDF</Trans>
							</a>
						</div>
					</div>
				</div>
				{/* </>
				} */}
			</div>

		);

	}
}

export default ImportantNotesModal;
